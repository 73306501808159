.slider__nav {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 10px;

  // We put the margin to the arrows and dots,
  // to avoid extra space under the slider if there is not enough slides to have arrows and dots
  > * {
    margin: 0 5px; }

  .slick--prev,
  .slick--next {
    width: 32px;
    height: 32px;
    box-shadow: 0 2px 4px 0 rgba(164, 164, 164, 0.5);
    background-color: rgba(255, 255, 254, 0.01);
    border-radius: 16px;

    &:before {
      height: 100%;
      width: 100%;
      background-position: 7px,center;
      background-size: 20px; } }

  .slick--prev {
    order: 1; }

  .slick-dots {
    order: 2; }

  .slick--next {
    order: 3; } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.slider__nav


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.slider__nav


// SM, MD and LG
//@media screen and (min-width: $min-sm )
//.slider__nav
//  > *
//    margin-top: 25px


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.slider__nav


// LG
//@media screen and (min-width: $min-lg )
//.slider__nav
