.sitemap__newsletter {
  display: flex;

  input {
    margin-right: 10px; } }

/* BREAKPOINTS */


// XS and SM
//@media screen and (max-width: $min-sm)
//.sitemap__newsletter


// MD
//@media screen and (min-width: $min-md)
//.sitemap__newsletter


// LG
//@media screen and (min-width: $min-lg)
//.sitemap__newsletter
