
.sport {
    .sport__supporter {
        align-items: center;
        color: $dark-grey;
        display: flex;
        font-weight: bold;
        justify-content: flex-end;
        margin-top: 15px;

        img {
            margin-left: 5px; } } }


/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs ) {
    .sport {
        .sport__supporter {
            font-size: 1rem;
            margin-bottom: 8px; } } }


// SM
@media screen and (min-width: $min-sm ) {
    .sport {
        .sport__supporter {
            font-size: 1.2rem; } } }


// SM, MD and LG
//@media screen and (min-width: $min-sm) and (max-width: $max-md )
//  .sport


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//  .sport


// LG
//@media screen and (min-width: $min-md)
//  .sport
