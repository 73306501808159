.header__nav__submenus {
  .row {
    // To force .header__nav__footer to stay on the bottom of the nav
    height: 100%; } }

/* BREAKPOINTS */


// SM
//@media screen and (max-width: $max-sm)


// MD
@media screen and (min-width: $min-md) and (max-width: $max-md) {
  .header__nav__submenus {
    padding-right: $gutter-md-real;
    padding-left: $gutter-md-real; }

  .header--sticky {
    .header__nav__lvl1 {
      > li {
        .header__nav__submenus {
          left: -105px; } } } } }

// MD and LG
@media screen and (min-width: $min-md) {
  .header__nav__submenus {
    box-shadow: 0 3px 7px -3px rgba(0, 0, 0, 0.7);
    visibility: hidden; } }

// LG
@media screen and (min-width: $min-lg) {
  .header__nav__submenus {
    padding-right: $gutter-lg-real;
    padding-left: $gutter-lg-real; }

  .header--sticky {
    .header__nav__lvl1 {
      > li {
        .header__nav__submenus {
          left: -97px; } } } } }
