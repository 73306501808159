#topbanner-mobile {
	display: none; }



/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
	#topbanner-mobile {
		display: flex;
		min-height: 200px;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		&.topbanner-mobile--locked {
			height: 165px;
			overflow: hidden;
			justify-content: flex-end; }

		.ems-slot.aobj0 {
			display: none; }

		#mobile_1 {
			margin-bottom: 0; } } }


// SM
//@media screen and (min-width: $min-sm) and (max-width: $max-sm)


// MD
//@media screen and (min-width: $min-md) and (max-width: $max-md)


// LG
//@media screen and (min-width: $min-lg)
