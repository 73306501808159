

// corona teaser in own-code-widget
.corona-teaser {
  font-size: 1.4rem;
  background: #e1e6eb;
  color: #46555f;
  font-weight: bold;
  text-align: center;
  padding: 10px 0;
  margin-top: -10px; }

.corona-teaser__red {
  color: $red; }


/* BREAKPOINTS */

// SM and MD
//@media screen and (min-width: $min-sm ) and (max-width: $max-md )
//.corona-teaser
//  font-size: 1.6rem



// LG
@media screen and (min-width: $min-lg ) {
  .corona-teaser {
    font-size: 2rem; } }


