.poll {
	font-size: 1.5rem; }

/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
	.poll {
		figure {
			margin: 0 (-$gutter-xs-real); }

		.title {
			margin-bottom: 0; } } }

// SM, MD and LG
@media screen and (min-width: $min-sm) {
	.poll {
		padding-bottom: 30px; } }

// LG
@media screen and (min-width: $min-lg) {
	.poll {
		border: none;
		font-size: 1.7rem;
		margin: 0;
		padding: 0; } }
