.header__nav__lvl2 {
  background: white; }

/* BREAKPOINTS */


// XS and SM
@media screen and (max-width: $max-sm) {
  .header__nav__lvl2 {
    display: none;
    > li {
      background: $lightest-grey;

      &.active {
        .header__nav__lvl3 {
          display: block; } } } } }

// MD and LG
@media screen and (min-width: $min-md) {
  .header__nav__lvl2 {
    color: $black;
    padding: 17px 0 15px;
    width: 236px;

    > li {
      &.li--lvl2--active {
        .header__nav__lvl3 {
          display: block; } } }

    li {
      align-items: center;
      @include border(b);
      display: flex;

      a {
        line-height: 29px; }

      &:before {
        background: url(../images/icons/icon__arrow-red.svg) no-repeat;
        content: '';
        height: 10px;
        width: 10px; } } } }
