.teaser--tiles {
  .teaser {
    padding: 0;

    .teaser__content {
      a {
        .teaser__headline {
          font-size: 1.8rem; } } } } }


/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
  .teaser--tiles {
    .row {
      [class*="col-"] {
        &:last-of-type {
          .teaser {
            margin-bottom: 0; } } } }

    .teaser__media {
      margin: 0 (-$gutter-xs-real) 5px;
      width: calc(100% + 20px); }

    .title {
      margin-bottom: 0; } } }

// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.teaser--tiles



// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .teaser--tiles {
    .title {
      line-height: 1;
      margin-bottom: 20px; }

    .teaser__media {
      width: 100%; } } }

//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.teaser--tiles


//MD and LG
@media screen and (min-width: $min-md) {
  .teaser--tiles {
    margin-top: 20px;

    //.col-xs-12
    ////&:nth-child(9)
 } }    //  //display: none

// LG
@media screen and (min-width: $min-lg) {
  .teaser--tiles {
    .teaser {
      .teaser__content {
        a {
          .teaser__headline {
            font-size: 2.2rem; } } } } } }
