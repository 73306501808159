.article__aside__author {
  width: 100%; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//  .article__aside__author


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//  .article__aside__author


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//  .article__aside__author


// LG
//@media screen and (min-width: $min-lg )
//  .article__aside__author
