.browserpush__teaser {
	padding-bottom: 40px;

	strong {
		display: block; } }

/* BREAKPOINTS */

@media screen and (max-width: $max-xs ) {
	.browserpush__teaser {
		padding-bottom: 20px;

		a {
			display: flex;
			justify-content: center;
			margin-top: 20px;

			button {
				align-items: center;
				display: flex;

				&:before {
					background: url(../images/icons/icon__subsettings.svg) no-repeat;
					content: '';
					display: flex;
					height: 20px;
					margin-right: 10px;
					width: 20px; } } } } }

// SM, MD and LG
@media screen and (min-width: $min-sm) {
	.browserpush__teaser {
		a {
			display: none; } } }

// LG
//@media screen and (min-width: $min-lg )
//browserpush__teaser
