.gallery__infobox {
  background: white;
  display: none;
  justify-self: center;
  padding: 5px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: $gallery__infobox; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.gallery__infobox


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.gallery__infobox


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.gallery__infobox


// LG
//@media screen and (min-width: $min-lg )
//.gallery__infobox
