.adviser__info__item {
    display: flex;

    a, div {
        display: flex;
        flex-direction: row; }

    a {
        font-weight: bold; }

    img {
        margin-left: auto; }

    span {
        img {
            margin-left: 5px; } } }



/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs ) {
    .adviser__info__item {
        span, div {
            width: 80%; } } }

// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//  .adviser__info__item

// MD
@media screen and (min-width: $min-md ) {
    .adviser__info__item {
        span, div {
            width: 70%; } } }


// LG
@media screen and (min-width: $min-lg ) {
    .adviser__info__item {
        span {
            font-size: 1.6rem; } }

    a.adviser__info__item {
        > img {
            height: 19px; } }

    div.adviser__info__item {
        > img {
            height: 29px; } } }


