/* https://toughengineer.github.io/demo/slider-styler/slider-styler.html */


.rangeslider {
	height: 16px;
	-webkit-appearance: none;
	background: transparent;
	cursor: pointer;
	width: 100%;
	--range: calc(var(--max) - var(--min));
	--ratio: calc((var(--value) - var(--min)) / var(--range));
	--sx: calc(0.5 * 16px + var(--ratio) * (100% - 16px));

	/*webkit*/
	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		width: 16px;
		height: 16px;
		border-radius: 1em;
		background: $red;
		border: none;
		box-shadow: none;
		margin-top: calc(3px * 0.5 - 16px * 0.5); }

	&::-webkit-slider-runnable-track {
		height: 3px;
		border: none;
		border-radius: 0;
		box-shadow: none;
		background: linear-gradient($red,$red) 0/var(--sx) 100% no-repeat, $lighter-grey; }

	/*mozilla*/
	&::-moz-range-thumb {
		width: 16px;
		height: 16px;
		border-radius: 1em;
		background: $red;
		border: none;
		box-shadow: none; }

	&::-moz-range-track {
		height: 3px;
		border: none;
		border-radius: 0;
		background: $lighter-grey;
		box-shadow: none;
		background: linear-gradient($red,$red) 0/var(--sx) 100% no-repeat, $lighter-grey; } }

/* BREAKPOINTS */


// XS
// @media screen and (max-width: $max-xs )



// SM
// @media screen and (min-width: $min-sm ) and (max-width: $max-sm )



// MD
// @media screen and (min-width: $min-md ) and (max-width: $max-md )



// LG
// @media screen and (min-width: $min-lg )

