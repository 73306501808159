.article__aside--text {
  color: $darker-grey;
  padding: 10px;

  p {
    margin-top: 16px; }

  figure {
    margin: 14px -10px; }

  .article__aside__copy {
    padding-right: 10px;
    padding-top: 5px;
    text-align: right; } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.article__aside--text


// SM
@media screen and (min-width: $min-sm) and (max-width: $max-sm) {
  .article__aside--text {
    padding: 11px $gutter-sm-real;

    figure {
      margin: 14px (-$gutter-sm-real); } } }

//MD
@media screen and (min-width: $min-md) and (max-width: $max-md) {
  .article__aside--text {
    padding: $gutter-md-real;

    figure {
      margin: 14px (-$gutter-md-real); } } }

// LG
@media screen and (min-width: $min-lg) {
  .article__aside--text {
    padding: 20px;

    p {
      margin-top: 20px; }

    figure {
      margin: 14px -20px 20px; } } }
