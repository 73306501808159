.theme__header {
  background: $lighter-grey;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 20px;
  padding: 15px;

  figure {
    float: right;
    margin-left: 10px;
    max-width: 33.33%;
    width: 180px; } }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.theme__header


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.theme__header


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.theme__header


// LG
//@media screen and (min-width: $min-lg )
//.theme__header
