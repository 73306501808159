.article__caption {
  font-size: 1.4rem;
  line-height: 1.1;
  padding: 0 8px; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//  .article__caption


// SM
@media screen and (min-width: $min-sm) and (max-width: $max-sm) {
  .article__caption {
    font-size: 1.5rem; } }

// MD
@media screen and (min-width: $min-md) and (max-width: $max-md) {
  .article__caption {
    font-size: 1.3rem; } }

// LG
@media screen and (min-width: $min-lg) {
  .article__caption {
    font-size: 1.6rem; } }
