.group {
  .teaser {
    &:last-child {
      margin-bottom: 0; } }

  &.group--sport-ticker-off {
    margin: 0;
    border: 0;
    padding: 0;
    height: 0;

    .title {
      display: none; } } }


/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs ) {
  .group {
    padding-bottom: 0; } }


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.group


// SM, MD and LG
@media screen and (min-width: $min-sm ) {
  .group {
    @include border(b);
    padding-bottom: 20px;

    > *:last-child,
    .content >*:last-child {
        margin-bottom: 0;
        padding-bottom: 0; } } }

//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.group


// LG
@media screen and (min-width: $min-lg ) {
  .group {
    padding-bottom: 20px; } }
