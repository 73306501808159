.browserpush__subscribe {
    border: 1px solid $dark-grey;
    max-width: 100%;
    padding: 10px;
    width: 500px; }

/* BREAKPOINTS */

@media screen and (max-width: $max-xs ) {
	.browserpush__subscribe {
        margin-bottom: 16px; } }

// SM, MD and LG
@media screen and (min-width: $min-sm) {
	.browserpush__subscribe {
		margin-bottom: 30px; } }

// LG
//@media screen and (min-width: $min-lg )
//browserpush__subscribe
