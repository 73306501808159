.ad-label {
  &.ad-label--right {
    text-align: right; }

  &.ad-label--mb {
    margin-bottom: 10px; }

  .ad-label__txt {
    background: $dark-grey;
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 3px 5px;
    display: inline-block; } }


.day__teaser {
  .ad-label {
    margin-bottom: 5px; } }
