
.linklist {
  border: 1px solid $light-grey;
  border-top: 0;
  margin-bottom: 20px;

  .title--small {
    margin: 0 0 10px 0; }

  .linklist__sec {
    flex: 0 0 auto;
    flex-basis: 100%;
    max-width: 100%;
    padding: 15px 10px;
    background: $lighter-grey;
    margin: 0 10px 10px 10px;

    .linklist__sec__title {
      color: $darker-grey;
      display: block;
      font-size: 1.6rem;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 10px; } }

  ul {
    list-style-type: none;
    padding: 0 10px;

    li {
      display: flex;
      border-bottom: 1px solid #FFFFFF;
      padding: 10px 0;
      align-items: center;
      font-size: 1.5rem; }

    li:nth-child(n+4) {
      display: none; } }

  > .linklist__button {
    display: none; }

  &.linklist--single {
    .linklist__sec {
      padding-top: 0;

      > .linklist__button {
        display: block; } } }

  .linklist__button {
    text-align: center;

    .button {
      margin-top: 15px; }

    .button--icon:before {
      background-image: url(../images/icons/icon__arrow-toggle.svg); } }

  .linklist__button--more {
    .button--icon:before {
      transform: rotate(180deg); } } }


// XS
@media screen and (max-width: $max-xs ) {
  .linklist {
    &.linklist--single {
      ul li {
        display: flex; }

      .row {
        div:nth-child(n+1) li:nth-child(n+6) {
          display: none; }

        div:nth-child(n+2) li {
          display: none; } } } }



 }  // SM, MD, LG
@media screen and (min-width: $min-sm ) {
  .linklist {
    border: 0;
    background: #e1e6eb;
    padding: 20px 15px;

    .title {
      margin-bottom: 20px; }

    .linklist__sec {
      padding: 0;
      margin: 0; }

    > .linklist__button {
      display: block; }

    .linklist__sec {
      > .linklist__button {
        display: none; } } } }

// LG
@media screen and (min-width: $min-lg ) {
  .linklist {
    .linklist__sec {
      .linklist__sec__title {
        font-size: 1.8rem; } } } }

