// on design, not displayed on XS and SM
.list--date {
	overflow: hidden;
	position: relative;

	.title {
		background: $lighter-grey;
		left: 0;
		margin: 0;
		padding: 20px 15px 10px;
		position: absolute;
		right: 17px;
		top: 0; }

	ul {
		background: $lighter-grey;
		max-height: 303px;
		overflow: auto;
		padding: 50px 15px 18px;
		-webkit-overflow-scrolling: touch;
		width: 100%; }

	a {
    &, span {
      display: block;
      @include ellipsis(); } }

	li {
		display: flex;

		span {
			display: inline;
			font-weight: $bold;
			line-height: 2.7rem; } }

	.teaser__headline {
		font-size: 1.8rem; } }


/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
	.list--date {
		.title {
			font-size: 1.6rem;
			line-height: 1.6rem;
			z-index: $list__date__title; }

		li {
			padding: 5px 0;

			span {
				display: block; } } } }



// SM and MD and LG
@media screen and (min-width: $min-sm ) {
	.list--date {
		li {
			align-items: baseline; } } }

// MD
//@media screen and (min-width: $min-md) and (max-width: $max-md)
//list--date


// LG
@media screen and (min-width: $min-lg) {
	.list--date {
		ul {
			max-height: 331px; }

		.teaser__headline {
			font-size: 2.2rem; } } }
