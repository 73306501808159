.article__aside--full {
  clear: both;

  .twitter-tweet, .instagram-media {
    margin-left: auto;
    margin-right: auto; }

  .data-protection-link {
    text-align: center; } }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.article__aside--full


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.article__aside--full


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.article__aside--full


// LG
//@media screen and (min-width: $min-lg)
//.article__aside--full
