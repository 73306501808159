.article__aside--chart {
  padding: 11px;

  picture {
    margin-bottom: 10px; }

  // Currency
  strong {
    color: $darker-grey; }

  // Chartbox
  .row {
    figure {
      margin-bottom: 11px; }

    .col-sm-6 {
      &:nth-last-child( -n + 2 ) {
        figure {
          margin-bottom: 0; } } } } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.article__aside--chart


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.article__aside--chart


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.article__aside--chart


// MD and LG
@media screen and (min-width: $min-md) {
  .article__aside--chart {
    padding: 16px;

    .row {
      figure {
        margin-bottom: 16px; } } } }

// LG
@media screen and (min-width: $min-lg) {
  .article__aside--chart {
    font-size: 1.6rem; } }
