.list--indexed {
  border: none;
  padding-bottom: 10px; }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.list--indexed


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.list--indexed


// SM, MD and LG
@media screen and (min-width: $min-sm ) {
  .list--indexed {
    margin-bottom: 0;
    padding-bottom: 2px; } }


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.list--indexed


// LG
@media screen and (min-width: $min-lg ) {
  .list--indexed {
    padding-bottom: 7px;

    .title {
      padding-bottom: 11px; } } }
