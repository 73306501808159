.article--video {
  padding-top: 0;

  .article__date {
    text-transform: none; }

  > .row {
    .article__headline {
      margin-bottom: 15px; }

    h1 {
      display: flex;
      flex-wrap: wrap;

      > * {
        width: 100%; } } }

  .playerresize-wrapper-fixed {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $black;
    z-index: 1000000; }

  #playerresize {
    width: 100%;
    margin: auto;

    > div {
      width: 100%;
      height: 0;
      padding-bottom: calc(1 / (16 / 9) * 100%);
      position: relative; }

    #playerwrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; } } }



/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.article--video


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.article--video


// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .article--video {
    padding-bottom: 20px;

    .ajax-wrapper {
      min-height: 160px; }

    .article__media {
      margin-bottom: 8px; }

    .article__headline {
      margin-bottom: 9px; }

    .title {
      margin-bottom: 20px; }

    .title--dark {
      font-size: 2.4rem; }

    > .row {
      position: relative;

      .article__share {
        margin-top: 0; } } } }

//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.article--video


// LG
@media screen and (min-width: $min-lg) {
  .article--video {
    .article__media {
      margin-bottom: 15px; }

    .teaser__kicker {
      font-size: 1.8rem; }

    .teaser__headline {
      font-size: 1.9rem; } } }
