// Not visible on xs and sm
.teaser__date {
  white-space: nowrap;

  .duration {
    text-transform: lowercase; }

  .date {
    + .duration {
      &:before {
        content: " | "; } } } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.teaser__date


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.teaser__date


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.teaser__date


// LG
//@media screen and (min-width: $min-lg )
