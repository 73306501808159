// never visible on xs
.teaser__more {
  align-items: center;
  color: $red;
  display: flex;
  font-size: 1.4rem;
  font-weight: $bold;
  text-decoration: underline;

  &:before {
    background: url(../images/icons/icon__arrow-headline.svg) no-repeat;
    content: '';
    float: left;
    height: 8px;
    margin-right: 7px;
    width: 8px; } }

/* BREAKPOINTS */


// MD and LG
//@media screen and ( min-width: $min-md )
//.teaser__more


// LG
@media screen and (min-width: $min-lg) {
  .teaser__more {
    font-size: 1.6rem; } }
