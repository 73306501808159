.stock__element {
  align-items: center;
  display: flex;
  font-size: 1.2rem;
  font-weight: $bold;
  justify-content: space-between;
  line-height: 1.5;
  width: 100%;

  > span {
    flex-shrink: 0;
    text-align: right; }

  .stock__currency {
    @include ellipsis();
    margin-right: auto; } }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs)
//.stock__element


// XS and SM
@media screen and (max-width: $max-sm) {
  .stock__element {
    &:last-child {
      display: none; } } }


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm)
//.stock__element


// SM, MD and LG
@media screen and (min-width: $min-sm ) {
  .stock__element {
    font-size: 1.3rem;
    line-height: 1.6; } }


// LG
@media screen and (min-width: $min-lg ) {
  .stock__element {
    font-size: 1.5rem; } }
