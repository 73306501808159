.gallery__nav {
  display: flex;
  flex-direction: column;
  padding: 16px 0 0 16px;
  width: 66px;

  button {
    font-size: 34px;
    padding: 0;

    &:first-child {
      order: 2; } }

  .slick-disabled {
    cursor: default;
    opacity: 0.5; } }

/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
  .gallery__nav {
    display: none; } }

// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.gallery__nav


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.gallery__nav


// LG
//@media screen and (min-width: $min-lg )
//.gallery__nav
