.extcontent__note {
  @include border();
  border-radius: 10px;
  padding: 15px;
  font-size: 1.4rem;
  max-width: 500px;
  margin: 0 auto;
  color: $black;

  img {
    width: 35px;
    float: right;
    margin: 0 0 10px 5px; }

  strong {
    color: $black;
    font-size: 1.5rem; }

  p {
    margin: 10px 0 15px 0; }

  button {
    width: 100%; } }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )


// SM
//@media screen and (min-width: $min-sm) and (max-width: $max-sm)


//SM && MD
//@media screen and (min-width: $min-sm) and (max-width: $max-md)


// LG
//@media screen and (min-width: $min-lg)

