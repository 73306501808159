.tv__prog {
  @include border(b);
  max-height: 440px;
  overflow: auto;
  // needed for js
  position: relative;

  .teaser__text {
    display: block;
    margin-top: 9px; }

  li {
    align-items: center;
    @include border(b);
    display: flex;
    padding: 13px 10px;

    &:last-child {
      border: none; } } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.tv__prog


// SM
@media screen and (min-width: $min-sm) and (max-width: $max-sm) {
  .tv__prog {
    max-height: 585px; } }

// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.tv__prog

// XS, SM and MD
@media screen and (max-width: $max-md) {
  .tv__prog {
    .teaser__headline {
      font-size: 1.5rem; } } }

// MD and LG
@media screen and (min-width: $min-md) {
  .tv__prog {
    border: none;
    max-height: 695px; } }

// LG
@media screen and (min-width: $min-lg) {
  .tv__prog {
    li {
      padding: 16px 10px; } } }
