.footer {
  background-color: #2d3c46;
  color: #ffffff;
  font-size: 1.2rem;
  margin: 0 -16px;
  padding: 20px 10px;
  text-align: center; }

/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs ) {
  .footer {
    margin: 0 -10px; } }

// SM
@media screen and (min-width: $min-sm ) and (max-width: $max-sm ) {
  .footer {
    margin: 0 -8px;
    padding: 17px $gutter-sm 20px; } }


// SM and MD
@media screen and (max-width: $max-md ) {
  .footer {
    padding: 12px $gutter-md 20px; } }

//  MD
@media screen and (max-width: $max-md ) {
  .footer {
    padding: 12px $gutter-md 20px; } }


// SM
@media screen and (min-width: $min-lg ) {
  .footer {
    font-size: 1.4rem;
    margin: 0 -18px; } }
