.thema__header {
  background: $lighter-grey;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;

  .thema__content {
    display: flex;
    flex-direction: column;
    padding: 15px;
    width: 66.66%; }

  figure {
    height: 100%;
    margin: auto;
    position: relative;
    width: 33.33%; }

  picture {
    z-index: 5; }

  figcaption {
    background-color: rgba(255,255,255,0.6);
    bottom: 0;
    right: 0;
    padding: 5px;
    position: absolute;
    z-index: 10; } }


/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs ) {
  .thema__header {
    .thema__content {
      padding: 5px;
      order: 2;
      width: 100%;
      .theme__detail__title {
        margin-bottom: 2px; } }

    figure {
      order: 1;
      width: 100%; } } }


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.thema__header


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.thema__header


// LG
//@media screen and (min-width: $min-lg )
//.thema__header
