.theme__detail__text {
	font-size: 1.5rem; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.theme__detail__text


// LG
@media screen and (min-width: $min-lg) {
	.theme__detail__text {
		font-size: 1.7rem; } }
