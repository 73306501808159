.poll__question {
	font-size: 1.6rem;
	margin-bottom: 10px; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.poll__question


// SM, MD and LG
@media screen and (min-width: $min-sm) {
	.poll__question {
		margin-top: -2px; } }

// MD and LG
//@media screen and (min-width: $min-md)
//poll__question

// LG
@media screen and (min-width: $min-lg) {
	.poll__question {
		font-size: 1.8rem; } }
