.nl-spezial__info {
  background-repeat: no-repeat;
  background-position: 16px center;
  background-size: 20px;
  padding: 16px 0 16px 50px;
  margin-bottom: 16px;
  font-size: 1.3rem;
  display: none; }

.nl-spezial__info a {
  text-decoration: underline; }

.nl-spezial__info--error {
  display: block;
  background-color: rgba(220, 0, 40, 0.1);
  background-image: url(../images/icons/icon__error.svg); }

.nl-spezial__info--load {
  display: block;
  background-color: rgba(50, 130, 85, 0.1);
  background-image: url(../images/icons/icon__loading_circle__grey.svg); }

.nl-spezial__info--success {
  display: block;
  background-color: rgba(50, 130, 85, 0.1);
  background-image: url(../images/icons/icon__success.svg); }

.nl-spezial__login {
  background-color: #F5F6F8;
  padding: 24px;
  margin-bottom: 32px;
  display: none; }

.nl-spezial--nologin .nl-spezial__login {
  display: block; }

.nl-spezial__login strong {
  font-size: 1.8rem;
  margin-bottom: 8px;
  display: block; }

.nl-spezial__login p {
  font-size: 1.5rem;
  margin-bottom: 24px; }

.nl-spezial__login .button {
  width: 185px; }

.nl-spezial__login .button--login {
  margin-right: 8px; }

.nl-spezial__abo {
  display: flex;
  align-items: center;
  margin-bottom: 16px; }

.nl-spezial__abo .button {
  width: 185px;
  margin-left: 32px;
  display: none; }

.nl-spezial__button--eval {
  background-color: #697882;
  cursor: default; }

.nl-spezial__button--eval:before {
  background-image: url(../images/icons/icon__loading_circle__grey.svg); }

.nl-spezial--load {
  background-image: url(../images/icons/icon__loading_circle__grey.svg);
  background-repeat: no-repeat;
  background-position: center 100px;
  background-size: 50px; }

.nl-spezial--load > * {
  visibility: hidden; }

.nl-spezial--load .title {
  visibility: visible; }

.nl-spezial--noabo .nl-spezial__button--noabo {
  display: inline-flex; }

.nl-spezial--abo .nl-spezial__button--abo {
  display: inline-flex; }

.nl-spezial--eval .nl-spezial__button--eval {
  display: inline-flex; }

.nl-spezial__teaser {
  display: flex; }

.nl-spezial__teaser .teaser__media {
  padding-right: 10px;
  width: 33.333%; }

.nl-spezial__teaser .teaser__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  width: 66.666%; }

.nl-spezial__teaser .teaser__text {
  display: block!important; }


/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
  .nl-spezial__abo {
    padding: 0 16px 24px 16px;
    border: 1px solid $lighter-grey;
    flex-direction: column; }

  .nl-spezial__abo>div {
    width: 100%; }

  .nl-spezial__abo .button {
    margin: 24px 0 0 0;
    width: 100%; }

  .nl-spezial__teaser {
    flex-direction: column; }

  .nl-spezial__teaser .teaser__media {
    width: calc(100% + 32px);
    padding: 0;
    margin: 0 -16px; }

  .nl-spezial__teaser .teaser__content {
    margin-top: 24px;
    width: 100%;
    padding: 0; }

  .nl-spezial--nologin .nl-spezial__login {
    display: flex;
    flex-direction: column;
    margin: -10px -10px 32px -10px; }

  .nl-spezial__login .button {
    width: 100%;
    margin-bottom: 8px; } }

