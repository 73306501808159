.header__nav__lvl1 {
  color: $black;
  max-width: 85%; }

/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
  .header__nav__lvl1 {
    max-width: 100%;
    width: 100%; } }


// XS, SM
@media screen and (max-width: $max-sm) {
  .header__nav__lvl1 {
    background: white;
    @include border();
    box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);
    display: none;
    position: absolute;
    min-width: 270px;
    top: 40px;
    z-index: $header__nav__lvl1;

    li {
      &:not(:last-child) {
        @include border(b); } }

    .scrollbar > ul > li {
      &.active {
        .header__nav__lvl2,
        > .header__nav__submenus {
          display: block; } } } } }

// SM
@media screen and (min-width: $min-sm) and (max-width: $max-sm) {
  .header__nav__lvl1 {
    top: 48px; } }

// MD and LG
@media screen and (min-width: $min-md) {
  .header__nav__lvl1 {
    display: block;
    height: 40px;
    margin: 20px 0 0 10px;
    order: 2;

    .scrollbar > ul {
      display: flex; }

    li {
      &.li--active,
      &:hover {
        > .header__nav__element {
          color: $red !important;
          font-weight: $bold !important; } } }

    .header__nav__submenus {
      background: white;
      left: 0;
      position: absolute;
      top: 60px;
      width: $container-width-md;
      z-index: $header__nav__submenus; }

    .scrollbar > ul > li {
      //&.li--active,
      //&:hover
      &.li--active {
        &:after {
          background: white;
          content: '';
          display: block;
          height: 100%;
          margin-left: -1px;
          position: relative;
          transform: translateY(-100%);
          z-index: -1; }

        .header__nav__submenus,
        .header__nav__lvl2 {
          //transition: .8s all
          //transition-delay: .6s
          visibility: visible; } }

      &:before {
        content: '';
        display: block;
        float: right;
        height: 27px;
        margin-top: 13px;
        width: 1px; }

      > .header__nav__element {
        color: white;
        padding: 0 15px;
        text-transform: uppercase;

        > *:first-child {
          // a tag or span for the RESSORTS section
          font-weight: $bold;
          line-height: 40px; } } } } }

// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.header__nav__lvl1


// LG
@media screen and (min-width: $min-lg) {
  .header__nav__lvl1 {
    margin-top: 35px;

    .scrollbar > ul > li {
      .header__nav__submenus {
        top: 85px;
        width: $container-width-lg; }

      &:before {
        height: 34px;
        margin-top: 16px; }

      > .header__nav__element {
        > *:first-child {
          // a tag or span for the RESSORTS section
          font-weight: $bold;
          line-height: 50px; } } } } }
