.weather__next__date {
	color: $black;
	display: block;
	font-size: 1.4rem;
	font-weight: $bold;
	line-height: 1;
	margin-bottom: 2px; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-sm )
//.weather__next__date


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.weather__next__date


// LG
@media screen and (min-width: $min-lg) {
	.weather__next__date {
		font-size: 1.6rem;
		margin-bottom: 5px; } }
