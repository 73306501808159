
.sport {
    .sport__ticker {
        display: flex;
        margin-top: 10px;
        .time {
            color: $dark-grey;
            font-weight: bold;
            margin-right: 10px; } } }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//  .sport


// SM
//@media screen and (min-width: $min-sm )
//  .sport


// SM, MD and LG
//@media screen and (min-width: $min-sm) and (max-width: $max-md )
//  .sport


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//  .sport


// LG
//@media screen and (min-width: $min-md)
//  .sport
