// Next and previous arrows
.bnews__slider__controls {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 10px;

  > * {
    margin: 0 5px; }

  .slick--next {
    order: 3; }

  .slick--next,
  .slick--prev {
    width: 32px;
    min-width: 32px;
    height: 32px;
    box-shadow: 0 2px 4px 0 rgba(164, 164, 164, 0.5);
    background-color: rgba(255, 255, 254, 0.01);
    border-radius: 16px;

    &:before {
      height: 100%;
      width: 100%;
      background-position: 7px,center;
      background-size: 20px; } } }

/* BREAKPOINTS */


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.bnews__slider__controls


// MD and LG
//@media screen and (min-width: $min-md )
//.bnews__slider__controls
