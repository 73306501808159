// Visible only on MD and LG
.sitemap {
  display: flex;
  margin-bottom: 0;
  margin-left: -16px;
  margin-right: -16px;
  padding: 15px;

  li {
    list-style: none; }

  .title {
    color: $black;
    font-size: 1.2rem; } }


/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
  .sitemap {
    display: none; } }


// SM
@media screen and (min-width: $min-sm) and (max-width: $max-sm) {
  .sitemap {
    display: none;
    margin-bottom: 0 !important;

    .social__wrapper {
      display: flex;
      justify-content: center;

      .title {
        align-self: center; } } } }


// MD
//@media screen and (min-width: $min-md) and (max-width: $max-md)
//.sitemap


// MD and LG
@media screen and (min-width: $min-md) {
  .sitemap {
    display: flex;

    li {
      margin-bottom: 2px; } } }


// LG
//@media screen and (min-width: $min-lg)
//.sitemap
