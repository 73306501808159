//.title--small


/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs ) {
	.title--small {
		background: $light-grey;
		color: $darker-grey; } }

// SM and MD
@media screen and (min-width: $min-sm) and (max-width: $max-md) {
	.title--small {
		font-size: 1.8rem; }

	.home {
		.title--small {
			font-size: 2.2rem; } } }

// LG
@media screen and (min-width: $min-lg ) {
	.title--small {
		font-size: 2rem; }

	.home {
		.title--small {
			font-size: 2.4rem; } } }
