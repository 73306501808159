.header__nav__push {
  align-items: center;
  display: flex;
  font-size: 1.2rem;
  font-weight: $semi-bold;
  position: relative;

  a {
    height: 100%;
    position: relative;

    &:before {
      background: url(../images/icons/icon__push.svg) no-repeat;
      background-position: center;
      background-size: contain;
      content: "";
      display: block;
      height: 100%;
      width: 20px; } } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.header__nav__push


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.header__nav__push


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.header__nav__push


// MD and LG
@media screen and (min-width: $min-md) {
  .header__nav__push {
    align-self: flex-end;
    font-size: 1.5rem;
    height: 28px;
    padding: 0 10px;

    a {
      align-items: flex-start !important;
      height: 34px; }

    span {
      line-height: 20px; } } }

// LG
@media screen and (min-width: $min-lg) {
  .header__nav__push {
    font-size: 1.7rem;
    height: 34px;
    line-height: 1;

    a {
      &:before {
        align-items: flex-start; } } } }
