.table--col4 {
  td {
    width: 25%;

    &:first-child {
      font-weight: $semi-bold; } } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.table--col4


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.table--col4


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.table--col4

// LG
//@media screen and (min-width: $min-lg )
//.table--col4
