.teaser__mediatype {
  background: #46555f;
  color: white;
  padding: 1px 4px; }



/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )


// MD and LG
//@media screen and (min-width: $min-md)


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )


// LG
//@media screen and (min-width: $min-lg)
