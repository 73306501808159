.article__headline {
  display: block;
  font-size: 2rem;
  line-height: 1.2; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//  .article__headline


// SM
@media screen and (min-width: $min-sm) and (max-width: $max-sm) {
  .article__headline {
    font-size: 2.2rem; } }


// MD
@media screen and (min-width: $min-md) and (max-width: $max-md) {
  .article__headline {
    font-size: 2.6rem; } }


// LG
@media screen and (min-width: $min-lg) {
  .article__headline {
    font-size: 3rem; } }
