.teaser__text {
  font-size: 1.5rem;
  line-height: 2rem;
  margin-top: 8px; }

/* BREAKPOINTS */


// XS and SM
@media screen and (max-width: $max-sm) {
  .teaser__text {
    display: none; } }

// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.teaser__text


//MD
@media screen and (min-width: $min-md) and (max-width: $max-md) {
  .teaser__text {
    margin-top: 10px; } }

// LG
@media screen and (min-width: $min-lg) {
  .teaser__text {
    font-size: 1.7rem;
    line-height: 2.1rem; } }
