.header__nav__live {
  align-items: center;
  display: flex;
  font-size: 1.2rem;
  font-weight: $semi-bold;
  position: relative;
  text-transform: uppercase;

  a {
    position: relative;

    &:before {
      background: url(../images/icons/icon__livetv.svg) no-repeat;
      background-position: center;
      background-size: contain;
      content: "";
      display: block;
      height: 20px;
      margin-right: 5px;
      width: 6px; } } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.header__nav__live


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.header__nav__live


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.header__nav__live


// MD and LG
@media screen and (min-width: $min-md) {
  .header__nav__live {
    display: none; } }

// LG
//@media screen and (min-width: $min-lg)
//.header__nav__live


