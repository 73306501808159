#header__nav__timezones {
    color: $dark_grey;
    display: flex;
    font-weight: bold;
    position: absolute;
    right: 10px;
    top: 5px;

    .header__nav__date {
        margin-right: 5px; }


    .header__nav__time {
        display: flex;

        div {
            &:last-of-type {
                &:after {
                    display: none; } }

            &:after {
                content: '|';
                display: inline-flex;
                padding: 0 3px; } } } }


/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs ) {
  #header__nav__timezones {
      display: none; } }

// SM
@media screen and (min-width: $min-sm ) and (max-width: $max-sm ) {
  #header__nav__timezones {
      display: none; } }


// SM, MD and LG
//@media screen and (min-width: $min-sm )
//#header__nav__timezones


// MD
@media screen and (min-width: $min-md ) and (max-width: $max-md ) {
  #header__nav__timezones {
    font-size: 1.1rem; } }


// LG
@media screen and (min-width: $min-lg ) {
  #header__nav__timezones {
    font-size: 1.3rem; } }
