.theme {
	.paging {
		@include border(b);
		padding-bottom: 25px; } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.theme


// SM, MD and LG
@media screen and (min-width: $min-sm) {
	.theme {
		.paging {
			margin-bottom: 30px; }

		.teaser--inline {
			.teaser__text {
				display: block; } } } }
