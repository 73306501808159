.quiz {
  >.row {
    align-items: center;
    border-bottom: 1px solid $lighter-grey;
    margin-bottom: 20px;
    padding-bottom: 20px; }

  .button--right {
    margin-left: auto; }

  p {
    margin-bottom: 13px; } }


/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs ) {
  .quiz {
    .teaser__media {
      margin: 0 (-$gutter-xs-real) 10px; } } }


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.quiz


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.quiz


// LG
//@media screen and (min-width: $min-lg )
//.quiz
