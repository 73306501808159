.header__nav__video {
  align-items: center;
  display: flex;
  font-size: 1.2rem;
  font-weight: $semi-bold;
  position: relative;

  a {
    position: relative; } }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )


// MD and LG
@media screen and (min-width: $min-md) {
  .header__nav__video {
    display: none; } }

// LG
//@media screen and (min-width: $min-lg)
