.sport__games {
	color: $black;
	font-size: 1.5rem;
	margin-bottom: 5px;

	&.formula {
		margin-bottom: 15px; }

	li {
		@include border(b);
		display: flex;
		justify-content: center;
		padding: 5px;

		> div:first-of-type {
			align-items: center;
			display: flex;
			flex-direction: column;
			width: 100%;

			> div:first-of-type {
				align-items: center;
				display: flex;
				width: 100%; } }

		div {
			& > *:not(.sport__team) {
				flex-shrink: 0; } } } }


/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
	.sport__games {
		font-size: 1.4rem; } }

// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.sport__games


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.sport__games


// LG
@media screen and (min-width: $min-lg) {
	.sport__games {
		font-size: 1.7rem;
		margin-bottom: 8px;

		.teaser__headline {
			font-size: 2rem; } } }
