.day {
  &.day-green {
    .day__header {
      border-color: $green;

      &::after {
        border-color: $green; }

      .day__author__border {
        border-color: $green; }

      .day__header__title, .day__author {
        color: $green;

        .article__kicker, .article__headline {
          color: $green; } } }

    .day__teaser {

      .day__teaser__hour {
        color: $green; }

      &:not(:last-child) {
        border-color: $green; } } }


  &.day-blue {
    .day__header {
      border-color: $blue;

      &::after {
        border-color: $blue; }

      .day__author__border {
        border-color: $blue; }

      .day__header__title, .day__author {
        color: $blue;

        .article__kicker, .article__headline {
          color: $blue; } } }

    .day__teaser {

      .day__teaser__hour {
        color: $blue; }

      &:not(:last-child) {
        border-color: $blue; } } }

  &.day-red {
    .day__header {
      border-color: $red;

      &::after {
        border-color: $red; }

      .day__author__border {
        border-color: $red; }

      .day__header__title, .day__author {
        color: $red;

        .article__kicker, .article__headline {
          color: $red; } } }

    .day__teaser {

      .day__teaser__hour {
        color: $red; }

      &:not(:last-child) {
        border-color: $red; } } } }
