//.table--col2r


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.table--col2r


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.table--col2r


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.table--col2r


// MD and LG
@media screen and (min-width: $min-md ) and (max-width: $max-md ) {
  .table--col2r {
    .table__elem {
      > * {
        > * {
          width: 80%;

          &:first-child {
            width: 20%; } } } } } }


// LG
//@media screen and (min-width: $min-lg )
//.table--col2r
