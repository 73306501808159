.article__media {
  color: $dark-grey;
  order: 3;

  picture {
    margin-bottom: 8px; }

  video {
    cursor: pointer; } }

/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
  .article__media {
    margin: 0 (-$gutter-xs-real) 15px; } }

// SM
@media screen and (min-width: $min-sm) and (max-width: $max-sm) {
  .article__media {
    margin-bottom: 23px;

    picture {
      margin-bottom: 9px; } } }

// MD and LG
@media screen and (min-width: $min-md) {
  .article__media {
    margin-bottom: 19px;

    picture {
      margin-bottom: 15px; } } }

// LG
@media screen and (min-width: $min-lg) {
  .article__media {
    margin-bottom: 16px; } }
