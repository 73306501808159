.adviser__links {
    a {
        align-items: center;
        border-bottom: 1px solid $light-grey;
        display: flex;

        &:before {
            background: url(../images/icons/icon__arrow-red.svg) no-repeat;
            content: '';
            height: 10px;
            margin-right: 5px;
            width: 10px; }

        &:last-of-type {
            border: none;
            font-size: 1.2rem;
            text-transform: uppercase;
            margin-bottom: 0;
            margin-top: 15px;
            padding: 0;

            &:before {
                background: url(../images/icons/icon__arrow-white.svg) no-repeat; } } } }


/* BREAKPOINTS */

// SM
@media screen and (max-width: $max-sm ) {
    .adviser__links {
        border-bottom: 2px solid #ffffff;
        padding: 10px;
        a {
            margin-bottom: 8px;
            padding-bottom: 8px;
            &:last-of-type {
                margin-top: 10px; } } } }



// Max MD
@media screen and (max-width: $max-md ) {
    .adviser__links {
        a {
            font-size: 1.5rem; } } }

// MD
@media screen and (min-width: $min-md ) {
    .adviser__links {
        border-right: 1px solid #ffffff;
        a {
            margin-bottom: 10px;
            padding-bottom: 10px;
            &:last-of-type {
                margin-top: 15px; } } } }

// Min & Max MD
@media screen and (min-width: $min-md ) and (max-width: $max-md ) {
    .adviser__links {
        padding: 16px; } }

// SM
@media screen and (min-width: $min-lg ) {
    .adviser__links {
        padding: 20px;

        a {
            font-size: 1.7rem;

            &:last-of-type {
                font-size: 1.4rem; } } } }

