.feedback__popup__close {
  cursor: pointer;
  font-size: 26px;
  margin-top: -5px; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.feedback__popup__close


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.feedback__popup__close


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.feedback__popup__close


// LG
//@media screen and (min-width: $min-lg )
//.feedback__popup__close
