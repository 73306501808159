.title__ad {
  background: $dark-grey;
  border: 1px solid #FFFFFF;
  color: white;
  padding: 3px 5px;
  margin-left: auto;
  font-size: 1.1rem;
  line-height: normal; }

.title--small {
  .title__ad {
    border: none; } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.title__ad


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.title__ad


// MD
@media screen and (min-width: $min-md ) and (max-width: $max-md ) {
  .title__ad {
    font-size: 1.2rem; } }


// LG
@media screen and (min-width: $min-lg ) {
  .title__ad {
    font-size: 1.3rem; } }


// SM and MD and LG
@media screen and (min-width: $min-sm ) {
  .title__ad {
    border: none; } }
