.table__elem {
  @include border(t);
  width: 100%;

  img {
    margin-right: 5px; } }

// Only not responsive tables
.table:not(.table--resp) {
  .table__elem {
    &:nth-child(n+2) {
      .table__head {
        display: none; } }

    &:nth-child(odd) {
      .table__values {
        background: $tables-grey; } }

    > * { // .table__head and .table__values
      display: flex;
      flex-wrap: wrap;

      > * { // span and time elements inside
        span {
          white-space: nowrap; } } }

    img {
      align-self: flex-start;
      float: left; } } }


/* BREAKPOINTS */


// XS and SM
@media screen and (max-width: $max-sm ) {
  .table--resp {
    .table__elem {
      display: flex;
      margin-bottom: 20px;

      > * {
        >* {
          @include border(b);
          display: block;
          @include ellipsis();
          padding: 7px 10px;
          width: 100%; } } } } }

// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.table__elem


// MD and LG
@media screen and (min-width: $min-md ) {
  .table__elem {
    &:nth-child(n+2) {
      .table__head {
        display: none; } }

    &:nth-child(odd) {
      .table__values {
        background: $tables-grey; } }

    > * { // .table__head and .table__values
      display: flex;
      flex-wrap: wrap;
      padding: 7px 0;

      > * { // span and time elements inside
        padding: 0 5px;

        span {
          white-space: nowrap; } } }

    img {
      align-self: flex-start;
      float: left; } } }


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.table__elem


// LG
@media screen and (min-width: $min-lg ) {
  .table__elem {
    > * {
      padding: 5px 0; } } }
