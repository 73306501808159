.extcontent__more {
  text-align: right;
  margin-top: 10px;

  a {
    color: $red;
    font-weight: bold;
    font-size: 1.4rem;
    padding-left: 12px;
    background: url(../images/icons/icon__arrow-headline.svg) left center no-repeat;
    background-size: 8px 8px; } }




/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )


// SM
//@media screen and (min-width: $min-sm) and (max-width: $max-sm)


//SM && MD
@media screen and (min-width: $min-sm) and (max-width: $max-md) {
  .extcontent__more {
    a {
      font-size: 1.5rem; } } }


// LG
@media screen and (min-width: $min-lg) {
  .extcontent__more {
    a {
      font-size: 1.7rem; } } }
