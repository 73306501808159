.theme__credit {
  color: $dark-grey;
  font-size: 1.1rem;
  margin-top: 8px; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.theme__credit


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.theme__credit


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.theme__credit


// LG
//@media screen and (min-width: $min-lg )
//.theme__credit
