.article__portrait {
  display: inline-flex;
  flex: 0 0 94px;
  padding-left: 10px;

  img {
    background: transparent; } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.article__portrait


// SM
@media screen and (min-width: $min-sm) and (max-width: $max-sm) {
  .article__portrait {
    flex: 0 0 145px; } }

// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .article__portrait {
    flex: 0 0 140px;
    margin-bottom: 26px; } }

// MD
//@media screen and (min-width: $min-md) and (max-width: $max-md)
//article__portrait
//flex: 0 0 196px

// LG
//@media screen and (min-width: $min-lg)
//article__portrait
//flex: 0 0 181px
