.day__author {
  color: $dark-grey;
  display: block;
  font-size: 1.5rem;
  font-style: italic;
 }  //margin-bottom: 20px

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs)
//.day__author


// SM, MD and LG
//@media screen and (min-width: $min-sm )
//.day__author

// LG
@media screen and (min-width: $min-lg) {
  .day__author {
    font-size: 1.7rem; } }

