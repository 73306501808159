
.abo-select {
	.abo-select__title {
		font-size: 2.6rem;
		margin-bottom: 8px; }

	.abo-select__subtitle {
		font-size: 1.5rem;
		display: block;
		margin-bottom: 16px; }

	.abo-select__go {
		font-size: 1.3rem;
		color: #46555F;
		text-decoration: underline;
		display: block;
		margin-bottom: 40px; }

	.abo-select__box {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin-bottom: 16px; }

	.abo-select__info {
		ul {
			margin-left: 30px;
			margin-bottom: 20px;

			li {
				font-size: 1.5rem;
				line-height: 2.2; } } }

	.abo-select__disc {
		display: block;
		margin-bottom: 20px;
		color: $darker-grey;
		font-size: 1.3rem; } }

.abo-teaser {
	padding: 22px;
	border: 1px solid $light-grey;
	background: #FFF;
	margin-bottom: 16px;

	picture {
		display: flex;
		justify-content: center;
		margin-bottom: 32px;
		width: 100%; }

	img {
		width: 300px; }

	.abo-teaser__headline {
		display: block;
		font-size: 2rem;
		font-weight: bold;
		margin-bottom: 16px; }

	ul {
		margin: 0 0 32px 24px;

		li {
			font-size: 1.5rem;
			line-height: 1.5; } }

	.abo-teaser__price {
		font-size: 2.2rem;
		margin-bottom: 20px;
		display: block; }

	.button {
		display: flex; } }





/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )



// SM and MD and LG
@media screen and (min-width: $min-sm ) {
	.abo-select {
		.abo-select__box {
			flex-direction: row;
			padding: 32px 0 16px 0;
			background: $lightest-grey;
			margin-bottom: 32px; }

		.abo-select__info {
			ul {
				li {
					font-size: 1.7rem; } } }

		.abo-select__disc {
			font-size: 1.5rem; } }

	.abo-teaser {
		width: 350px;

		&:first-child {
			margin-left: auto;
			margin-right: 16px; }

		&:last-child {
			margin-right: auto; } } }


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )



// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )



// LG
@media screen and (min-width: $min-lg) {
	.abo-select {
		.abo-select__title {
			font-size: 3rem; }

		.abo-select__subtitle {
			font-size: 1.7rem; }

		.abo-select__go {
			font-size: 1.5rem; }

		.abo-teaser {
			width: 450px;

			&:first-child {
				margin-right: 20px; }

			.abo-teaser__headline {
				font-size: 2.2rem; }

			ul {
				li {
					font-size: 1.7rem; } }

			.abo-teaser__price {
				font-size: 2.4rem; } } } }


