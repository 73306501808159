.header__nav__lvl3 {
  display: none; }


/* BREAKPOINTS */


// XS and SM
@media screen and (max-width: $max-sm) {
  .header__nav__lvl3 {
    @include border(t);

    a {
      width: 100%; }

    > li {
      background: $lighter-grey;

      &:not(:last-child) {
        @include border(b, $color: white); }

      &:before {
        // We don't display the triangle icons (only on L and XL)
        display: none; } } } }

// MD and LG
@media screen and (min-width: $min-md) {
  .header__nav__lvl3 {
    background: white;
    @include border(r);
    bottom: 0;
    left: 254px;
    padding-left: $gutter-md-real;
    position: absolute;
    top: 17px;
    width: 254px;
    z-index: $header__nav__lvl3;

    > li {
      &.li--lvl3--active {
        .header__nav__lvl4 {
          display: block; } } } } }

// LG
@media screen and (min-width: $min-lg) {
  .header__nav__lvl3 {
    padding-left: $gutter-lg-real; } }
