.nl-loggedin__info {
  background-repeat: no-repeat;
  background-position: 16px center;
  background-size: 20px;
  padding: 16px 0 16px 50px;
  margin-bottom: 16px;
  font-size: 1.3rem;
  display: none; }

.nl-loggedin__info--error {
  display: block;
  background-color: rgba(220, 0, 40, 0.1); }

.nl-loggedin__info--load {
  display: block;
  background-color: rgba(50, 130, 85, 0.1);
  background-image: url(../images/icons/icon__loading_circle__grey.svg); }

.nl-loggedin__info--success {
  display: block;
  background-color: rgba(50, 130, 85, 0.1);
  background-image: url(../images/icons/icon__success.svg); }

.nl-loggedin__notice {
  margin-bottom: 20px; }

.nl-loggedin__abo {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e1e6eb;
  padding-bottom: 20px;
  margin-bottom: 20px; }

.nl-loggedin__abo article {
  margin: 0; }

.nl-loggedin__abo .button {
  width: 185px;
  margin-left: 32px; }

.nl-loggedin__button--eval {
  background-color: #697882;
  cursor: default;
  display: none; }

.nl-loggedin__button--eval:before {
  background-image: url(../images/icons/icon__loading_circle__grey.svg); }

.nl-loggedin__abo--wait .nl-loggedin__button--eval:before {
  background-image: none; }

.nl-loggedin--load {
  background-image: url(../images/icons/icon__loading_circle__grey.svg);
  background-repeat: no-repeat;
  background-position: center 100px;
  background-size: 50px;
  height: 200px; }

.nl-loggedin--load > * {
  visibility: hidden; }

.nl-loggedin__abo--noabo .nl-loggedin__button--noabo {
  display: inline-flex; }

.nl-loggedin__abo--abo .nl-loggedin__button--abo {
  display: inline-flex; }

.nl-loggedin__abo--wait  .nl-loggedin__button--noabo,
.nl-loggedin__abo--wait  .nl-loggedin__button--abo,
.nl-loggedin__abo--eval  .nl-loggedin__button--noabo,
.nl-loggedin__abo--eval  .nl-loggedin__button--abo {
  display: none; }

.nl-loggedin__abo--eval .nl-loggedin__button--eval,
.nl-loggedin__abo--wait .nl-loggedin__button--eval {
  display: inline-flex; }

.nl-loggedin__teaser {
  display: flex;
  width: 100%; }

.nl-loggedin__teaser .teaser__media {
  padding-right: 10px;
  width: 33.333%; }

.nl-loggedin__teaser .teaser__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  width: 66.666%; }

.nl-loggedin__teaser .teaser__text {
  display: block!important; }


/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
  .nl-loggedin__abo {
    padding: 0 16px 24px 16px;
    border: 1px solid $lighter-grey;
    flex-direction: column; }

  .nl-loggedin__abo>div {
    width: 100%; }

  .nl-loggedin__abo .button {
    margin: 24px 0 0 0;
    width: 100%; }

  .nl-loggedin__teaser {
    flex-direction: column; }

  .nl-loggedin__teaser .teaser__media {
    width: calc(100% + 32px);
    padding: 0;
    margin: 0 -16px; }

  .nl-loggedin__teaser .teaser__content {
    margin-top: 24px;
    width: 100%;
    padding: 0; } }

