// Opens header__nav__lvl1 on XS and SM
// .header__nav--open


/* BREAKPOINTS */


// XS and SM
@media screen and (max-width: $max-sm) {
  .header__nav--open {
    .header__nav__lvl1 {
      border-top: none;
      display: block; }

    .header__burger {
      background: white;
      @include border();
      border-bottom: none;

      &:before {
        background: url(../images/icons/icon__burger--active.svg); } } } }

// SM
//@media screen and (min-width: $min-sm) and (max-width: $max-sm)
//.header__nav--open
