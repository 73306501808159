.teaser--gallery {
  picture {
    &:before {
      background: url(../images/icons/icon__gallery.svg) no-repeat; } } }

/* BREAKPOINTS */


// SM, MD and LG
//@media screen and (min-width: $min-sm)
//teaser--gallery

// MD and LG
//@media screen and (min-width: $min-md)
//teaser--gallery

// LG
//@media screen and (min-width: $min-lg)
//teaser--gallery
