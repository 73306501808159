// The article class is used only on article, video detail or gallery pages.
// Don't use it anywhere else as there is a script detecting its presence and making the social links moving!
//.article


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.article


// SM
@media screen and (min-width: $min-sm) and (max-width: $max-sm) {
  .article {
    // padding-bottom: 20px
    .title__ad {
      margin-right: 8.333%; } } }

// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .article {
    .title {
      font-size: 2.2rem;
      margin-bottom: 6px; }

    > .row {
      min-height: 140px; } } }

// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.article


// LG
@media screen and (min-width: $min-lg) {
  .article {
    .title {
      font-size: 2.4rem;
      margin-bottom: 9px;
      .title__ad {
        margin-right: 8.333%; } }

    > .row {
      min-height: 175px; } } }
