.slider--indexed {
  counter-reset: index;

  .teaser__media {
    counter-increment: index;
    position: relative;

    &:before {
      background: $red;
      color: white;
      content: counter(index);
      font-size: 1.5rem;
      font-weight: $bold;
      left: 15px;
      line-height: 1.7;
      position: absolute;
      text-align: center;
      top: 0;
      width: 25px;
      z-index: $slider--indexed; } } }

/* BREAKPOINTS */


// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .slider--indexed {
    .teaser__media {
      &:before {
        font-size: 1.7rem;
        width: 30px; } } } }
