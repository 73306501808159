.article__share-wrapper {
  display: flex;
  border-top: 1px solid $lighter-grey;
  border-bottom: 1px solid $lighter-grey;
  padding: 5px 0;
  margin-bottom: 20px;
  align-items: center;
  height: 54px; }

.article__share-action {
  font-size: 1.5rem;
  line-height: 22px;
  text-decoration: underline;
  color: $darker-grey;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: $black; }

  &:before {
    content: "";
    background-image: url(../images/icons/icon__share-arrow.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 22px;
    height: 22px;
    margin: 0 10px 0 5px; } }

.article__share-main {
  display: none;

  > * {
    cursor: pointer;
    margin: 0 4px;
    padding: 9px;
    position: relative;
    align-items: center;
    display: flex;

    &:hover {
      background-color: $lighter-grey; }

    span {
      display: none; }

    .article__share--clicked {
      display: inline; } }

  .article__share__print {
    display: none; } }

.article__share-follow {
  display: flex;
  align-items: center;
  margin-left: auto;
  font-size: 1.5rem;

  > * {
    padding: 9px; }

  a:hover {
    background-color: $lighter-grey; } }

.article__share-followGN {
  font-size: 1.5rem;
  line-height: 22px;
  text-decoration: underline;
  color: $darker-grey;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: auto;

  &:hover {
    color: $black; }

  &:after {
    content: "";
    background-image: url(../images/icons/icon__google-light.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 22px;
    height: 22px;
    margin: 0 10px 0 5px; } }


.article__share-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  z-index: 1000000;

  .article__share-overlaydisp {
    width: 300px;
    background: white;
    position: fixed;
    left: calc(100% / 2 - 150px);
    top: 100px;
    padding: 16px 16px 16px 24px; }

  .article__share-overlayhead {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 10px; }

  .article__share-overlayclosed {
    background-image: url(../images/icons/icon__close_darker-grey.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 22px;
    height: 22px;
    cursor: pointer; }

  .article__share-main {
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;

    > * {
      padding: 9px 0;
      margin: 10px 0 0 0;

      span {
        display: inline-block;
        margin-left: 20px; } } }

  .article__share-overlayhead {
    display: flex; }

  .article__share-overlayclosed {
    margin-left: auto; } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//  .article__share


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.article__share


// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .article__share-action {
    display: none; }

  .article__share-main {
    display: flex;

    .article__share__whatsapp {
      display: none; }

    .article__share__print {
      display: block; } } }


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )


// LG
//@media screen and (min-width: $min-lg)



