.teaser--day {
  figcaption {
    display: flex;
    margin-top: -24px;
    position: relative;
    text-transform: uppercase;
    width: 100%;
    z-index: $teaser--day;

    span, a {
      background: $red;
      color: white;
      font-size: 1.4rem;
      font-weight: $bold;
      line-height: 1.2;
      margin-bottom: 3px;

      @media (inverted-colors: inverted) {
        background: $inv-red; } } }

  .teaser__related {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;

    figure {
      position: relative; }

    figcaption {
      margin-top: 0;
      white-space: nowrap; } }

  .teaser__related__element {
    display: flex;
    margin-top: 20px;
    width: 100%;

    .teaser__headline {
      font-size: 1.8rem; }

    > a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 5px;
      width: 66.666%; }

    figure {
      width: 33.333%; } }

  .teaser--day-green {
    a, span {
      background-color: transparent;
      color: $green;
      line-height: inherit;
      padding: 0;

      @media (inverted-colors: inverted) {
        color: $inv-green; } } }

  .teaser--day-blue {
    a, span {
      background-color: transparent;
      color: $blue;
      line-height: inherit;
      padding: 0;

      @media (inverted-colors: inverted) {
        color: $inv-blue; } } }

  .teaser--day-red {
    a, span {
      background-color: transparent;
      color: $red;
      line-height: inherit;
      padding: 0;

      @media (inverted-colors: inverted) {
        color: $inv-red; } } }

  .teaser__media {
    width: 100%;
    figcaption {
      line-height: 1.5em;
      padding-left: 5px; } } }

/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
  .teaser--day {
    .teaser__related {
      .teaser__related__element {
        &:first-of-type {
          margin-top: 0; } }

      figure {
        padding-right: 5px; } } } }


// SM and MD and LG
@media screen and (min-width: $min-sm) {
  .teaser--day {
    .teaser__related {
      .teaser__related__element {
        &:first-of-type {
          margin-top: 0; } }

      figure {
        padding-right: 8px; } } } }


// SM
@media screen and (min-width: $min-sm) and (max-width: $max-sm) {
  .teaser--day {
    .teaser__related {
      .teaser__related__element {
        .teaser__headline {
          font-size: 2.0rem; } } }

    figcaption {
      a, span {
        font-size: 1.6rem; } } } }


// MD
@media screen and (min-width: $min-md) and (max-width: $max-md) {
  .teaser--day {
    .teaser__related {
      .teaser__related__element {
        .teaser__headline {
          font-size: 2.2rem; } } }

    figcaption {
      a, span {
        font-size: 1.6rem; } } } }


// LG
@media screen and (min-width: $min-lg) {
  .teaser--day {
    .teaser__related {
      .teaser__related__element {
        .teaser__headline {
          font-size: 2.6rem; } } }

    figcaption {
      a, span {
        font-size: 1.8rem; } } } }
