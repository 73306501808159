//.author__content



/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs ) {
  .author__content {
    display: flex;
    width: 100%; } }

// SM
// @media screen and (min-width: $min-sm ) //and (max-width: $max-sm )
