.tv__days {
  display: flex;
  margin: 13px auto 10px;
  width: 320px;

  .slick-slide {
    color: $dark-grey;
    padding-bottom: 5px;
    text-align: center;

    span {
      display: block;
      font-size: 1.5rem;
      font-weight: $bold;

      &:last-child {
        font-size: 1.3rem;
        margin-top: 3px; } } }

  .slick-disabled {
    cursor: default;
    display: block !important;
    opacity: 0.3; }

  .active {
    @include border(b, $color: $red, $width: 3px);
    color: $red; }

  .slick-arrow {
    padding: 0 10px 8px;

    &:before {
      height: 20px;
      width: 11px; } } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.tv__days


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.tv__days


// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .tv__days {
    margin: 4px auto 15px;
    width: 360px;

    .slick-arrow {
      &:before {
        height: 26px;
        width: 14px; } } } }

//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.tv__days


// LG
@media screen and (min-width: $min-lg) {
  .tv__days {
    margin: 5px auto 23px;

    .slick-slide {
      span {
        font-size: 1.7rem;

        &:last-child {
          font-size: 1.5rem; } } } } }
