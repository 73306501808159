.day__date {
  color: $red;
  font-size: 1.4rem;
  font-weight: $semi-bold;
  margin-bottom: 2px; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.day__date


// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .day__date {
    font-size: 1.6rem; } }

// LG
@media screen and (min-width: $min-lg) {
  .day__date {
    font-size: 1.8rem; } }
