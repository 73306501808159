.sport__footer {
	color: $red;
	display: flex;
	font-size: 1.5rem;
	font-weight: $semi-bold;
	justify-content: flex-end;

	@media (inverted-colors: inverted) {
		color: $inv-red; }

	&:not(:last-of-type) {
		margin-bottom: 17px; }

	a {
		align-items: center;
		display: flex;
		margin-left: 10px;

		&:before {
			background: url(../images/icons/icon__arrow-red.svg) no-repeat;
			content: '';
			height: 8px;
			width: 8px; } } }

/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs ) {
	.sport__footer {
		font-size: 1.2rem; } }


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.sport__footer


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.sport__footer


// LG
@media screen and (min-width: $min-lg) {
	.sport__footer {
		font-size: 1.7rem;

		&:not(:last-of-type) {
			margin-bottom: 14px; } } }
