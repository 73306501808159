.table__head {
  color: $red;
  font-weight: $bold; }


/* BREAKPOINTS */


// XS and SM
@media screen and (max-width: $max-sm ) {
  .table--resp {
    .table__head {
      width: 25%; } } }


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.table__head


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.table__head


//MD
@media screen and (min-width: $min-md ) and (max-width: $max-md ) {
  .table__head {
    @include border(b); } }


// LG
//@media screen and (min-width: $min-lg )
//.table__head
