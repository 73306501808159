.teaser--hero {
  // By default, all teaser__related are not displayed, except for hero teasers.
  .teaser__related {
    display: block; }

  > .teaser__content {
    // not related elements
    a {
      .teaser__headline {
        font-size: 2.0rem; } } } }


/* BREAKPOINTS */


// XS and SM
//@media screen and (max-width: $max-sm)
//.teaser--hero
//  margin-bottom: 16px

// XS
@media screen and (max-width: $max-xs) {
  .teaser--hero {
    .teaser__media {
      margin-left: -$gutter-xs-real;
      margin-right: -$gutter-xs-real;
      width: calc(100% + #{$gutter-xs-real * 2}); } }

  // IN A BOX
  .box {
    > a,
    .title {
      + .row {
        .group {
          .teaser--hero {
            margin-top: -10px; } } } } } }


// SM and MD and LG
//@media screen and (min-width: $min-sm)
//.teaser--hero
//  margin-bottom: 25px


// SM
@media screen and (min-width: $min-sm) and (max-width: $max-sm) {
  .teaser--hero {
    > .teaser__content {
      // not related elements
      a {
        .teaser__headline {
          font-size: 2.2rem; } } }

    .teaser__text {
      display: block; } } }


// MD
@media screen and (min-width: $min-md) and (max-width: $max-md) {
  .teaser--hero {
    > .teaser__content {
      // not related elements
      a {
        .teaser__headline {
          font-size: 2.6rem; } } } } }


// LG
@media screen and (min-width: $min-lg) {
  .teaser--hero {
    > .teaser__content {
      // not related elements
      a {
        .teaser__headline {
          font-size: 3.0rem; } } }

    .teaser__text {
      margin-top: 14px; } } }
