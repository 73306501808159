.article__aside__question {
  background: $lighter-grey;
  color: $darker-grey;
  display: flex;
  font-weight: $bold;
  margin: 0 -10px 15px;
  padding: 14px 10px;

  &:after {
    align-self: center;
    background: url(../images/icons/icon__qmark.svg) no-repeat center center;
    content: '';
    flex-shrink: 0;
    justify-self: center;
    height: 50px;
    width: 50px; } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.article__aside__question


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.article__aside__question


//MD
@media screen and (min-width: $min-md) and (max-width: $max-md) {
  .article__aside__question {
    margin: 0 -16px 15px;
    padding: 14px 16px;

    &:after {
      height: 55px;
      width: 55px; } } }

// LG
@media screen and (min-width: $min-lg) {
  .article__aside__question {
    margin: 0 -20px 15px;
    padding: 14px 20px; } }
