.media {
  background: $lighter-grey;
  position: relative;

  &:before {
    background-size: contain;
    bottom: 15px;
    content: '';
    height: 50px;
    left: 15px;
    position: absolute;
    width: 50px;
    z-index: $media; }

  &[class*=" media--"] {
    overflow: hidden;

    img {
      position: absolute; } } }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.media


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.media


// MD and LG
@media screen and (min-width: $min-md) {
  .media {
    &:before {
      height: 54px;
      width: 54px; } } }

// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.media


// LG
@media screen and (min-width: $min-lg) {
  .media {
    &:before {
      height: 64px;
      width: 64px; } } }
