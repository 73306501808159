.moderator {
	display: flex;
	flex-wrap: wrap;

	picture {
		width: 100%; }

	.title {
		display: block;
		width: 100vw; }

	.title--dark {
		margin-bottom: 8px; }

	.teaser__media {
		float: left;
		margin: 0 10px 10px 0;
		max-width: 230px;
		width: 33.333%; }

	.teaser__text {
		display: block;
		margin-top: 11px;

		p {
			margin-bottom: 15px; }

		> * {
			&:last-child {
				margin-bottom: 0; } } }

	.teaser__headline {
		font-size: 1.8rem;

		.teaser__kicker {
			margin-top: -3px; } } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.moderator


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.moderator


// SM, MD and LG
@media screen and (min-width: $min-sm) {
	.moderator {
		padding-bottom: 30px;

		.title--dark {
			font-size: 2.2rem;
			margin-bottom: 16px; }

		.teaser__headline {
			font-size: 2.6rem; }

		.teaser__media {
			margin-right: 20px; } } }

//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.moderator


// LG
@media screen and (min-width: $min-lg) {
	.moderator {
		.teaser__kicker {
			font-size: 1.9rem; }

		.teaser__headline {
			font-size: 2.8rem; } } }
