.escalation {
  @include border(b, $width:3px, $color:$red);
  margin-bottom: 16px;
  width: 100%;

  .title {
    background: $red;
    color: white; } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.escalation


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.escalation


// SM, MD and LG
@media screen and (min-width: $min-sm ) {
  .escalation {
    margin-bottom: 20px;

    .title {
      margin-bottom: 10px;
      padding: 10px; } } }


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.escalation


// LG
//@media screen and (min-width: $min-lg )
//.escalation
