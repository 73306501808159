//.slider__slides


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.slider__slides


// SM
@media screen and (min-width: $min-sm) and (max-width: $max-sm) {
  .slider__slides {
    margin-left: -$gutter-sm;
    margin-right: -$gutter-sm; } }

// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .slider__slides {
    position: relative; } }

// MD
@media screen and (min-width: $min-md) and (max-width: $max-md) {
  .slider__slides {
    margin-left: -$gutter-md;
    margin-right: -$gutter-md; } }

// MD and LG
@media screen and (min-width: $min-md) {
  .slider__slides {
    .slick--prev,
    .slick--next {
      width: 50px; } } }

// LG
@media screen and (min-width: $min-lg) {
  .slider__slides {
    margin-left: -$gutter-lg;
    margin-right: -$gutter-lg; } }
