.sport__team {
  @include ellipsis();
  width: calc(50% - 52px);

  &:first-child {
    text-align: right; } }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.sport__team


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.sport__team


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.sport__team


// LG
//@media screen and (min-width: $min-lg )
//.sport__team
