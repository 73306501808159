.day__teaser__headline {
  font-size: 2rem;
  line-height: 1.2;
  margin-bottom: 9px; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.day__teaser__headline


// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .day__teaser__headline {
    margin-bottom: 13px; } }


// SM
@media screen and (min-width: $min-sm) and (max-width: $max-sm) {
  .day__teaser__headline {
    font-size: 2.2rem; } }


// MD
@media screen and (min-width: $min-md) and (max-width: $max-md) {
  .day__teaser__headline {
    font-size: 2.6rem; } }


// LG
@media screen and (min-width: $min-lg) {
  .day__teaser__headline {
    font-size: 3rem; } }
