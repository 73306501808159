.article__aside--authorbox {
  padding: 17px 11px 11px; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.article__aside--authorbox


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.article__aside--authorbox


//MD
@media screen and (min-width: $min-md) and (max-width: $max-md) {
  .article__aside--authorbox {
    padding: 20px 17px; } }

// LG
@media screen and (min-width: $min-lg) {
  .article__aside--authorbox {
    padding: 19px 19px 16px; } }
