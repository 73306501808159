.sport__link {
	align-self: center;
	background: $dark-grey;
	color: white;
	font-size: 1.1rem;
	font-weight: $semi-bold;
	line-height: 1.5rem;
	margin-left: 10px;
	padding: 0 5px;

	&:before {
		@include triangle(r);
		margin-right: 5px; }

	&:hover {
		color: white; } }

.sport__link--live {
	background: $red; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.sport__link


// XS and SM
@media screen and (max-width: $max-sm) {
	.sport__link {
		display: none; } }

// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.sport__link


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.sport__link


// LG
@media screen and (min-width: $min-lg) {
	.sport__link {
		font-size: 1.3rem;
		line-height: 1.7rem;
		padding-top: 1px;

		&:before {
			font-size: 1.1rem; } } }
