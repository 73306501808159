.sport__score {
  display: flex;
  justify-content: center;
  text-align: center;
  width: 70px;

  strong {
    font-size: 1.5rem; }

  &.sport__link--live {
    background-color: transparent;
    color: $red; } }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.sport__score


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.sport__score


//MD
@media screen and (min-width: $min-md ) and (max-width: $max-md ) {
  .sport__score {
    width: 120px; } }


// LG
@media screen and (min-width: $min-lg ) {
  .sport__score {
    width: 120px;

    strong {
      font-size: 1.7rem; } } }
