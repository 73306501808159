
.box-coop {
  background: $lighter-grey;
  margin-bottom: 20px;
  padding: 20px;

  .slider {
    margin: 0;
    padding: 0;

    .slider__nav {
      .slick--prev,
      .slick--next {
        box-shadow: 0 2px 4px 0 rgb(164, 164, 164); } }

    &.slider--indexed {
      .teaser__media {
        &:before {
          background: none;
          bottom: -13px;
          color: white;
          font-weight: bold;
          font-size: 8rem;
          line-height: 8rem;
          left: unset;
          right: 10px;
          text-align: right;
          text-shadow: 2px 2px 5px $black;
          top: unset;
          width: 100px; } } } }

  .title--brand {
    position: relative;

    .title__brand {
      color: $dark-grey;
      font-size: 1.4rem;
      font-weight: normal;
      line-height: 2.4rem;
      position: absolute;
      right: 0;
      text-transform: none;
      vertical-align: middle;
      display: flex;

      img {
        //vertical-align: text-bottom
        width: 90px;
        margin-left: 10px; } } } }


/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs ) {
  .box-coop {
    margin: 0 -10px 10px;
    padding: 0 10px 10px;

    .title--brand {
      margin-bottom: 35px;

      .title__brand {
        font-size: 1.2rem;
        position: absolute;
        right: 10px;
        top: 40px;

        img {
          width: 75px; } } } } }


// SM and MD
@media screen and (min-width: $min-sm ) and (max-width: $max-md ) {
  .box-coop {
    padding: 16px;

    .slider {
      &.slider--indexed {
        .teaser__media {
          &:before {
            bottom: -10px;
            font-size: 6.5rem;
            line-height: 6.5rem;
            width: 80px; } } } }

    //.title__brand
    ////font-size: 1.2rem

    ////img
 } }    //  //width: 65px

// SM and MD and LG
@media screen and (min-width: $min-sm ) {
  .box-coop {
    .title--brand {
      margin-top: 30px;

      .title__ad {
        margin-top: -70px; } } } }

// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )



//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )



// LG
//@media screen and (min-width: $min-lg )

