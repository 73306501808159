.poll__form {
	label {
		display: flex;
		margin-bottom: 10px;
		width: 100%;

		// only answers, not submit buttons
		input {
			margin-right: 10px; } }

	input {
		transition: opacity 0.15s;

		&:disabled {
			cursor: default;

			&:not([name="results"]) {
				opacity: 0.6; } }

		&[type=submit] {
			margin-right: 10px;
			margin-top: 2px; } } }

/* BREAKPOINTS */
