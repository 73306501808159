.adviser__title {
    font-weight: bold;
    text-transform: uppercase; }

/* BREAKPOINTS */


// SM
@media screen and (max-width: $max-sm ) {
    .adviser__title {
        margin-bottom: 10px; } }

// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//  .adviser__title

// Min MD
@media screen and (min-width: $min-md ) {
    .adviser__title {
        margin-bottom: 15px; } }

// Max MD
@media screen and (max-width: $max-md ) {
    .adviser__title {
        font-size: 1.8rem; } }

// LG
@media screen and (min-width: $min-lg ) {
    .adviser__title {
        font-size: 2rem; } }

