.sidebar-teasers {
	background: $lighter-grey;
	padding: 20px;

	.title {
		background: transparent;
		display: block;
		font-size: 1.8rem;
		margin-bottom: 15px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap; }

	.teaser__kicker,
	.teaser__headline {
		display: block; }

	.teaser {
		margin-bottom: 11px;
		padding: 0;

		.teaser__headline {
			font-size: 1.8rem; } } }

/* BREAKPOINTS */


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//sidebar-teasers


// LG
@media screen and (min-width: $min-lg) {
	.sidebar-teasers {
		.title {
			font-size: 2rem; }

		.teaser {
			.teaser__headline {
				font-size: 2.2rem; } } } }
