// not visible on xs and sm
.header__nav__teasers {
  height: 100%;
  padding-top: 17px;
  width: 100%;

  .row {
    height: 100%; }

  .teaser {
    .teaser__content {
      .teaser__headline {
        font-size: 1.8rem; } } } }

/* BREAKPOINTS */


// XS and SM
@media screen and (max-width: $max-sm) {
  .header__nav__teasers {
    display: none;

    img {
      display: none; } } }

// MD and LG
@media screen and (min-width: $min-md) {
  .header__nav__teasers {
    img {
      min-height: 135px; } } }

// LG
@media screen and (min-width: $min-lg) {
  .header__nav__teasers {
    img {
      min-height: 192px; }

    .teaser {
      .teaser__content {
        .teaser__headline {
          font-size: 2.2rem; } } } } }
