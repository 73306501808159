.impressum {
  h1.title {
    margin-bottom: 20px; } }


/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
  .impressum {
    .article__aside {
      > .title {
        margin-top: -10px; } } } }


// SM
//@media screen and (min-width: $min-sm) and (max-width: $max-sm)
//.impressum


// MD
//@media screen and (min-width: $min-md) and (max-width: $max-md)
//.impressum


// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .impressum {
    h2.title {
      color: $red; } } }


// LG
//@media screen and (min-width: $min-lg)
//.impressum
