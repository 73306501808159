.table__values--wrap {
  display: inline-flex;
  flex-wrap: wrap; }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.table__values--wrap


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.table__values--wrap


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.table__values--wrap


// LG
//@media screen and (min-width: $min-lg )
//.table__values--wrap
