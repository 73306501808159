.article__aside__caption {
  line-height: 1.6rem; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.article__aside__caption



// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.article__aside__caption



//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.article__aside__caption



// LG
@media screen and (min-width: $min-lg) {
  .article__aside__caption {
    line-height: 1.8rem; } }
