.theme__detail__title {
	color: $red;
	font-size: 1.6rem;
	margin-bottom: 7px;
	text-transform: uppercase;

	+ .teaser {
		margin-top: 10px; } }

/* BREAKPOINTS */


// SM, MD and LG
@media screen and (min-width: $min-sm) {
	.theme__detail__title {
		font-size: 1.8rem; } }

// MD and LG
@media screen and (min-width: $min-md) {
	.theme__detail__title {
		font-size: 2rem;

		+ .teaser {
			margin-top: 25px; } } }
