.sport__logo {
  margin: 0 10px; }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.sport__logo


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.sport__logo


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.sport__logo


// LG
//@media screen and (min-width: $min-lg )
//.sport__logo
