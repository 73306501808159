.media--16-9 {
	padding-bottom: 56.25%; }

/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
	.media {
		&.media--16-9-xs {
			padding-bottom: 56.25%; } } }

// SM
@media screen and (min-width: $min-sm) and (max-width: $max-sm) {
	.media {
		&.media--16-9-sm {
			padding-bottom: 56.25%; } } }

// Max SM
@media screen and (max-width: $max-sm ) {
	.media {
		&.media--16-9-max-sm {
			padding-bottom: 56.25%; } } }


// LG
@media screen and (min-width: $min-lg) {
	.media {
		&.media--16-9-lg {
			padding-bottom: 56.25%; } } }
