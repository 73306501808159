.day__headline {
  margin-bottom: 10px;
  font-size: 1.8rem; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.day__headline


// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .day__headline {
    font-size: 2.6rem;
    line-height: 3.4rem; } }

// LG
@media screen and (min-width: $min-lg) {
  .day__headline {
    font-size: 2.8rem; } }
