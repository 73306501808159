.stock__container {
  display: flex;
  margin-top: 12px; }

/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs ) {
  .stock__container {
    padding-bottom: 11px;
    padding-top: 0; } }


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.stock__container


// MD and LG
//@media screen and (min-width: $min-md )
//  .stock__container
