.theme__index {
	display: flex;
	flex-wrap: wrap;
	font-size: 1.5rem;
	justify-content: center;
	margin: 16px auto 19px;

	li {
		background: $darker-grey;
		@include border(b, $color: white);
		@include border(r, $color: white);
		flex-grow: 1;
		list-style: none;

		&:hover,
		&.active {
			background: $red;
			transition: background 0.2s; } }

	a {
		color: white;
		display: block;
		line-height: 30px;
		text-align: center; } }

// For detail page, where theme__index is at the bottom
.theme__nav {
	+ .theme__index {
		margin: 16px 0 9px; } }

/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
	.theme__index {
		max-width: 255px;

		.teaser {
			display: block; }

		a {
			width: 27px; } }

	//.title
	// .theme__index
 }	//margin-top: -10px

// SM, MD and LG
@media screen and (min-width: $min-sm) {
	.theme__index {
		flex-direction: row;
		margin: 10px 0 30px;

		a {
			font-size: 1.7rem;
			line-height: 3.1rem; } }

	// For detail page, where theme__index is at the bottom
	.theme__nav {
		+ .theme__index {
			margin: 16px 0 29px; } } }
