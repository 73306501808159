@keyframes bar {
	0% {
		width: 0; }
	20% {
		width: 0; } }

.poll__bar__bar {
	animation-name: bar;
	animation-duration: $poll-bar-animation-duration;
	background: $red;
	height: 20px;
	min-width: 1px; }
