// The teaser--wide widget (not if hero) is inline on xs and md
.teaser--wide {
  figure {
    width: 100%; } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.teaser--wide


// SM
@media screen and (min-width: $min-sm ) and (max-width: $max-md ) {
  .teaser--wide {
    .teaser__content {
      .teaser__related {
        .teaser__related__element {
          &:before {
            margin-top: 8px; }
          .teaser__headline, .teaser__kicker {
            font-size: 1.5rem; } } } } } }


// MD and LG
//@media screen and (min-width: $min-md )
//.teaser--wide


// LG
@media screen and (min-width: $min-lg ) {
  .teaser--wide {
    .teaser__content {
      .teaser__related {
        .teaser__related__element {
          .teaser__headline, .teaser__kicker {
            font-size: 1.7rem; } } } } } }
