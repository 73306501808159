//.sitemap__col


/* BREAKPOINTS */


// XS and SM
//@media screen and (max-width: $min-sm)


// MD and LG
@media screen and (min-width: $min-md) {
  .sitemap__col {

    &:last-of-type {
      margin-left: 15px; } } }


// LG
//@media screen and (min-width: $min-lg)
//  .sitemap__col
