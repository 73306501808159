.media--17-6 {
	padding-bottom: 35.25%; }

/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
	.media {
		&.media--17-6-xs {
			padding-bottom: 35.25%; } } }

// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//media--17-6


// Min MD
@media screen and (min-width: $min-md ) {
	.media {
		&.media--17-6-min-md {
			padding-bottom: 35.25%; } } }

// LG
//@media screen and (min-width: $min-lg )
//media--17-6
