.article--img {
  width: 100%;

  picture {
    position: relative; } }


.article--img-ctb {

  picture {
    cursor: pointer;

    &:before {
      background: url(../images/icons/icon__zoom.svg) no-repeat;
      bottom: 15px;
      content: '';
      display: block;
      position: absolute;
      right: 15px; } } }


/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs ) {
  .article--img {
    width: auto; } }


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.article--img


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.article--img


// LG
//@media screen and (min-width: $min-lg )
//.article--img
