//.col--x2


/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
  .col--x2 {
    > * {
      &:not(:last-child) {
        @include border(b);
        padding-bottom: 20px !important; } } } }


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.col--x2


// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .col--x2 {
    @include border(b);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 0;

    > * {
      margin-bottom: 20px;
      width: 50%;

      &:nth-child(odd) {
        padding-right: 17px; }

      &:nth-child(even) {
        @include border(l);
        padding-left: 17px; } } } }


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.col--x2


// LG
//@media screen and (min-width: $min-lg )
//.col--x2
