.dropdown {
	color: $black;
	font-size: 1.5rem;
	height: 42px;
	overflow: hidden;
	position: relative;

	&.dropdown--open {
		overflow: visible;

		.dropdown__select {
			border-color: $dark-grey;
			box-shadow: 0px 0px 4px $dark-grey;

			&:after {
				transform: rotate(180deg); } } }

	.dropdown__select {
		align-items: center;
		border: 1px solid $lighter-grey;
		border-radius: 5px;
		cursor: pointer;
		display: flex;
		font-weight: bold;
		height: 100%;
		overflow: hidden;
		padding: 0 15px;
		width: 100%;

		&:after {
			background-image: url("data:image/svg+xml,%3Csvg width='22' height='13' viewBox='0 0 22 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.9999 12.0999L0.899902 1.9999L1.99991 0.899902L10.9999 9.8999L19.9999 0.899902L21.0999 1.9999L10.9999 12.0999Z' fill='%2346555F'/%3E%3C/svg%3E%0A");
			background-position: center;
			background-repeat: no-repeat;
			background-size: 20px;
			content: '';
			height: 100%;
			margin-left: auto;
			width: 20px; }

		&:hover {
			border: 1px solid $dark-grey; }

		&.dropdown__select--placeholder {
			color: $dark-grey;
			font-weight: normal; } }

	ul {
		background: #FFFFFF;
		border: 1px solid $lighter-grey;
		border-radius: 5px;
		left: 0;
		position: absolute;
		width: 100%;
		z-index: 100001;

		li,
		li a {
			align-items: center;
			cursor: pointer;
			display: flex;
			height: 42px;
			width: 100%; }

		li {
			padding: 0 15px;

			&:hover {
				background-color: $lightest-grey; }

			&.dropdown__selected {
				font-weight: bold; } } } }


/* BREAKPOINTS */


// LG
@media screen and (min-width: $min-lg ) {
	.dropdown {
		font-size: 1.7rem; } }
