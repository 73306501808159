.liveticker {
    margin-bottom: 16px;
    width: 100%;

    .title {
        background: $red;
        color: white;
        line-height: inherit;
        padding: 5px 10px; }

    &.liveticker__image {
        .title {
            margin-bottom: 0; }

        .teaser {
            margin-top: 5px; } } }


.liveticker--light {
    border-color: $darker-grey;

    .title {
        background: $darker-grey; } }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs)
//.liveticker


// SM, MD and LG
@media screen and (min-width: $min-sm ) {
  .liveticker {
    margin-bottom: 20px;

    .title {
      margin-bottom: 10px;
      padding: 5px 10px; } } }


// MD
@media screen and (min-width: $min-md) and (max-width: $max-md) {
  .liveticker {} }


// LG
//@media screen and (min-width: $min-lg)
//.liveticker
