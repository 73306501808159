@mixin border( $location:all, $width:1px, $style:solid, $color:$lighter-grey ) {
  @if $location == all {
    border:         $width $style $color; }
  @if $location == t {
    border-top:     $width $style $color; }
  @else if $location == r {
    border-right:   $width $style $color; }
  @else if $location == b {
    border-bottom:  $width $style $color; }
  @else if $location == l {
    border-left:    $width $style $color; } }

@mixin background-image( $image:arrow, $color:$black ) {
  @if $image == arrow {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M18 10 4 20V0z'/%3E%3C/defs%3E%3Cuse fill='#{$color}' xlink:href='%23a' fill-rule='evenodd'/%3E%3C/svg%3E"); }
  @if $image == arrow-light {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpath id='a' d='M16 0 6 10l10 10v-3.158L9.158 10l.526-.526L16 3.158z'/%3E%3C/defs%3E%3Cuse fill='#{$color}' transform='rotate(180 11 10)' xlink:href='%23a' fill-rule='evenodd'/%3E%3C/svg%3E"); }
  @if $image == arrow-stock {
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' style='enable-background:new 0 0 12 12' xml:space='preserve'%3E%3Ccircle cx='6' cy='6' r='6' style='fill:#{$color}'/%3E%3Cpath style='fill:%23fff' d='M2.8 7.9 5 5.2v5.1h2V5.2L9.2 8l1.6-1.2-4-5.1H5.2l-4 5z'/%3E%3C/svg%3E"); } }

@mixin rotate($angle:180deg) {
  -webkit-transform:  rotate($angle);
  -moz-transform:     rotate($angle);
  -ms-transform:      rotate($angle);
  -o-transform:       rotate($angle);
  transform:          rotate($angle); }

@mixin ellipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

/* GEOMETRIC FORMS ICONS */
// See https://en.wikipedia.org/wiki/Geometric_Shapes for more

@mixin triangle($direction:t) {
  @if $direction == t {
    content: "\25B2"; }
  @else if $direction == r {
    content: "\25B6"; }
  @else if $direction == b {
    content: "\25BC"; }
  @else if $direction == l {
    content: "\25C0"; } }

@mixin circle() {
  content: "\25CF"; }
