.vplayer--sticky {
  position: fixed;
  z-index: 99999;
  top: 85px;
  width: 1452px;
  background-color: white;
  //border: 1px solid #2d3c46
  //box-shadow: 0px -5px 0px 0px rgba(255,255,255,1)
  box-shadow: 0 3px 7px -3px rgba(0, 0, 0, 0.7);
  height: 208px;
  padding: 10px;
  margin-left: -18px; }

.vplayer--sticky .row {
  display: block;
  padding: 0;
  margin: 0; }

.vplayer--sticky  *[class^="col-"],
.vplayer--sticky   *[class*=" col-"] {
  display: block;
  padding: 0;
  margin: 0;
  max-width: none; }

.vplayer--sticky.vplayer--article {
  width: 779px;
  margin: 0; }


// XS
@media screen and  (max-width: $max-xs) {
  .vplayer--sticky {
    //width: calc(100% - 20px)
    width: 100%;
    //height: 172px
    top: 40px;
    margin-left: -10px; }
  .vplayer--sticky.vplayer--article {
    width: 100%;
    margin: 0; } }


// SM
@media screen and (min-width: $min-sm) and (max-width: $max-sm) {
  .vplayer--sticky {
    //width: calc(100% - 16px)
    width: 100%;
    top: 48px;
    margin-left: -8px; }
  .vplayer--sticky.vplayer--article {
    width: calc(83.333% - 16px);
    margin: 0; } }

// MD
@media screen and (min-width: $min-md) and (max-width: $max-md) {
  .vplayer--sticky {
    width: 1036px;
    top: 60px;
    margin-left: -16px; }

  .vplayer--sticky.vplayer--article {
    width: 664px;
    margin: 0; } }

// LG
@media screen and (min-width: $min-lg) {
  .vplayer--detail {
    padding: 0 120px; }

  .vplayer--sticky.vplayer--detail {
    padding: 10px; } }

