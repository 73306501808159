.article__more {
  border: none;
  margin-bottom: 0;
  margin-top: 30px;

  .title {
    margin-bottom: 20px; } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.article__more


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.article__more


// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .article__more {
    @include border(t);
    padding-top: 20px; } }

// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.article__more


// LG
//@media screen and (min-width: $min-lg )
//.article__more
