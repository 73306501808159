.feedback__short {
  padding-bottom: 5px;
  width: 100%;

  .feedback__btn {
    border: none;
    cursor: pointer;
    display: flex;
    margin: 0;
    padding: 0; }

  __mail {
    display: block; }

  input {
    @include border($width: 1px);
    font-size: 1.5rem;
    margin-bottom: 4px;
    margin-top: 15px;
    max-width: 100%;
    min-width: 100%;
    padding: 4px 4px 4px 10px;
    width: 100%; }

  textarea {
    margin-top: 5px;
    padding: 4px 4px 4px 10px; }

  .feedback__short__contact {
    align-items: center;
    display: inline-flex;
    cursor: default;

    &:before {
      background: url(../images/icons/icon__arrow-red.svg) no-repeat;
      content: '';
      display: block;
      height: 8px;
      margin-right: 5px;
      transform: rotate(0deg);
      width: 8px; } }


 }  // XS
@media screen and (max-width: $max-xs ) {
  .feedback__short {
    margin-bottom: 5px; } }

