//.table--col4r


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.table--col4r


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.table--col4r


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.table--col4r


// MD and LG
@media screen and (min-width: $min-md) {
  .table--col4r {
    .table__elem {
      > * {
        > * {
          &:not(:first-child) { // span and time elements inside
            width: 30%; }

          &:first-child {
            width: 10%; }

          &:nth-last-child(-n+2) {
            justify-content: flex-end;
            text-align: right; } } } } } }


// LG
//@media screen and (min-width: $min-lg )
//.table--col4r
