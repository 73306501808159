
.lotto {
	padding-bottom: 15px;

	.teaser__ad {
		margin: 0 0 0 auto; }

	.teaser__infos {
		margin: 0; }

	&.lotto--hide {
		display: none; } }

.lotto__lottery--hidden {
	visibility: hidden; }

.lotto__lottery--6aus49 {
	background-color: #F7DC16; }

.lotto__lottery--eurojackpot {
	background-color: #F5C800; }

.lotto__header {
	align-items: center;
	display: flex;
	justify-content: space-around;
	margin: 20px 0; }

.lotto__header img:first-child {
	width: 150px; }

.lotto__header img:last-child {
	width: 40px; }

.lotto__jackpot {
	border-bottom: 1px dashed $black;
	margin: 0 16px 20px 16px;
	text-align: center;

	span {
		display: block;
		font-size: 1.3rem; }

	.lotto__jackpot__value {
  		font-size: 3.5rem;
  		font-weight: bold;
  		margin-bottom: 15px; }

	.lotto__jackpot__value--status {
		font-size: 2.0rem; } }

.lotto__jackpot__button {
	background-color: $red;
	border-radius: 5px;
	color: #FFF;
	display: inline-block;
	font-size: 1.5rem;
	font-weight: bold;
	margin-bottom: 20px;
	padding: 10px; }

.lotto__numbers span,
.lotto__numbers a {
	display: block;
	font-size: 1.3rem;
	margin-bottom: 10px;
	text-align: center; }

.lotto__numbers {
	ul {
		display: flex;
		list-style-type: none;
		margin: 0 auto 15px 0;
		justify-content: center;

		li {
			align-items: center;
			border: 1px solid black;
			border-radius: 50%;
			display: flex;
			font-size: 1.3rem;
			height: 28px;
			justify-content: center;
			margin: 3px;
			text-align: center;
			width: 28px;

			&:last-child {
				font-weight: bold;
				border-width: 2px; } } }

	a {
		color: #46555f;
		margin-bottom: 20px;
		text-decoration: underline; } }

.lotto__lottery--eurojackpot {
	.lotto__numbers {
		ul {
			li:nth-child(6) {
				font-weight: bold;
				border-width: 2px; } } } }

.lotto__footer {
	background-color: #FFF;
	padding: 10px 0;
	text-align: center;
	font-size: 1.1rem;

	span {
		display: block;

		&:last-child {
			margin-top: 10px; } } }

.lotto__slider {
	border: 1px solid $dark-grey; }

.lotto__slider__controls {
	align-items: center;
	display: flex;
	justify-content: center;
	margin-top: 15px; }

.lotto__slider__controls .slick--prev,
.lotto__slider__controls .slick--next {
	background-color: rgba(255, 255, 254, 0.01);
	border-radius: 16px;
	box-shadow: 0 2px 4px 0 rgba(164, 164, 164, 0.5);
	height: 32px;
	width: 32px; }

.lotto__slider__controls .slick--prev:before,
.lotto__slider__controls .slick--next:before {
	background-position: 7px,center;
	background-size: 20px;
	height: 100%;
	width: 100%; }

.lotto__slider__controls {
	.slick--prev {
    	order: 1; }

	.slick-dots {
		order: 2; }

	.slick--next {
		order: 3; } }


/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
	.sidebar .lotto {
		display: none; } }


// SM
//@media screen and (min-width: $min-sm) and (max-width: $max-sm)


// MD
//@media screen and (min-width: $min-md) and (max-width: $max-md)


// LG
@media screen and (min-width: $min-lg) {
	.lotto__jackpot {
		span {
			font-size: 1.4rem; }

		.lotto__jackpot__value {
  			font-size: 3.8rem; }

		.lotto__jackpot__value--status {
			font-size: 2.2rem; } }

	.lotto__jackpot__button {
		font-size: 1.6rem; }

	.lotto__numbers span,
	.lotto__numbers a {
		font-size: 1.4rem; }

	.lotto__numbers {
		ul {
			li {
				font-size: 1.4rem;
				height: 30px; } } }

	.lotto__footer {
		font-size: 1.2rem; } }


// SM und MD und LG
@media screen and (min-width: $min-sm) {
	.content .lotto {
		display: none; } }
