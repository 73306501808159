.teaser__section {
  margin-right: auto; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.teaser__section


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.teaser__section


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.teaser__section


// LG
//@media screen and (min-width: $min-lg )
//.teaser__section
