.header__logo {
  order: 2;

  img {
    height: 40px;
    width: 64px; } } // IE 11

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs)
//.header__logo


// XS and SM
@media screen and (max-width: $max-sm) {
  .header__logo {
    margin-right: auto; } }

// SM
@media screen and (min-width: $min-sm) and (max-width: $max-sm) {
  .header__logo {
    img {
      height: 48px;
      width: 77px; } } }
// IE 11


// MD and LG
@media screen and (min-width: $min-md) {
  .header__logo {
    //background: $red //IE11
    order: 1;
    //padding-right: 1px //IE11

    img {
      height: 60px;
      width: 96px; } } } // IE 11


// LG
@media screen and (min-width: $min-lg) {
  .header__logo {
    img {
      height: 85px;
      width: 136px; } } } // IE 11
