.quiz__next {
  display: flex;
  margin-bottom: 10px;

  .button {
    text-transform: uppercase;
    margin-left: auto; } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.quiz__next


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.quiz__next


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.quiz__next


// LG
//@media screen and (min-width: $min-lg )
//.quiz__next
