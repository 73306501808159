.sidebar__group {
  &:last-child {
    border: none;

    .teaser {
      margin-bottom: 25px; } }

  .slick-initialized {
    margin-bottom: 15px !important;

    .teaser {
      margin-bottom: 6px;
      padding-left: 5px;
      padding-right: 5px; }

    .teaser__content {
      padding: 0 11px; }

    picture {
      margin: 0 -5px; } }

  .teaser__media {
    width: 100%; } }

.sidebar__group__wrapper {
  > * {
    margin: 0;

    &:last-child {
      border-bottom: none; } } }

/* BREAKPOINTS */


// MAX XS
@media screen and (max-width: $max-xs ) {
  .sidebar__group {
    .teaser {
      .teaser__media {
        margin-left: -10px;
        margin-right: -10px;
        width: calc(100% + 20px); }

      &:first-of-type {
        margin-top: -10px; } } } }


// SM
@media screen and (min-width: $min-sm) and (max-width: $max-sm) {
  .sidebar__group {
    .title {
      margin-top: 6px; } } }

// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .sidebar__group {
    @include border(b);
    margin-bottom: 15px;

    .title {
      margin-bottom: 16px; } }

  .sidebar__group--slider {
    overflow: hidden;

    .slick-dots {
      margin-top: 5px; } } }

// MD and LG
//@media screen and (min-width: $min-md )
//.sidebar__group


// LG
//@media screen and (min-width: $min-lg )
//.sidebar__group
//  .teaser
