//.slick-slider

.slick-slide {
  > div {
    width: 100%; }

  img {
    color: transparent; } }

.slick--prev,
.slick--next {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0; }

.slick-disabled {
  cursor: default;
  opacity: 0.3; }

.slick-dots {
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  justify-content: center;
  line-height: 0;
  text-align: center;

  li {
    display: inline-block;
    height: 8px;
    margin: 5px;

    button {
      background: $light-grey;
      border: none;
      border-radius: 50%;
      color: transparent;
      cursor: pointer;
      height: 8px;
      padding: 0;
      width: 8px; }

    &.slick-active {
      button {
        background: $bg-grey;

        @media (inverted-colors: inverted) {
          background: $inv-red; } } }

    &:hover {
      button {
        background: $dark-grey; } } } }

/* BREAKPOINTS */


// SM, MD and LG
//@media screen and (min-width: $min-sm)
//  .slick-dots
//    align-self: center
//    display: flex
//    flex-wrap: wrap

//    li
//      button
//        height: 6px
//        width: 6px
