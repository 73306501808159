.media--22-3 {
	padding-bottom: 13.64%; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//media--22-3


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//media--22-3


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//media--22-3


// LG
//@media screen and (min-width: $min-lg )
//media--22-3
