.newsletter {
	font-size: 1.5rem;

	.title {
		margin-bottom: 12px; }

	a {
		text-decoration: underline; }

	a.xxx {
		color: $red;
		font-weight: $semi-bold; }

	form {
		.title {
			margin-top: 0; }

		.title--small {
			margin-bottom: 13px;
			margin-top: 10px; } }

	input {
		font-size: 1.5rem;
		height: 42px;
		padding: 10px; }

	span {
		display: block; }

	.popup, .popup__unsubscribe {
		.container {
			max-width: 350px;
			top: calc(50% - 81px); }

		input {
			@include border();
			max-width: calc(100% - 110px);
			margin: 10px 0; }

		form {
			align-items: center;
			display: flex;
			flex-wrap: wrap; } } }

.newsletter--hidden {
	display: none; }


/* BREAKPOINTS */


// SM, MD and LG
@media screen and (max-width: $min-sm) {
	.newsletter {
		.title {
			margin-bottom: 14px; }

		form {
			.title--small {
				margin-bottom: 7px; } } } }

// LG
@media screen and (min-width: $min-lg) {
	.newsletter {
		font-size: 1.7rem; } }
