.list {
  //padding-bottom: 0

  ul {
    list-style: none; } }


/* BREAKPOINTS */


// SM
//@media screen and (min-width: $min-sm) and (max-width: $max-sm)
//.list


// MD and LG
//@media screen and (min-width: $min-md)
//.list
