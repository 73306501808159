.header__burger {
  align-items: center;
  display: flex;
  justify-content: center;
  order: 1;
  width: 40px;

  &:before {
    background-image: url(../images/icons/icon__burger.svg);
    height: 17px;
    width: 20px; } }

/* BREAKPOINTS */


// XS and SM
//@media screen and (min-width: $max-sm )
//.header__burger


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.header__burger


// MD and LG
@media screen and (min-width: $min-md) {
  .header__burger {
    display: none; } }

// LG
//@media screen and (min-width: $min-lg )
//.header__burger
