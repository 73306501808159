.weather__next {
	display: flex;
	flex-grow: 1;
	justify-content: space-between; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-sm )
//.weather__next


// SM and MD
//@media screen and (min-width: $min-sm ) and (max-width: $max-md )
//.weather__next


// MD and LG
@media screen and (min-width: $min-md) {
	.weather__next {
		margin-top: 5px; } }

// LG
@media screen and (min-width: $min-lg) {
	.weather__next {
		margin-top: 2px; } }
