.feedback__star {
  cursor: pointer;
  fill: #d8d8d8;
  height: 25px;
  width: 22px;

  &.active,
  &:hover {
    fill: #ffd055;

    ~ .feedback__star {
      polygon {
        fill: #ffd055; } } } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.feedback__star


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.feedback__star


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.feedback__star


// LG
//@media screen and (min-width: $min-lg )
//.feedback__star
