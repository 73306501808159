.gallery__fullsize {
  img {
    cursor: pointer;
    height: auto;
    margin-left: 20px;
    width: 30px; } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.gallery__fullsize


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.gallery__fullsize


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.gallery__fullsize


// LG
//@media screen and (min-width: $min-lg )
//.gallery__fullsize
