
.sparwelt__title {
  font-size: 2.4rem;
  margin-bottom: 16px;
  line-height: 1;
  color: $darker-grey;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  font-weight: $bold;
  text-transform: uppercase; }

.sparwelt__title__anzeige {
  background: $dark-grey;
  color: white;
  padding: 3px 5px;
  font-size: 1.2rem;
  height: 17px; }

.sparwelt__teaser {
  border-bottom: 1px solid $lighter-grey;
  font-size: 1.5rem;
  display: flex;
  padding-bottom: 10px;
  padding-top: 10px;
  height: 100%; }

.sparwelt__image {
  display: flex;
  padding: 10px;
  border:  1px solid $lighter-grey;
  width: 33%;
  height: 100%; }

.sparwelt__image img {
  width: 100%;
  height: 100%; }

.sparwelt__text {
  display: flex;
  width: 67%;
  padding-left: 20px;
  align-items: center; }

.sparwelt__text span {
  padding-left: 18px;
  background: url(../images/icons/icon__arrow-red.svg) no-repeat;
  background-size: 10px 10px;
  background-position: 0  4px; }

.sparwelt__angebot {
  display: flex;
  color: $dark-grey;
  font-size: 1.1rem;
  margin-top: 10px;
  justify-content: flex-end;
  align-items: center; }

.sparwelt__angebot a {
  display: flex;
  justify-content: flex-end;
  align-items: center; }

.sparwelt__angebot img {
  width: 70px;
  margin: 0 0 16px 10px; }



/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs ) {
  .sparwelt__teaser {
    font-size: 1.5rem; }

  .sparwelt__title {
    background: $light-grey;
    color: $darker-grey;
    align-items: center;
    justify-content: space-between;
    font-size: 1.6rem;
    margin: 0 -10px 10px;
    padding: 0 10px;
    line-height: 3.6rem; }

  .sparwelt__title__anzeige {
    height: auto;
    font-size: 1.1rem;
    line-height: normal; } }


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )



// LG
@media screen and (min-width: $min-lg ) {
  .sparwelt__teaser {
    font-size: 1.7rem; }

  .sparwelt__angebot {
    font-size: 1.3rem; } }



.sparwelt-neu {
  width: 100%;
  border-top: 1px solid #e1e6eb;
  padding: 15px 0 20px; }

.sparwelt-neu .sparwelt__wrapper__wrapper {
  overflow: hidden; }

.sparwelt-neu .sparwelt__wrapper {
  display: flex;
  flex-direction: row;
  position: relative; }

.sparwelt-neu .button {
  width: 100%; }

//.sparwelt-neu .button--icon
//  &:before
//    @include background-image(arrow-light,$dark-grey-ec)

.sparwelt-neu .sparwelt__row {
  display: block;
  margin: 10px 0 10px 5px;
  flex-direction: column;
  width: 100%; }

.sparwelt-neu .sparwelt__angebot {
  display: flex;
  color: #697882;
  font-size: 1.2rem;
  margin-top: 0;
  justify-content: flex-end;
  align-items: center; }

.sparwelt-neu .sparwelt__angebot a {
  display: flex;
  justify-content: flex-end;
  align-items: center; }

.sparwelt-neu .sparwelt__angebot img {
  width: 110px;
  margin: 0 10px 0 10px; }

.sparwelt-neu .sparwelt__title {
  font-size: 2.4rem;
  margin-bottom: 16px;
  line-height: 1;
  color: #46555f;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  font-weight: bold;
  text-transform: uppercase; }

.sparwelt-neu .sparwelt__teaser-neu {
  width: 280px;
  height: auto;
  display: flex;
  margin-bottom: 5px;
  background-color: white;
  overflow: hidden;
  box-shadow: -2px 3px 5px 0px rgba(125,140,150,0.75);
  border-radius: 0px 0px 20px 0px; }

.sparwelt-neu .teaser__kicker {
  font-size: 1.4rem;
  line-height: 1.2; }

.sparwelt-neu .sparwelt__img {
  clip-path: polygon(16% 0, 100% 0, 100% 100%, 0% 100%);
  display: block;
  width: 60%;
  background-position: 100% 0;
  background-repeat: no-repeat;
  background-size: cover; }

.sparwelt-neu .sparwelt__img::after {
  content: "";
  display: block;
  width: 100%;
  padding-bottom: 50%; }

.sparwelt-neu .sparwelt__info {
  align-items: flex-start;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  width: 40%;
  padding-bottom: 0; }

.sparwelt-neu .sparwelt__info * {
  margin: 0 5%; }


/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs ) {
  .sparwelt-neu {
    border-top: 1px solid #e1e6eb;
    padding-top: 15px;
    min-height: 521px; }

  .sparwelt-neu .teaser__kicker {
    font-size: 1.6rem;
    line-height: 1.3; }

  .sparwelt-neu .sparwelt__title {
    background: #aab9c3;
    color: #46555f;
    align-items: center;
    justify-content: space-between;
    font-size: 1.6rem;
    margin: 0 -10px 10px;
    padding: 0 10px;
    line-height: 3.6rem; } }


// LG
@media screen and (min-width: $min-lg ) {
  .sparwelt-neu .sparwelt__angebot img {
    width: 130px; }

  .sparwelt-neu .teaser__kicker {
    font-size: 1.6rem;
    line-height: 1.3; }

  .sparwelt-neu .sparwelt__angebot {
    font-size: 1.4rem; } }

