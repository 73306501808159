.table--col1-3 {
  td {
    text-align: right;
    width: 20%;

    // 2 lasts
    &:first-child {
      @include ellipsis();
      text-align: left;
      width: 40%; } } }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.table--col1-3


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.table--col1-3


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.table--col1-3


// LG
//@media screen and (min-width: $min-lg )
//.table--col1-3
