// visible only on MD and LG
.header__nav__footer {
  align-self: flex-end;
  @include border(t);
  font-size: 1.5rem;
  margin-bottom: 0;
  padding: 16px;
  width: 100%;

  .element {
    & > * {
      margin-right: 5px; } }

  span {
    color: $red; }

  a {
    @include border(r);
    color: $black;
    padding-right: 5px;

    &:last-child {
      border: none; } } }

/* BREAKPOINTS */


// XS and SM
@media screen and (max-width: $max-sm) {
  .header__nav__footer {
    display: none; } }

// LG
@media screen and (min-width: $min-lg) {
  .header__nav__footer {
    font-size: 1.7rem; } }
