.author__infos {
	color: $dark-grey;
	font-size: 1.5rem;
	line-height: 1.2;
	margin-top: -3px; }


/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
	.author__infos {
		width: 100%; } }

// LG
@media screen and (min-width: $min-lg) {
	.author__infos {
		font-size: 1.7rem; } }
