.promo {

  .teaser {
    @include border(b);
    margin-bottom: 20px;
    padding-bottom: 15px;

    .teaser__text {
      display : block; }

    &:first-of-type {
      background: $lightest-grey;
      border: none;
      padding: 15px; } }

  .teaser__headline {
    font-size: 2rem; } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.promo


// SM
// media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.promo


// SM, MD and LG
@media screen and (min-width: $min-sm ) {
  .promo {
    .teaser {
      margin-bottom: 30px;
      padding-bottom: 30px;

      &:first-of-type {
        padding-right: 22px; } }

    .teaser__headline {
      font-size: 2.4rem; } } }


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.promo


// LG
//@media screen and (min-width: $min-lg )
//.promo
