.slider__slides--image {
  @include border();
  padding-bottom: 10px; }


.slider__slides--image .article__media {
  margin-bottom: 0; }


.slider__slides--image .slick--prev,
.slider__slides--image .slick--next {
  position: absolute;
  top: calc(50% - 40px);
  transition: all 0.2s;
  z-index: 5000; }


.slider__slides--image .slick--prev,
.slider__slides--image .slick--next {
  &:before {
    margin: 0 10px; } }


.slider__slides--image .slick--prev {
  left: 0; }


.slider__slides--image .slick--next {
  right: 0; }


.slider__slides--image .article--img-ctb picture {
  &:before {
    content: none; } }



/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs ) {
  .slider__slides--image .slick--prev {}
  .slider__slides--image .slick--next {
    &:before {
      height: 30px;
      width: 30px; } } }


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )



//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )



// LG
//@media screen and (min-width: $min-lg )

