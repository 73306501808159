.day {
  padding-bottom: 0;

  .title {
    margin-bottom: 16px; }

  .article__share {
    margin-bottom: 4px;
    margin-top: 20px;
    position: relative;
    flex-direction: row;

    a {
      margin: 0 3px!important; } }

  .article__aside__caption {
    color: $dark-grey;
    font-size: 1.5rem;
    line-height: 1.2; }

  .article__aside__copy {
    color: $light-grey;
    font-size: 1.3rem; } }

/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
  .day {
    .article__share {
      a:last-child {
        margin-left: 15px; } } } }

// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .day {
    .title {
      font-size: 2.2rem; }

    .article__share {

      img[src*="top"] {
        margin-top: 10px; } }

    .article__aside__caption {
      font-size: 1.6rem; } } }

// LG
@media screen and (min-width: $min-lg) {
  .day {
    .title {
      font-size: 2.4rem; } } }


