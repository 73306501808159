.day__teaser__more {
  // color: $red
  display: block;
  font-size: 1.7rem;
  font-weight: $semi-bold;
  justify-content: flex-end;
  margin-top: 10px;
  text-transform: uppercase;

  &:before {
    background: url(../images/icons/icon__arrow-red.svg) no-repeat;
    content: '';
    display: inline-block;
    height: 12px;
    margin-right: 2px;
    width: 12px; }

  @media (inverted-colors: inverted) {
    color: $inv-red; }

  &:not(:last-of-type) {
    margin-bottom: 17px; } }

.day {
  &.day-red {
    .day__teaser__more {
      color: $red;
      &:before {
        background: url(../images/icons/icon__arrow-red.svg) no-repeat;

        @media (inverted-colors: inverted) {
          color: $inv-red; } } } }

  &.day-green {
    .day__teaser__more {
      color: $green;
      &:before {
        background: url(../images/icons/icon__arrow-green.svg) no-repeat; }

      @media (inverted-colors: inverted) {
        color: $inv-green; } } }

  &.day-blue {
    .day__teaser__more {
      color: $blue;
      &:before {
        background: url(../images/icons/icon__arrow-blue.svg) no-repeat;

        @media (inverted-colors: inverted) {
          color: $inv-blue; } } } } }
