.feedback__backdrop {
  background: rgba(0, 0, 0, 0.7);
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999998; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.feedback__backdrop


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.feedback__backdrop


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.feedback__backdrop


// LG
//@media screen and (min-width: $min-lg )
//.feedback__backdrop
