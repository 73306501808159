.day__header {
    background: white;
    display: flex;
    margin-bottom: 20px;
    position: relative;
    z-index: $dayheader;

    &::after {
      content: '';
      border-bottom: 3px solid;
      position: absolute;
      left: 0;
      width: 100%; }

    .article__kicker {
        color: $black;
        font-size: 1.4rem;
        font-weight: 400; }

    .article__headline {
        color: $red;
        font-size: 3rem;
        text-transform: uppercase;
        font-weight: $semi-bold; }

    .day__header__title {
        display: flex;
        flex-direction: column; }

    a {
        cursor: pointer; }

    .day__author {
        align-items: center;
        display: flex;
        font-size: 1.3rem;
        font-weight: $normal;
        justify-content: flex-end;
        position: relative;
        z-index: 1; }

    .day__author__border {
          background: white;
          border: 2px solid fuchsia;
          border-radius: 50%;
          height: 74px;
          width: 74px;

          img {
            border-radius: 50%;
            height: 70px;
            padding: 1px; } } }

/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs ) {
  .day__header {
      flex-direction: column;
      min-height: 80px;

      &::after {
        bottom: 5px; }

      .day__author, .day__author__name {
         justify-content: flex-start;
         margin-top: 5px;
         order: 1; }

      .day__author__border {
         height: 44px;
         margin-right: 10px;
         width: 44px;

         img {
           height: 40px; } }

      .article__kicker {
         font-size: 1.4rem; }

      .article__headline {
         font-size: 2.0rem; }

      .day__author {
         font-size: 1.3rem; } } }


// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .day__header {
    min-height: 70px;

    &::after {
      bottom: 5px; }

    a {
      margin-left: 10px; }

    .day__author {
      top: 5px;
      span {
        margin-left: 10px; } } }

  .day__header__title {
    flex-grow: 1; } }


// LG
@media screen and (min-width: $min-lg) {
  .day__header {
    min-height: 70px;

    &:after {
      bottom: 0; }

    .article__kicker {
      font-size: 1.6rem; }

    .article__headline {
      font-size: 3.2rem; }

    .day__author {
      font-size: 1.5rem;
      top: 10px; } } }
