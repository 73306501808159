.header__nav__search {
  > a {
    &:before {
      background: url(../images/icons/icon__search.svg) no-repeat;
      background-position: center;
      background-size: contain;
      content: "";
      display: block;
      height: 20px;
      width: 20px; } }

  &.header__nav__search--open {
    background-color: #FFF;

    > a {
      &:before {
        background-image: url(../images/icons/icon__close_darker-grey.svg); } }

    .header-search {
      display: flex; } }

  .header-search {
    position: absolute;
    top: 40px;
    width: 100%;
    color: $black;
    background-color: #FFF;
    left: 0;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    flex-direction: column;
    align-items: center;
    padding: 16px 10px;
    z-index: 80000;
    display: none; } }



/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.header__nav__search


// SM and MD and LG
@media screen and (min-width: $min-sm) {
  .header__nav__search {
    align-self: flex-end;
    font-size: 1.5rem;
    padding: 0 10px;

    .header-search {
      top: 60px;
      width: 350px;
      right: 0;
      left: auto; } } }


// SM
@media screen and (min-width: $min-sm ) and (max-width: $max-sm ) {
  .header__nav__search {
    .header-search {
      top: 48px; } } }


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.header__nav__search


// LG
@media screen and (min-width: $min-lg) {
  .header__nav__search {
    .header-search {
      top: 85px; } } }


