.article__share--fixed {
  top: 50%; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//  .article__share--fixed


// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .article__share--fixed {
    position: fixed; } }

// SM
@media screen and (min-width: $min-sm) and (max-width: $max-sm) {
  .article__share--fixed {
    left: 0;
    margin-left: 8px; } }

// MD
@media screen and (min-width: $min-md) and (max-width: $max-md) {
  .article__share--fixed {
    margin-left: -85px; } }

// LG
@media screen and (min-width: $min-lg) {
  .article__share--fixed {
    margin-left: -79px; } }
