.vplayer__text {
  position: relative; }

.vplayer--sticky .vplayer__text {
  margin: 0 20px 0 370px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%; }

.vplayer--sticky .vplayer__text .article__share,
.vplayer--sticky .vplayer__text .article__date,
.vplayer--sticky .vplayer__text .article__text,
.vplayer--sticky .vplayer__text .teaser__related {
  display: none; }


// XS
@media screen and (max-width: $max-xs) {
  .vplayer--sticky .vplayer__text {
    display: none; } }



