.article__aside--ad {
  position: relative;
  margin-top: 20px;

  .article__aside--ad__label {
    background: $dark-grey;
    color: white;
    font-size: 1.1rem;
    margin-right: 5px;
    padding: 3px 5px;
    display: block;
    position: absolute;
    right: 0;
    top: -20px;
    margin-right: 0;
    text-transform: uppercase; } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.article__aside--ad


// SM, MD, LG
//@media screen and (min-width: $min-sm )
//.article__aside--ad


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.article__aside--ad


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.article__aside--ad


// LG
//@media screen and (min-width: $min-lg )
//.article__aside--ad

