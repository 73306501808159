#footer_tc_privacy {
	display: none!important; }

.dtn-banner {
	background: rgba(45, 60, 70, 0.9);
	width: 100%;
	position: fixed;
	z-index: 999998;
	bottom: 0px;
	left: 0px; }

.dtn-banner__txt {
	color: rgb(255, 255, 255);
	padding: 10px;
	font-size: 12px;
	text-align: left;
	display: inline-block;

	a {
		text-decoration: underline;
		color: #FFFFFF;
		font-weight: bold; } }

.dtn-banner__button {
	padding: 0 10px;
	background: none;
	display: inline-block;

	button {
		border: 0;
		background: #dc0028;
		color: rgb(255, 255, 255);
		display: block;
		cursor: pointer;
		font-size: 12px;
		font-weight: bold;
		padding: 5px 20px;
		margin: 10px 0px; } }
