.teaser-cert-filter {
    ul {
        display: flex;
        flex-wrap: nowrap;
        height: 64px;
        overflow-x: scroll;
        overflow-y: hidden;
        position: relative;
        width: 100%;

        &::-webkit-scrollbar {
            display: none; }

        li {
            list-style-type: none;
            padding: 0 5px;

            a {
                font-weight: bold;
                text-transform: uppercase;

                &.active {
                    border-bottom: 2px solid $red;
                    color: $red;
                    padding-bottom: 2px; } } } }


    .nav {
        .list {
            li {
                display: none; } }

        .active + .list {
            left: 0;
            position: absolute;
            top: 30px;
            z-index: 5; }

        .active + .list li {
            display: block; } } }




/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs ) {
  .teaser-cert-filter {
    margin-top: 14px;

    ul {
      height: 80px; } } }


// SM
@media screen and (min-width: $min-sm ) and (max-width: $max-sm ) {
  .teaser-cert-filter {
    ul {
      height: 80px; } } }


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//  .teaser-cert-filter


// LG
//@media screen and (min-width: $min-lg )
// .teaser-cert-filter
