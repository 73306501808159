//.box


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.box


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.box


// SM, MD and LG
@media screen and (min-width: $min-sm ) {
  .box {
    @include border(b);
    margin-bottom: 20px;

    > section {
      &:last-of-type {
        border: none;
        margin-bottom: 0; } } } }


// MD and LG
//@media screen and (min-width: $min-md )
//.box


// MD
@media screen and (min-width: $min-md ) and (max-width: $max-md ) {
  .box {} }
//    .content
//      .teaser--inline
//        .teaser__text
//          display: none


@media screen and (min-width: $min-lg ) {
  .box {
    margin-bottom: 20px; } }
