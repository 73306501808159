.poll__element {
	@include border(b);
	margin-bottom: 20px;
	padding-bottom: 20px; }

/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
	.poll__element {
		figure {
			margin-bottom: 13px; } } }

// XS and SM
@media screen and (max-width: $max-sm) {
	.col-lg-6 {
		&:last-child {
			.poll__element {
				border: none;
				padding-bottom: 0; } } } }

// SM and MD
@media screen and (min-width: $min-sm) and (max-width: $max-md) {
	.poll__element {
		margin-bottom: 30px;

		&:last-child {
			border: none;
			margin: 0;
			padding: 0; } } }

// SM, MD and LG
@media screen and (min-width: $min-sm) {
	.poll__element {
		margin-bottom: 30px;
		padding-bottom: 30px; } }

// LG
@media screen and (min-width: $min-lg) {
	.poll__element {
		width: calc(50% - #{$gutter-lg});

		&:nth-child(even) {
			margin-left: $gutter-lg; }

		&:nth-child(odd) {
			margin-right: $gutter-lg; } } }
