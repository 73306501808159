.table--col2-1 {
  td {
    &:last-child {
      width: 20%;
      text-align: right; }

    // 2 first
    &:nth-child(-n+2) {
      @include ellipsis();
      width: 40%; } } }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.table--col2-1


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.table--col2-1


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.table--col2-1


// LG
//@media screen and (min-width: $min-lg )
//.table--col2-1
