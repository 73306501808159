.weather__location {
	color: $dark-grey;
	font-size: 1.5rem;
	font-weight: $semi-bold;
	text-transform: uppercase;
	word-wrap: break-word; }

/* BREAKPOINTS */


// SM, MD and LG
@media screen and (min-width: $min-sm) {
	.weather__location {
		align-self: flex-end;
		@include ellipsis();
		font-size: 1.8rem;
		text-transform: uppercase; } }

// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.weather__location


// MD and LG
//@media screen and (min-width: $min-md )
//.weather__location


// LG
@media screen and (min-width: $min-lg) {
	.weather__location {
		font-size: 2rem;
		line-height: 1.15; } }
