.search {
	.teaser__content {
		justify-content: flex-start; }

	.paging {
		@include border(b);
		padding: 0 0 20px; }

	.form {
		margin-bottom: 18px; } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.search


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.search


// SM, MD and LG
@media screen and (min-width: $min-sm) {
	.search {
		.teaser__content {
			.teaser__text {
				display: block; } }

		.paging {
			border: none;
			margin-bottom: 30px;
			padding: 0; }

		.cat {
			margin-top: 4px; }

		.form {
			margin-bottom: 30px; } } }

// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.search


// LG
//@media screen and (min-width: $min-lg )
//.search
