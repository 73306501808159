//.list__wrapper


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.list__wrapper


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.list__wrapper


// SM, MD and LG
@media screen and (min-width: $min-sm ) {
  .list__wrapper {
    columns: 2; } }


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.list__wrapper


// LG
//@media screen and (min-width: $min-lg )
//.list__wrapper
