//.poll__wrapper


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.poll__wrapper


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.poll__wrapper


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.poll__wrapper


// LG
@media screen and (min-width: $min-lg) {
	.poll__wrapper {
		display: flex;
		flex-wrap: wrap;
 } }		//padding-bottom: 20px
