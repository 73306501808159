.weather-brand {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  font-size: 1.5rem;
  background-color: $lightest-grey;
  padding: 15px;
  color: $darker-grey;
  line-height: 1.2;
  margin-bottom: 20px;

  .weather-brand__txt {
    display: flex;
    align-items: center;

    img {
      margin-left: 10px;
      display: block; } }

  .weather-brand__button {
    margin-left: auto; } }


/* BREAKPOINTS */


// XS, SM
@media screen and (max-width: $max-sm) {
  .weather-brand {
    flex-direction: column;

    .weather-brand__txt {
      img {
        width: 100px; } }

    .weather-brand__button {
      text-align: center;
      margin: 0;
      padding-top: 20px; } } }


// XS, SM, MD
@media screen and (max-width: $max-md) {
  .weather-brand__txt span {
    width: 210px; } }


LG {}
@media screen and (min-width: $min-lg) {
  .weather-brand {
    font-size: 1.7rem;
    padding: 20px 15px; } }
