/*
 * Container style
 */
.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto; }

.ps__rail-x,
.ps__rail-y {
  display: none;
  position: absolute; }

.ps__rail-y {
  right: 0;
  width: 3px; }

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block; }

.ps__thumb-x {
  bottom: 0;
  position: absolute; }

.ps__thumb-y {
  background-color: black;
  position: absolute;
  width: 100%; }


/* BREAKPOINTS */


// XS, SM
@media screen and (max-width: $max-sm) {
  .scrollbar {
    max-height: calc(100vh - 40px);
    overflow: hidden;
    position: relative; } }


/* MS supports */
//@supports (-ms-overflow-style: none)
//.ps
//overflow: auto !important



//@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
//.ps
//overflow: auto !important


