.tv__content {
  width: 100%; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.tv__content


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.tv__content


// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .tv__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; } }

//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.tv__content


// LG
//@media screen and (min-width: $min-lg )
//.tv__content
