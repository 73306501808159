.table__values {
  > * {
    display: inline-block; } }


/* BREAKPOINTS */


// XS and SM
@media screen and (max-width: $max-sm ) {
  .table--resp {
    .table__values {
      width: 75%;

      > * {
        @include ellipsis();

        &:nth-child(odd) {
          background: $tables-grey; } } } } }


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.table__values



//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.table__values


// LG
//@media screen and (min-width: $min-lg )
//.table__values
