.extcontent__loading {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 30px;
  background-image: url(../images/icons/icon__loading.svg); }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )


// SM
//@media screen and (min-width: $min-sm) and (max-width: $max-sm)


//SM && MD
//@media screen and (min-width: $min-sm) and (max-width: $max-md)


// LG
//@media screen and (min-width: $min-lg)

