.adviser__info {
    font-size: 1.4rem;

    a.adviser__info__item {
        align-items: center;
        line-height: 1.8rem; }

    div.adviser__info__item {
        border-bottom: 1px solid $light-grey;
        margin-bottom: 15px;
        padding-bottom: 15px; } }


/* BREAKPOINTS */

// SM
@media screen and (max-width: $max-sm ) {
    .adviser__info {
        padding: 10px;
        div.adviser__info__item {
            align-items: center;
            margin-bottom: 10px;
            padding-bottom: 10px; } } }

// Min & Max MD
@media screen and (min-width: $min-md ) and (max-width: $max-md ) {
    .adviser__info {
        padding: 16px; } }

// MD
@media screen and (min-width: $min-md ) {
    .adviser__info {
        border-left: 1px solid #ffffff;

        div.adviser__info__item {
            align-items: flex-end;
            margin-bottom: 15px;
            padding-bottom: 15px; } } }

// LG
@media screen and (min-width: $min-lg ) {
    .adviser__info {
        padding: 20px; } }


