
.newcontent-note {
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.7);
  position: fixed;
  bottom: 0;
  margin-left: -16px;
  width: 340px;
  height: auto;
  background: #ffffff;
  z-index: 100000;
  padding: 15px;
  display: none;

  .newcontent-note__close {
    background-image: url(../images/icons/icon__close_darker-grey.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer; }

  .newcontent-note__title {
    display: block;
    font-weight: bold;
    font-size: 1.6rem;
    color: #0a1924;
    margin: 0 20px 20px 0; }

  .newcontent-note__txt {
    display: block;
    color: $black;
    font-size: 1.5rem;
    margin-bottom: 20px; }

  .teaser {
    margin: 0;

    .teaser__kicker {
      font-size: 1.4rem; }

    .teaser__headline {
      font-size: 1.6rem; }

    .teaser__media {
      width: 100%; } }

  .newcontent-note__buttons {
    display: flex;
    margin: 30px 0 15px 0;

    a {
      border-radius: 5px;
      color: #ffffff;
      text-decoration: none;
      background-color: $darker-grey;
      padding: 10px 0;
      text-align: center;
      font-size: 1.5rem;
      font-weight: bold;
      width: 46%;

      &:last-child {
        margin-left: auto;
        background: none;
        color: #697882;
        border: solid 2px $dark-grey; } } }

  .newcontent-note__disable {
    font-size: 1.3rem;
    text-decoration: underline;
    color: $dark-grey;
    cursor: pointer; } }


/* BREAKPOINTS */


// XS and SM
@media screen and (max-width: $max-sm) {
  .newcontent-note {
    display: none!important; } }



// LG
@media screen and (min-width: $min-lg) {
  .newcontent-note {
    width: 477px;
    margin-left: -20px;
    padding: 20px;

    .newcontent-note__title {
      font-size: 1.8rem; }

    .newcontent-note__txt {
      font-size: 1.7rem; }

    .teaser {
      .teaser__kicker {
        font-size: 1.6rem; }

      .teaser__headline {
        font-size: 1.8rem; } }

    .newcontent-note__buttons a {
      font-size: 1.7rem; }

    .newcontent-note__disable {
      font-size: 1.5rem; } } }

