.teaser--inline {
  .teaser__media {
    margin-bottom: 0; } }

/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
  .teaser--inline,
  .teaser--inline-xs {
    margin-bottom: 15px;

    .teaser__media {
      margin-bottom: 0;
      padding-right: $gutter-xs;
      width: 33.333%; }

    .teaser__content {
      padding-left: $gutter-xs;
      width: 66.666%; }

    .teaser__text {
      display: none; }

    .media {
      &:before {
        height: 30px;
        width: 30px; } } } }

// SM
@media screen and (min-width: $min-sm) and (max-width: $max-sm) {
  .teaser--inline,
  .teaser--inline-sm {
    margin-bottom: 18px;

    .teaser__media {
      padding-right: $gutter-sm;
      margin-bottom: 0;
      width: 33.333%; }

    .teaser__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: $gutter-sm;
      width: 66.666%; }

    .teaser__text {
      display: block;
      font-size: 1.4rem;
      line-height: 1.8rem;
      margin-top: 5px; } } }

/* hide teaser__text and teaser__related in SM for < 1100px */
@media screen and (min-width: $min-sm) and (max-width: 1100px) {
  .teaser--inline,
  .teaser--inline-sm {

    .teaser__text {
      display: none; }

    .teaser__related {
      display: none; } } }



// MD
@media screen and (min-width: $min-md) and (max-width: $max-md) {
  .teaser--inline,
  .teaser--inline-md {
    margin-bottom: 20px;

    .teaser__media {
      margin-bottom: 0;
      padding-right: $gutter-md;
      width: 33.333%; }

    .teaser__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: auto;
      padding-left: $gutter-md;
      width: 66.666%; }

    .teaser__text {
      display: block;
      font-size: 1.4rem;
      line-height: 1.8rem;
      margin-top: 5px; } } }


// LG
@media screen and (min-width: $min-lg) {
  .teaser--inline,
  .teaser--inline-lg {
    margin-bottom: 30px;

    .teaser__media {
      padding-right: $gutter-lg;
      width: 33.333%; }

    .teaser__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: $gutter-lg;
      width: 66.666%; } } }
