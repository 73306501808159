.gallery__header {
  background: $black;
  display: flex;
  flex-shrink: 0;
  justify-content: flex-end;
  padding: 15px 10px;
  position: relative;
  transition: margin-top $gallery-transitions;
  width: 100%;

  img {
    height: 25px;
    max-width: 25px;
    min-width: 25px; } }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.gallery__header


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.gallery__header


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.gallery__header


// LG
@media screen and (min-width: $min-lg) {
  .gallery__header {
    padding: 15px 20px;

    img {
      height: 31px;
      max-width: 31px;
      min-width: 31px; } } }
