.popup__close, .popup__close__unsubscribe {
  background: transparent;
  border: none;
  cursor: pointer;
  height: 25px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 25px;
  z-index: $popup__close;

  &:before {
    content: '\00D7';
    display: block;
    font-size: 30px;
    margin-top: -5px; } }
