.feedback {
  .feedback__btn {
    align-items: center;
    color: $red;
    display: inline-flex;

    &:before {
      background: url(../images/icons/icon__arrow-red.svg) no-repeat;
      transform: rotate(0deg); }

    .feedback__star {
      transform: scale(0.8, 0.8);
      width: 19px; }

    .feedback__stars {
      margin-bottom: 0; } } }

/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
  .feedback__btn {
    @include border(t);
    margin-top: 3px;
    padding-top: 2px; } }

// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.feedback__btn


// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .feedback__btn {
    border-top: none;
    @include border(l, $width: 2px, $color: #778892);
    margin-left: 5px;
    padding-left: 10px; } }

// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.feedback__btn


// LG
//@media screen and (min-width: $min-lg )
//.feedback__btn
