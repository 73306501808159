.quiz__question {
    font-weight: bold; }

/* BREAKPOINTS */


// Max MD
@media screen and (max-width: $max-md ) {
  .quiz__question {
    font-size: 1.8rem; } }


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.quiz__question


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.quiz__question


// LG
@media screen and (min-width: $min-lg ) {
  .quiz__question {
    font-size: 2rem; } }
