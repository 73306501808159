.gallery--full {
  .gallery__footer {
    margin-bottom: -47px; }

  .slick--prev {
    margin-left: -40px;
    opacity: 0; }

  .slick--next {
    margin-right: -40px;
    opacity: 0; } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.gallery--full


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.gallery--full

//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.gallery--full


// LG
//@media screen and (min-width: $min-lg )
//.gallery--full
