.article__aside--quizz {
  padding: 10px 10px 13px; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.article__aside--quizz


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.article__aside--quizz


// MD and LG
@media screen and (min-width: $min-md) {
  .article__aside--quizz {
    padding: 16px; } }

// LG
@media screen and (min-width: $min-lg) {
  .article__aside--quizz {
    padding: 20px; } }
