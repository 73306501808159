.table {
  table-layout: fixed; }

*[class^="table--col1"],
*[class*=" table--col1"],
.table--col2-1 {
  tbody {
    td {
      &:first-child {
        font-weight: $semi-bold; } } } }

/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
  .table {
    display: flex;
    flex-wrap: wrap; } }

// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.table


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.table


// LG
//@media screen and (min-width: $min-lg )
//.table
