.day__teaser__related {}


/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs ) {
  .day__teaser__related {
    .teaser {
      @include border(b);
      margin-bottom: 10px;
      padding-bottom: 10px; }

    .teaser--inline {
      .teaser__content {
        width: 100%;
        padding: 0; } }

    .teaser--inline {
      .teaser__media {
        display: none; } } } }


// SM, MD and LG
//@media screen and (min-width: $min-sm )



// LG
//@media screen and (min-width: $min-lg)
