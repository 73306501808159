.article__aside {
	.extcontent--audionow {
		iframe {
			display: block; }

		.extcontent--audionow__abo {
			background: #000000;
			border-top: 1px solid #333437;
			padding: 8px 24px;

			a {
				display: inline-block;
				margin-right: 8px;

				svg:hover {
					fill: $lighter-grey; } } } } }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )


// SM
//@media screen and (min-width: $min-sm) and (max-width: $max-sm)


//MD
//@media screen and (min-width: $min-md) and (max-width: $max-md)


// LG
//@media screen and (min-width: $min-lg)


