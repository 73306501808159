.article__share--bottom {
  bottom: 0; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//  .article__share--bottom


// SM, MD and LG
//@media screen and (min-width: $min-sm )
//.article__share--bottom
