.teaser--newsletter {
    background-color: $lighter-grey;
    flex-direction: column;
    padding: 16px;

    span {
        color: $darker-grey;
        font-size: 2.2rem;
        font-weight: bold;
        margin-bottom: 10px;
        text-transform: uppercase; }

    p {
        color: $darker-grey;
        font-size: 1.5rem;
        margin-bottom: 10px; }

    form {
        align-items: center;
        display: flex;
        input {
            margin-right: 10px; } } }


/* BREAKPOINTS */


// XS and SM
//@media screen and (max-width: $max-sm)
//  .teaser--newsletter

// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//  .teaser--newsletter


//MD
//@media screen and (min-width: $min-md) and (max-width: $max-md)
//  .teaser--newsletter

// LG
//@media screen and (min-width: $min-lg)
//  .teaser--newsletter
//      p
//          font-size: 1.7rem

