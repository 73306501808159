.list__time {
    color: $dark-grey;
    font-size: 1.3rem;
    margin-right: 5px; }

/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs ) {
  .list__time {
    padding-top: 1px; } }


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.list__time


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.list__time


// LG
@media screen and (min-width: $min-lg ) {
  .list__time {
    font-size: 1.5rem; } }
