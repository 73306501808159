@keyframes bar__text {
	0% {
		opacity: 0; }
	85% {
		opacity: 0; }
	100% {
		opacity: 1; } }

.poll__bar__text {
	animation-name: bar__text;
	animation-duration: $poll-bar-animation-duration;
	color: $darker-grey;
	//line-height: 20px
	padding-left: 5px; }
