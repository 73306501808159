.table__nav {
	align-items: center;
	display: flex;
	justify-content: center;
	margin-bottom: 16px;

	span {
		min-width: 200px;
		text-align: center; }

	button {
		background: url(../images/icons/icon__arrow.svg) no-repeat 5px;
		border: none;
		cursor: pointer;
		height: 18px;
		margin: 0 20px;
		padding: 4px;
		width: 20px;

		&:last-child {
			transform: rotate(180deg); }

		&[disabled] {
			cursor: default;
			opacity: 0.3; } } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.table__nav


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.table__nav


// SM, MD and LG
@media screen and (min-width: $min-sm) {
	.table__nav {
		margin: 10px 0 21px; } }

// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.table__nav


// LG
//@media screen and (min-width: $min-lg )
//.table__nav
