.gallery__text {
  color: white;
  font-size: 1.5rem;
  line-height: 1.2; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.gallery__text


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.gallery__text


// SM, MD and LG
@media screen and (min-width: $min-sm) and (max-width: $max-sm) {
  .gallery__text {
    font-size: 1.7rem; } }

//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.gallery__text


// LG
@media screen and (min-width: $min-lg) {
  .gallery__text {
    font-size: 1.9rem; } }
