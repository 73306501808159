.author__img {
  margin-top: -30px;
  width: 200px;

  img {
    width: 200px;
    height: 200px;
    border-radius: 50%; } }


/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs ) {
  .author__img {
    background: #EDF0F3;
    width: 100%;
    margin: 0;
    float: none;
    text-align: center;

    img {
      margin-bottom: -20px;
      margin-top: 20px;
      width: 140px;
      height: 140px; } } }


// SM, MD
@media screen and (min-width: $min-sm ) and (max-width: $max-md ) {
  .author__img {
    width: 180px;

    img {
      width: 180px;
      height: 180px; } } }


// SM, MD and LG
// @media screen and (min-width: $min-sm ) // and (max-width:  )
