.teaser--video {
  picture {
    &:before {
      background: url(../images/icons/icon__play--bg.svg) no-repeat; } } }

// only for test
.test-ut {
  .teaser--video.teaser--video-ut {
    picture {
      &:before {
        background: url(../images/icons/icon__play--ut.svg) no-repeat; } } } }

/* BREAKPOINT */


// SM, MD and LG
//@media screen and (min-width: $min-sm)
//teaser--video

// MD and LG
//@media screen and (min-width: $min-md)
//teaser--video

// LG
//@media screen and (min-width: $min-lg)
//teaser--video
