.gallery__close {
  cursor: pointer;
  margin-left: 20px; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.gallery__close


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.gallery__close


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.gallery__close


// LG
//@media screen and (min-width: $min-lg )
//.gallery__close
