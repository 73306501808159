.stock-table__table {
  margin-bottom: 20px;
  overflow-x: auto; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.stock-table__table


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.stock-table__table


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.stock-table__table


// LG
//@media screen and (min-width: $min-lg )
//.stock-table__table
