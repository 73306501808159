.teaser__media {
  align-self: flex-start;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
  min-height: 1px;
  position: relative;

  > a {
    display: block;
    width: 100%; } }

.teaser--icnew picture:before {
  content: none!important; }

.teaser__mediainfo {
  align-items: center;
  background-color: rgba(10,25,36,.75);
  bottom: 0;
  display: flex;
  left: 0;
  position: absolute;

  svg {
    background: $red;
    color: #FFFFFF;
    padding: 8px; }

  span {
    color: #FFFFFF;
    font-size: 12px;
    padding: 0 8px; } }



/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.teaser__media


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.teaser__media


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.teaser__media



// LG
//@media screen and (min-width: $min-lg )
//.teaser__media
