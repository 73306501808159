//.sticky-video-wrapper


@media screen and (max-width: $max-xs) and (orientation: portrait) {
  .sticky-video-wrapper {
    &.sticky-video-wrapper--nativeXS {
      position: sticky;
      top: 40px;
      z-index: 99999;
      background-color: white;
      //border: 10px solid #FFFFFF
      //border-bottom: 10px solid #FFFFFF
      //border-top: 2px solid #FFFFFF
      margin: 0 -10px;
      box-shadow: 0 3px 7px -3px rgba(0, 0, 0, 0.7);

      .vplayer__video {
        margin: 0; } } } }






/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.tv


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.tv


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.tv


// LG
//@media screen and (min-width: $min-lg )
//.tv
