.article__related {
  .teaser {
    margin-bottom: 10px; } }


.article__related__title {
  display: block;
  padding: 8px 10px 0;
  text-transform: uppercase;
  font-size: 1.7rem;
  font-weight: bold;
  color: $dark-grey;
  margin: 5px 0 10px; }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs)


// SM
//@media screen and (min-width: $min-sm) and (max-width: $max-sm)


// SM, MD and LG
//@media screen and (min-width: $min-sm)


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )


// MD and LG
//@media screen and (min-width: $min-md )


// LG
@media screen and (min-width: $min-lg) {
  .article__related__title {
    padding: 8px 20px 0; } }
