.tabs__tab {
  counter-reset: teaser;

  .teaser {
    counter-increment: section;
    display: flex;

    &:before {
      background: $red;
      color: white;
      content: counter(section);
      display: block;
      float: left;
      height: 32px;
      margin-right: 16px;
      min-width: 24px;
      padding-top: 5px;
      text-align: center; }

    .teaser__kicker {
      display: block;
      margin-top: -3px; } } }

/* BREAKPOINTS */

// XS
@media screen and (max-width: $max-xs ) {
  .tabs__tab {
    .teaser {
      &:nth-child(n+6) {
        display: none; } }
    &:not(.tabs__tab--default) {
      display: none; } } }


// MD and LG
//@media screen and (min-width: $min-md)
//  .tabs__tab
