.newsletter__title {
	color: $black;
	font-size: 1.8rem;
	font-weight: $bold;
	margin: 0 0 7px;
	padding-right: 24px;
	position: relative; }

/* BREAKPOINTS */


// XS
//@media screen and (min-width: $max-xs )
//.newsletter__title


// SM, MD and LG
@media screen and (min-width: $min-sm) {
	.newsletter__title {
		margin: 10px 0 7px;
		font-size: 2.2rem; } }


// LG
@media screen and (min-width: $min-lg) {
	.newsletter__title {
		font-size: 2.6rem; } }
