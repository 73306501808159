// teaser__kicker is present all over the website in a lot of different widgets
// Any change here can modify many widgets too
.teaser__kicker {
  align-items: center;
  color: $red;
  display: flex;
  font-size: 1.4rem;
  line-height: 1.2;
  margin-bottom: 3px;

  @media (inverted-colors: inverted) {
      color: $inv-red; }

  .update {
    background-color: $red;
    color: #FFFFFF;
    text-transform: uppercase;
    margin-right: 5px;
    padding: 4px 5px; } }

/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs ) {
  .teaser__kicker {

    .update {
      font-size: 1.1rem; } } }


// SM MD
@media screen and (min-width: $min-sm ) and (max-width: $max-md ) {
  .teaser__kicker {

    .update {
      font-size: 1.2rem; } } }

// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .teaser__kicker {
    font-size: 1.6rem; } }

// LG
@media screen and (min-width: $min-lg) {
  .teaser__kicker {
    font-size: 1.8rem;

    .update {
      font-size: 1.3rem; } } }
