//.content
//.content--main
//  .teaser--inline
//    .teaser__infos
//      flex-direction: column
//
//      > *:not(:last-child)
//        margin-bottom: 2px

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.content
//  margin-bottom: 20px


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.content


// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .content--main {
    margin-bottom: 30px; } }

// LG
//@media screen and (min-width: $min-lg )
//.content
