.popup, .popup__unsubscribe {
  background: rgba(0,0,0,0.5);
  bottom: 0;
  display: none;
  left: 0;
  padding: 8px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $popup; }
