.contact {
    input, textarea, select {
        margin-top: 10px; }

    .contact__success {
        flex-direction: column;
        margin-bottom: 30px;
        div {
            margin-bottom: 10px;
            margin-left: auto; }
        a {
            color: $red;
            margin-left: auto; } }

    .contact__small__text {
        color: $light-grey;
        font-size: 1.3rem;
        margin-bottom: 15px;
        margin-top: 5px; }

    .contact__send {
        display: flex;
        margin-bottom: 30px;
        button {
            margin-left: auto; } }

    p {
        a {
            color: $red; } }

    textarea {
        margin-bottom: 10px; }

    .title {
        &.title--small {
            margin-bottom: 0; } } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs)


// SM
//@media screen and (min-width: $min-sm) and (max-width: $max-sm)
//  .contact


// MD
//@media screen and (min-width: $min-md) and (max-width: $max-md)
//  .contact


// SM, MD and LG
@media screen and (min-width: $min-sm) {
    .contact {
        .sidebar--contact {
            padding: 25px 20px 0 20px; } } }

// LG
//@media screen and (min-width: $min-lg)
//  .contact
