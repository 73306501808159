.weather__header {
	margin-bottom: 8px; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs)
//weather__header
//.title

// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.weather__header


// SM, MD and LG
@media screen and (min-width: $min-sm) {
	.weather__header {
		display: flex;
		margin-bottom: 17px; } }

// MD and LG
@media screen and (min-width: $min-md) {
	.weather__header {
		margin-bottom: 17px; } }

// LG
@media screen and (min-width: $min-lg) {
	.weather__header {
		margin-bottom: 25px; } }
