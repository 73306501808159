.gallery__full {
  cursor: pointer;
  margin-left: 15px; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.gallery__full


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.gallery__full

//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.gallery__full


// LG
//@media screen and (min-width: $min-lg )
//.gallery__full
