.browserpush {
	font-size: 1.5rem;

	.title {
		margin-bottom: 12px; } }

/* BREAKPOINTS */

// SM, MD and LG
@media screen and (max-width: $min-sm) {
	.browserpush {
		.title {
			margin-bottom: 14px; } } }

// LG
@media screen and (min-width: $min-lg) {
	.browserpush {
		font-size: 1.7rem; } }
