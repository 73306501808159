.browserpush__modal {
    background: white;
    border: 1px solid $lighter-grey;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(164, 164, 164, 0.5);
    left: 50%;
    max-width: calc(100% - 20px);
    padding: 20px;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 500px;

    &.ask {
        display: flex; }

    &--close {
        display: flex;
        justify-content: flex-end;

        img {
            cursor: pointer;
            margin-bottom: 10px;
            width: 20px; } }

    img {
        display: block;
        max-width: 100%; } }

/* BREAKPOINTS */

@media screen and (max-width: $max-xs ) {
	.browserpush__modal {
        &.ask {
            flex-direction: column; } } }

// SM, MD and LG
//@media screen and (min-width: $min-sm)
//browserpush__modal

// LG
//@media screen and (min-width: $min-lg )
//browserpush__modal
