.tv__prog {
  .tv__prog--now {
    background: $lighter-grey;
    border: none;

    time,
    .teaser__headline {
      color: $red; }

    .tv__content {
      margin-top: -1px;

      .teaser__headline {
        &:after {
          content: "JETZT";
          font-size: 1rem;
          margin-left: 5px;
          vertical-align: super; } } } } }

/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
  .tv__prog--now {
    .teaser__headline {
      margin-bottom: 5px; } } }

// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.tv__prog--now


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.tv__prog--now


// LG
@media screen and (min-width: $min-lg) {
  .tv__prog--now {
    .teaser__headline {
      &:after {
        font-size: 1.2rem !important; } } } }
