.slider--moderator {
  @include border(b);
  margin: 10px 0 20px;
  padding-bottom: 17px;

  .slick-track {
    display: flex; }

  .teaser {
    height: 100%;
    margin-bottom: 0 !important; } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.slider--moderator


// XS and SM
@media screen and (max-width: $max-sm) {
  .slider--moderator {
    margin: 10px -5px 20px; } }

// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.slider--moderator


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.slider--moderator


// LG
//@media screen and (min-width: $min-lg )
//.slider--moderator
