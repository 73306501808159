// minimize layout shift

.slider__slides--default {
  padding-bottom: 53px;

  > article {
    display: none;

    &:first-child {
      display: block;

      .teaser__headline {
        white-space: nowrap;
        overflow: hidden; } } }

  &.slick-initialized {
    padding: 0; } }



// SM
@media screen and (min-width: $min-sm) and (max-width: $max-sm) {
  .slider__slides--default {
    padding-bottom: 55px;

    > article:first-child {
      width: calc(100%/3); } } }


// MD and LG
@media screen and (min-width: $min-md) {
  .slider__slides--default {
    padding-bottom: 55px;

    > article:first-child {
      width: calc(100%/4); } } }



