.table__title {
  background: $light-grey;
  color: white;
  display: block;
  font-weight: $bold;
  line-height: 2.8rem;
  padding: 0 5px; }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.table__title


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.table__title


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.table__title


// LG
//@media screen and (min-width: $min-lg )
//.table__title
