.sidebar--author {
  border: none;
  padding: 0;

  .author__text {
    padding-top: 5px; } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.sidebar--author


// SM
@media screen and (min-width: $min-sm ) and (max-width: $max-sm ) {
  .sidebar--author {
    position: sticky;
    top: 50px; } }


//MD
@media screen and (min-width: $min-md ) and (max-width: $max-md ) {
  .sidebar--author {
    position: sticky;
    top: 70px; } }


// LG
@media screen and (min-width: $min-lg ) {
  .sidebar--author {
    position: sticky;
    top: 100px; } }
