.article__aside__title {
  color: $darker-grey;
  display: block;
  font-size: 1.5rem;
  margin-bottom: 16px;
  margin-top: 6px;
  text-transform: uppercase; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.article__aside__title


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.article__aside__title


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.article__aside__title


//MD and LG
@media screen and (min-width: $min-md) {
  .article__aside__title {
    margin-top: 0; } }

// LG
@media screen and (min-width: $min-lg) {
  .article__aside__title {
    font-size: 1.7rem;
    margin-bottom: 20px; } }
