.article__date {
  color: $dark-grey;
  display: block;
  font-size: 1.1rem;
  line-height: 1.2;
  margin-bottom: 4px; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.article__date


// MD
@media screen and (min-width: $min-md ) and (max-width: $max-md ) {
  .article__date {
    font-size: 1.3rem; } }


// LG
@media screen and (min-width: $min-lg) {
  .article__date {
    font-size: 1.5rem; } }
