.header__nav__action {
  align-items: center;
  display: flex;
  order: 3;

  > li {
    height: 40px;

    > a {
      display: flex;
      align-items: center;
      height: 100%; } } }

/* BREAKPOINTS */


// XS and SM
@media screen and (max-width: $max-sm) {
  .header__nav__action {
    > li {
      display: flex;
      padding: 0 10px; } } }

// SM
@media screen and (min-width: $min-sm ) and (max-width: $max-sm ) {
  .header__nav__action {
    > li {
      height: 48px; } } }

// MD and LG
@media screen and (min-width: $min-md) {
  .header__nav__action {
    margin-left: auto;

    > * {
      display: flex;
      height: 100%; } } }

// LG
@media screen and (min-width: $min-lg) {
  .header__nav__action {
    > li {
      height: 50px;

      &:last-child {} } } }
// N-TV LIVE
//     img
//       max-height: 85px
