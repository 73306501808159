.theme__link {
  color: #46555f;
  display: flex;

  a {
    align-items: center;
    color: #128b7c;
    display: flex;
    font-weight: bold;
    padding-left: 10px;

    &:before {
      background: url(../images/icons/icon__arrow-light-green.svg) no-repeat;
      content: '';
      height: 10px;
      padding-left: 5px;
      width: 10px; } } }


/* BREAKPOINTS */
// XXS

@media screen and (max-width: 400px) {
  .theme__link {
    flex-direction: column;

    a {
      padding-top: 5px;
      padding-left: 0; } } }

// XS
@media screen and (max-width: $max-xs) {
  .theme__link {
    font-size: 13px;
    padding: 5px 0 0; } }

// SM
// @media screen and (min-width: $min-sm) and (max-width: $max-sm)

// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .theme__link {
    font-size: 15px;
    padding: 8px 0 16px; } }


// MD and LG
// @media screen and (min-width: $min-md)


// LG
@media screen and (min-width: $min-lg) {
  .theme__link {
    font-size: 17px;
    padding: 5px 0 20px; } }
