.header__nav__account {
	> a {
		&:before {
			background: url(../images/icons/icon__meinntv.svg) no-repeat;
			background-position: center;
			background-size: contain;
			content: "";
			display: block;
			height: 100%;
			width: 20px; } }

	&.header__nav__account--loggedin {
		> a {
			&:before {
				background-image: url(../images/icons/icon__meinntv__loggedin.svg); } } }

	&.header__nav__account--open {
		background-color: #FFF;

		> a {
			&:before {
				background-image: url(../images/icons/icon__close_darker-grey.svg); } }

		.header-account {
			display: flex; } } }

.header-account {
	position: absolute;
	top: 40px;
	width: 100%;
	color: $black;
	background-color: #FFF;
	left: 0;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	flex-direction: column;
	align-items: center;
	padding: 20px 16px 0 16px;
	z-index: 80000;
	display: none;

	.header-account__login {
		display: flex;
		width: 100%;

		.button {
			width: 100%; } }

	.header-account__create {
		font-size: 1.3rem;
		width: 100%;
		margin-top: 10px;

		a {
			display: block;
			padding: 13px 0;

			&:hover {
				background-color: $lightest-grey; } }

		span {
			font-weight: bold; } }

	.header-account__logout {
		width: 100%;
		border-top: 1px dashed $dark-grey;
		padding-top: 10px;
		margin-bottom: 10px;
		width: 100%;

		a {
			display: block;
			padding: 10px 0;
			font-size: 1.3rem;

			&:hover {
				background-color: $lightest-grey; } } }

	.header-account__info {
		width: 100%;
		display: flex;
		flex-direction: column; }

	.header-account__name {
		font-size: 1.7rem;
		line-height: 1.2;
		font-weight: bold;
		color: $black;
		overflow: hidden;
		text-overflow: ellipsis; }

	.header-account__abo {
		font-size: 1.5rem;
		line-height: 1.2;
		padding-top: 5px; }

	ul {
		width: 100%;
		border-top: 1px dashed $dark-grey;
		margin-top: 10px;
		margin-bottom: 10px;
		padding-top: 10px;

		li {
			font-size: 1.5rem;
			line-height: 1.5;

			a {
				display: block;
				padding: 10px 0;

				&:hover {
					background-color: $lightest-grey; } } } }

	&.header-account--loading {
		background: url(../images/icons/icon__loading__grey.svg) no-repeat;
		background-position: center 30px;
		background-size: 50px;
		background-color: #FFF;
		height: 200px;

		> a {
			visibility: hidden; } } }





/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//header__nav__account


// SM and MD and LG
@media screen and (min-width: $min-sm) {
	.header__nav__account {
		align-self: flex-end;
		font-size: 1.5rem;
		padding: 0 10px; }

	.header-account {
		top: 60px;
		width: 350px;
		right: 80px;
		left: auto; } }


// SM
@media screen and (min-width: $min-sm ) and (max-width: $max-sm ) {
	.header-account {
		top: 48px; } }


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//header__nav__account


// LG
@media screen and (min-width: $min-lg) {
	.header-account {
		top: 85px;

		.header-account__create {
			font-size: 1.5rem; }

		.header-account__logout {
			a {
				font-size: 1.5rem; } }

		.header-account__name {
			font-size: 1.9rem; }

		.header-account__abo {
			font-size: 1.7rem; }

		ul {
			li {
				font-size: 1.7rem; } } } }



