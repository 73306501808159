@media print {
  body > * {
    display: none; }

  .metawrapper {
    display: block; }

  .metawrapper {
    > * {
      display: none; } }

  .container {
    display: block !important;
    > * {
      display: none !important; } }

  .header,
  .article,
  .row,
  *[class^="col-"],
  *[class*=" col-"] {
    display: block !important; }

  .article__aside,
  .article__audio,
  .article__media,
  .article__share,
  #header__nav__timezones,
  .header__nav__lvl1,
  .header__nav__action,
  section, // all widgets
  .sidebar,
  .ob_embed {
    display: none !important; }

  .article__aside {
    &.article__aside--text {
      display: block !important; } }

  .header {
    position: static !important; }

  .breadcrumb {
    display: flex!important; }

  .header__burger {
    display: none!important; }

  .header__nav {
    background: transparent !important; }

  .row,
  *[class^="col-"],
  *[class*=" col-"] {
    margin: 0;
    padding: 0; } }
