.teaser__infos {
  align-items: center;
  color: $dark-grey;
  display: flex;
  font-size: 1.1rem;
  line-height: 1.2;
  font-weight: $semi-bold;
  justify-content: space-between;
  margin-bottom: 4px;
  text-transform: uppercase;

  .teaser__date {
    + .duration {
      &:before {
        content: '| ';
        margin-left: 3px; } } }

  .duration {
    text-transform: lowercase;
    white-space: nowrap; } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.teaser__infos


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.teaser__infos


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )


// LG
@media screen and (min-width: $min-lg) {
  .teaser__infos {
    font-size: 1.3rem; } }
