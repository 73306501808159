.paging {
  align-items: center;
  color: $dark-grey;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  text-align: center;

  div {
    display: flex;

    a {
      align-items: center;
      display: flex;
      height: 30px;
      padding: 0 5px 0 0; }

    &:last-of-type {
      a {
        padding: 0 0 0 5px; } } }

  li {
    display: inline-block;
    font-size: 1.5rem;

    > * {
      display: block;
      line-height: 30px;
      min-width: 30px;
      padding: 0 5px; }

    a {
      background: $dark-grey;
      color: white; }

    span {
      min-width: 20px;

      &.active {
        background: $red;
        color: white;
        min-width: 30px; } } }

  .icon {
    &:before {
      height: 17px;
      width: 17px; } }

  .paging__next1,
  .paging__next10 {
    &:before {
      transform: rotate(180deg); } } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.paging


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.paging


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.paging


// LG
@media screen and (min-width: $min-lg) {
  .paging {
    li {
      font-size: 1.7rem; } } }

