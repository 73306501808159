.teaser__related__inline {
    margin-top: 10px; }

/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs ) {
    .teaser__related__inline {
      display: none; } }

// SM
@media screen and (min-width: $min-sm ) and (max-width: $max-sm ) {
  .teaser__related__inline {
    .teaser__related__element {
      span.teaser__headline, span.teaser__kicker {
          font-size: 1.4rem; } } } }


// MD and LG
//@media screen and (min-width: $min-md)
//.teaser__related__inline


//MD
@media screen and (min-width: $min-md ) and (max-width: $max-md ) {
  .teaser__related__inline {
    .teaser__related__element {
      span.teaser__headline, span.teaser__kicker {
          font-size: 1.4rem; } } } }

// LG
@media screen and (min-width: $min-lg) {
  .teaser__related__inline {
    .teaser__related__element {
      .teaser__headline {
          font-size: 1.7rem; } } } }


