.quiz__answer {
  font-weight: bold;

  &.right {
    span {
      color: $green; } }


  &.wrong {
    span {
      color: $red; } } }

/* BREAKPOINTS */


// Max MD
@media screen and (max-width: $max-md ) {
  .quiz__answer {
    font-size: 1.5rem; } }


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.quiz__answer


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.quiz__answer


// LG
@media screen and (min-width: $min-lg ) {
  .quiz__answer {
    font-size: 1.7rem; } }
