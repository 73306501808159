.article__aside__copy {
  font-size: 1.2rem; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.article__aside__copy


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.article__aside__copy


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.article__aside__copy


// LG
@media screen and (min-width: $min-lg) {
  .article__aside__copy {
    font-size: 1.4rem; } }
