.teaser--live2 {
  position: relative;

  a {
    width: 100%; }

  // GIF
  .teaser--video {
    height: calc(100% - 10px);
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: $teaser--live2;

    .media {
      height: 100%;

      &:before {
        bottom: 50%;
        height: 40px;
        left: 50%;
        transform: translate(-50%, 50%);
        width: 40px; } }

    picture {
      min-width: 0; }

    img {
      height: 100%;
      width: auto; } } }

/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
  .teaser--live2 {
    margin-left: -$gutter-xs-real;
    margin-right: -$gutter-xs-real;
    width: calc(100% + #{$gutter-xs-real * 2}); } }

// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.teaser--live2


// SM, MD and LG
@media screen and (min-width: $min-sm) {
  section {
    &.teaser--live2 {
      padding-bottom: 25px;

      .teaser--video {
        height: calc(100% - 45px);
        right: 10px;
        top: 10px; } } } }

// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.teaser--live2


// LG
@media screen and (min-width: $min-lg) {
  //.teaser--live2
  section {
    &.teaser--live2 {
      .teaser--video {
        height: calc(100% - 55px);
        right: 15px;
        top: 15px; } } } }
