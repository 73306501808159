.tv__prog {
  .tv__prog--live {
    .teaser__headline {
      &:after {
        color: $red;
        content: "LIVE";
        font-size: 1rem;
        margin-left: 5px;
        vertical-align: super; } } } }

//.tv__content
//.teaser__headline
//  margin-top: -6px


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.tv__prog--live


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.tv__prog--live


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.tv__prog--live


// LG
//@media screen and (min-width: $min-lg )
//.tv__prog--live
