.stock__percent {
  width: 40px; }

.stock__element--down {
  .stock__percent {
    color: $red; } }

.stock__element--up {
  .stock__percent {
    color: $green; } }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.stock__percent


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.stock__percent

//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.stock__percent


// LG
//@media screen and (min-width: $min-lg )
//.stock__percent
