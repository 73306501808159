.day__teaser__credit {
  color: $light-grey;
  font-size: 1.3rem; }

/* BREAKPOINTS */


// LG
@media screen and (min-width: $min-lg) {
  .day__teaser__credit {
    font-size: 1.5rem; } }
