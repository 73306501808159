.article__media__error {
  color: #FFFFFF;
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: $black;
  text-align: center;

  p {
   margin: 10px 30px; } }

/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
  .article__media__error {} }


// SM
@media screen and (min-width: $min-sm) and (max-width: $max-sm) {
  .article__media__error {} }


// SM and MD and LG
@media screen and (min-width: $min-sm) {
  .article__media__error {
      font-size: 2.2rem; } }


// LG
@media screen and (min-width: $min-lg) {
  .article__media__error {} }
