.table--col1-1l-1 {
  td {
    width: 60%;
    @include ellipsis();

    &:first-child,
    &:last-child {
      width: 20%; } }

  td {
    &:last-child {
      text-align: right; } } }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.table--col1-1l-1


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.table--col1-1l-1


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.table--col1-1l-1


// LG
//@media screen and (min-width: $min-lg )
//.table--col1-1l-1
