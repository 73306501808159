.newsletter__error {
	background: $red;
	color: white;
	margin-top: 10px;
	padding: 6px; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.newsletter__error


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.newsletter__error


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.newsletter__error


// LG
//@media screen and (min-width: $min-lg )
//.newsletter__error
