.sport__league {
	background: $light-grey;
	color: white;
	display: block;
	font-size: 1.5rem;
	font-weight: $semi-bold;
	padding: 5px 10px; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.sport__league


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.sport__league


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.sport__league


// LG
@media screen and (min-width: $min-lg) {
	.sport__league {
		font-size: 1.7rem; } }
