.quiz__evaluation {
  span {
    font-weight: bold; }

  &.right {
    span {
      color: $green; } }

  &.wrong {
    div {
      color: $green; }

    span {
      color: $red; } }

  .quiz__evaluation--answer {
    margin-bottom: 13px;
    div {
      padding-left: 15px; }

    p {
      font-weight: bold;
      margin-bottom: 4px; } } }

.quiz__evaluation__btn {
  align-items: center;
  color: $red;
  cursor: pointer;
  display: inline-flex;
  margin-bottom: 20px;

  &.open {
    + .quiz__evaluation_container {
      display: block; }

    &:before {
      transform: rotate(90deg); } }

  span {
    @include border(b, $color: $red); }

  &:before {
    background: url(../images/icons/icon__arrow-red.svg) no-repeat;
    color: $red;
    content: '';
    height: 12px;
    transform: rotate(270deg);
    margin-right: 5px;
    width: 12px; } }

.quiz__evaluation_container {
  display: none;

  >div {
    @include border(b);
    margin-bottom: 20px;
    padding-bottom: 20px;

    &:last-child {
      border: none; } } }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.quiz__evaluation


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.quiz__evaluation


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.quiz__evaluation


// LG
//@media screen and (min-width: $min-lg )
//.quiz__evaluation
