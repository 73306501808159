.infografik {
  .infografik__title {
    color: #494949;
    font-weight: bold;
    font-size: 1.8rem;
    display: inline-block;
    margin-bottom: 15px;

    span {
      padding: 2px 5px;
      border-bottom: 1px solid #494949; }

    span:first-child {
      background-color: #494949;
      color: #FFF; } } }


.infografik--switch {
  ul {
    display: flex;
    list-style-type: none;

    li {
      border-bottom: 2px solid $lighter-grey;
      padding: 0 8px;
      margin: 0;
      cursor: pointer;
      font-size: 1.4rem;
      color: $darker-grey;

      &:before {
        Content: none; }

      &.infografik__select__active {
        font-weight: bold;
        border-bottom: 2px solid $dark-grey;
        cursor: default; }

      &:not(.infografik__select__active):hover {
        color: #0A1924; } } }

  .infografik__select {
    display: flex;
    margin-bottom: 20px;

    select {
      padding: 5px;
      border: 1px solid $light-grey;
      color: $darker-grey; }

    button {
      background-color: rgba(255, 255, 254, 0.01);
      border: none;
      padding: 0;
      border-radius: 16px;
      box-shadow: 0 2px 4px 0 rgba(164, 164, 164, 0.5);
      height: 32px;
      width: 32px;
      cursor: pointer;

      &:before {
        background-position: 7px,center;
        background-size: 20px;
        height: 100%;
        width: 100%; }

      &.infografik__prev {
        margin-right: 20px;
        margin-left: auto; }

      &.infografik__next {
        margin-left: 20px;
        margin-right: auto; } } } }

.infografik--double {
  iframe {
    display: block; } }

.infografik__double {
  display: flex;
  width: 100%;

  iframe:last-child {
    display: none; } }

.infografik--showall {
  div:not(:first-of-type) {
    border-top: 1px solid $lighter-grey;
    margin-top: 10px;
    padding-top: 20px; } }



/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )


// MD and LG
@media screen and (min-width: $min-md ) {
  .infografik--double {
    .infografik__select {
      display: none; } }

  .infografik__double {
    iframe {
      &:first-child {
        margin-right: 10px; }

      &:last-child {
        display: block;
        margin-left: 10px; } } } }


