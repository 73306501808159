.header__nav__push {
    &.active {
        a {
            &:after {
                background-color: #dc0028;
                border-radius: 50%;
                content: '';
                display: inline-block;
                height: 8px;
                right: -2px;
                width: 8px;
                position: absolute;
                top: 5px; } } } }
