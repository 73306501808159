.ems-slot {
	display: none;
	justify-content: center; }


.ems-slot__bottom {
	display: none; }


.ems-slot--skyscraper,
.ems-slot--wallpaper {
	position: absolute;
	right: 0;
	top: 0; }


.gujAd {
	display: none; }


.gujAd--billboard,
.gujAd--superbanner,
.gujAd--iqbanner,
.gujAd--mobile,
.gujAd--dmofooter {
	margin-bottom: 20px; }


.gujAd--dmofooter {
	margin-top: 30px; }


.gujAd--halfpagead,
.gujAd--rectangle {
	margin-bottom: 20px; }


.sidebar {
	.ems-slot--teaser .teaser__media,
	.ems-slot--spteaser .teaser__media {
		width: 100%; } }


.ems-native__advert {
	text-align: right;
	margin-bottom: 20px;
	@include border(b); }


.ems-native__advert span {
	display: inline-block;
	font-size: 1.1rem;
	background: $dark-grey;
	color: #FFFFFF;
	text-transform: uppercase;
	padding: 3px 5px;
	letter-spacing: 0.1rem;
	margin-bottom: 2px; }


.ems-native__present {
	text-align: right;
	margin-bottom: 20px;
	color: $dark-grey;
	font-size: 16px;
	padding-bottom: 3px;
	@include border(b); }


.ems-native__present img {
	vertical-align: text-bottom;
	margin-left: 10px; }


/* billboard for tablet viewport min 970, superbanner for tablet max 969 */

@media screen and (min-width: 970px) and (max-width: $max-sm) {
	.ems-slot {
		&.ems-slot--tab-big {
			display: flex; } } }


@media screen and (min-width: $min-sm) and (max-width: 969px) {
	.ems-slot {
		&.ems-slot--tab-small {
			display: flex; } } }



.day {
  .ems-slot--teaser {
    .day__teaser {
      @include border(b);
      margin-bottom: 30px;
      border-color: $red; } }

  &.day-blue {
    .ems-slot--teaser {
      .day__teaser {
        border-color: $blue; } } }

  &.day-green {
    .ems-slot--teaser {
      .day__teaser {
        border-color: $green; } } } }


/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
	.ems-slot {
		&.ems-slot--mob {
			display: flex;

			&.ems-slot--spteaser,
			&.ems-slot--teaser {
				display: block; } } }

	.home .ems-slot--mob.ems-slot--teaser {
 		height: 110px;
 		overflow: hidden; } }


// SM
@media screen and (min-width: $min-sm) and (max-width: $max-sm) {
	.ems-slot {
		&.ems-slot--tab {
			display: flex;

			&.ems-slot--spteaser,
			&.ems-slot--teaser {
				display: block; } } } }

// MD
@media screen and (min-width: $min-md) and (max-width: $max-md) {
	.ems-slot--skyscraper {
		left: 1056px; }

	.ems-slot--wallpaper {
		left: 1036px; }

	.ems-native__advert span {
		font-size: 1.2rem; } }

// LG
@media screen and (min-width: $min-lg) {
	.ems-slot--skyscraper {
		left: 1472px; }

	.ems-slot--wallpaper {
		left: 1453px; }

	.ems-native__advert span {
		font-size: 1.3rem; } }

// MD and LG
@media screen and (min-width: $min-md) {
	.ems-slot {
		&.ems-slot--desk {
			display: flex;

			&.ems-slot--spteaser,
			&.ems-slot--teaser {
				display: block; } } } }


