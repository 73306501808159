//.stock
//  .title
//    display: flex
//    justify-content: space-between


/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs ) {
  .border-bot {
    .stock {
      margin-bottom: 0; } } }


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.stock


// SM, MD and LG
@media screen and (min-width: $min-sm ) { //and (max-width: $max-sm )
  .stock {
    padding: 19px 0 24px;

    .title {
      line-height: 2.1rem; } }

  .border-bot {
    .stock {
      border: none;
      margin-bottom: 0; } } }


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.stock


// MD and LG
@media screen and (min-width: $min-md ) {
  .stock {
    padding: 12px 0 23px;

    .title {
      line-height: 2.2rem;
      margin-bottom: 11px; } } }


// LG
@media screen and (min-width: $min-lg ) {
  .stock {
    padding-bottom: 20px;
    padding-top: 11px; } }
