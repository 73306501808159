.teaser__appbanner {
    display: none; }


@media screen and (max-width: $max-xs) {
    .teaser__appbanner {
        cursor: pointer;
        display: block;
        margin-bottom: 10px;
        padding-bottom: 25%;
        position: relative;
        width: 100%;

        img {
           display: block;
           height: auto;
           max-width: 100%;
           position: absolute; } } }

