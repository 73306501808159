.teaser__summary {
    color: $dark-grey;
    display: block; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
.teaser__summary {}


// Max MD
@media screen and (max-width: $max-md ) {
    .teaser__summary {
        font-size: 1.4rem;
        margin-top: 2px; } }


// SM, MD and LG
//@media screen and (min-width: $min-sm)
//  .teaser__summary

// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//  .teaser__summary

// LG
@media screen and (min-width: $min-lg ) {
    .teaser__summary {
        margin-top: 4px; } }
