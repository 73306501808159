html {
    font-size: 62.5%;
    position: relative; }

 ::selection {
    background: $red;
    color: white; }

body {
    background: $lighter-grey;
    color: $black;
    font-family: $font-family;
    font-size: 1.6rem;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    // header goes sticky, we need to fill the empty space
    &.sticky {
        .container {
            padding-top: 40px; } }

    // When we have an overlay, we have to disable the scroll inside the body
    &.fixed {
        overflow: hidden; }

    &.home {
        .border-bot {
            .sidebar {
                position: initial; } } } }

.container {
    background: white; }

a {
    color: inherit;
    cursor: pointer;
    text-decoration: none; }

* {
    box-sizing: border-box;

    &:focus {
        outline: 0; } }

input {
    &[type=submit] {
        cursor: pointer; } }

input,
textarea,
select,
button {
    font-family: $font-family; }

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
blockquote {
    margin: 0;
    padding: 0; }

picture {
    display: block;
    min-width: 100%;

    img {
        display: block;
        width: 100%; } }

.lazyload {
    opacity: 0; }

.dark-grey {
    color: $dark-grey !important; }

.red {
    color: $red !important; }

.dark-red {
    color: $red !important; }

.green {
    color: $green !important; }

.no-border {
    border: none !important;
    margin-bottom: 0 !important; }

.data-protection-link {
    color: $darker-grey;
    display: block;
    font-size: 1rem;
    margin: 0 0 4px 5px; }

.metawrapper {
    div.fussball-ticker, div.container-fluid, div.basketball-ticker {
        padding-left: 0;
        padding-right: 0; } }


/* ANIMATIONS */

@keyframes slidedown {
    0% {
        top: -40px; }

    100% {
        top: 0; } }

@keyframes daySlidedown {
    0% {
        top: 0; }

    100% {
        top: 48px; } }

@keyframes scale-overlay {
    0% {
        opacity: 0.8;
        transform: scale(0.98); }

    100% {
        opacity: 1;
        transform: scale(1); } }

/* BREAKPOINTS */

// SM, MD and LG
@media screen and (min-width: $min-sm) {
    section,
    .border-bot {
        @include border(b);

        margin-bottom: 20px;

        .sidebar {
            border: none;
            margin-bottom: 0;
            padding: 0; } }

    article {
        margin-bottom: 30px; }

    body {
        &.sticky {
            .container {
                padding-top: 48px; } }

        &.home {
            .sidebar {
                //position: sticky
                top: 50px; } } }

 }    // Sticky Sidebar no IE11 Support

// XS
@media screen and (max-width: $max-xs) {
    section {
        margin-bottom: 15px; } }

// MD
@media screen and (min-width: $min-md) and (max-width: $max-md) {
    body {
        margin-left: 0; }

    .metawrapper {
        margin: 0 auto;
        width: 1256px;
        position: relative; } }

// MD and LG
@media screen and (min-width: $min-md) {
    body {
        margin-top: 40px;

        &.sticky {
            .container {
                padding-top: 63px; } }

        &.home {
            .sidebar {
                top: 70px; } } }

    .container {
        margin: 0; }

    @keyframes slidedown {
        0% {
            top: -60px; }

        100% {
            top: 0; } } }

// LG
@media screen and (min-width: $min-lg) {
    section,
    .border-bot {
        margin-bottom: 20px; }

    body {
        &.sticky {
            .container {
                padding-top: 88px; } }

        &.home {
            .sidebar {
                top: 100px; } } }

    .metawrapper {
        margin: 0 auto;
        width: 1672px;
        position: relative; } }

// CODE FOR ELLIPSIS MULTILINE (CHROME ONLY)
// -webkit-line-clamp: 2;
// -webkit-box-orient: vertical;
// display: -webkit-box;
// overflow: hidden;
