.bnews__alert {
	font-size: 1.5rem;
	width: 100%;

	article {
		background: $red;
		color: white;
		margin-bottom: 0;

		@media (inverted-colors: inverted) {
          background: $inv-red; }

		p {
			line-height: 1.8rem; }

		.bnews__title {
			font-size: 1.2rem;
			font-weight: bold;
			text-transform: uppercase; } }

	.title {
		background: transparent;
		color: #ffffff;
		line-height: 1.2;
		margin-top: 5px;
		text-transform: none; } }


/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
	.bnews__alert {
		margin-left: -$gutter-xs-real;
		margin-right: -$gutter-xs-real;
		width: calc(100% + 20px);

		article {
			padding: 10px; }

		.title {
			line-height: 2.0rem; } } }

// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.bnews__alert


// SM, MD and LG
@media screen and (min-width: $min-sm) {
	.bnews__alert {
		padding-bottom: 25px;

		article {
			margin-bottom: 0;
			padding: 16px; }

		.title {
			font-size: 2.2rem;
			margin-bottom: 10px; } } }

//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.bnews__alert


// MD and LG
//@media screen and (min-width: $min-md )
//.bnews__alert


// LG
@media screen and (min-width: $min-lg) {
	.bnews__alert {
		.title {
			font-size: 2.4rem; } } }
