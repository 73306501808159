.icon {
  &:before {
    content: '';
    display: block;
    height: 37px;
    width: 37px; } }

.icon__arrow {
  &:before {
    background: url(../images/icons/icon__arrow.svg) no-repeat; } }

.icon__arrow-circ {
  &:before {
    background: url(../images/icons/icon__arrow-circ.svg) no-repeat; } }

.icon__arrow-circ--active {
  &:before {
    background: url(../images/icons/icon__arrow-circ--active.svg) no-repeat; } }

.icon__bdl-arrow {
  &:before {
    background: url(../images/icons/icon__bdl-arrow.svg) no-repeat; } }

.icon__close {
  &:before {
    background: url(../images/icons/icon__close.svg) no-repeat; } }

.icon__next,
.icon__prev {
  &:before {
    @include background-image(arrow-light,$darker-grey-ec);
    background-repeat: no-repeat;
 }    //background: url(../images/icons/icon__arrow.svg) no-repeat

  &:not(.slick-disabled) {
    &:hover {
      &:before {
        @include background-image(arrow-light,$red-ec); } } } }

.icon--flipped,
.icon--prev-round:before {
    transform: rotate(180deg); }

.icon__prev:before, {
    transform: rotate(180deg); }

.icon--next-round,
.icon--prev-round {
  &:before {
    background: url(../images/icons/icon__gallery-btn.svg) no-repeat; } }
