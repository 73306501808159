.weather__now {
	align-items: center;
	display: flex;
	flex-shrink: 0;
	font-size: 3.5rem;
	margin-right: 19px;
	padding-right: 19px;
	position: relative;

	> * {
		display: inline-flex; }

	img {
		height: 45px;
		margin-right: 15px; }

	&:after {
		background: $lighter-grey;
		content: "";
		display: block;
		height: 40px;
		position: absolute;
		right: 0;
		top: 0;
		width: 1px; } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.weather__now


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.weather__now


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.weather__now


// MD and LG
@media screen and (min-width: $min-md) {
	.weather__now {
		&:after {
			height: 62px;
			top: -7px; } } }

// LG
@media screen and (min-width: $min-lg) {
	.weather__now {
		&:after {
			height: 72px;
			top: -17px; } } }
