.partner__slider {
   .slick-track {
      align-items: center;
      display: flex; }

   .slick-slide {
      img {
        margin: 0 auto;
        width: calc(100% - 32px); } } }

