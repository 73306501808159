.article__share {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  > * {
    cursor: pointer;
    margin: 0 4px;
    padding: 9px;
    position: relative;

    &:hover {
      background-color: $lighter-grey; }

    &:first-child {
      margin-top: 0; }

    &:last-child {
      margin-bottom: 0; } }

  img {
    height: 24px;
    display: block;
    //left: 30px
 }    //width: auto

  .article__share__print {
    display: none; }

  &.article__share--meldung {
    border-top: 1px solid $lighter-grey;
    border-bottom: 1px solid $lighter-grey;
    padding: 5px 0;

    .article__share__followGN {
      font-size: 1.5rem;
      line-height: 22px;
      text-decoration: underline;
      color: $darker-grey;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:after {
        content: "";
        background-image: url(../images/icons/icon__audio.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        width: 22px;
        height: 22px;
        margin: 0 10px 0 5px; } } } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//  .article__share


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.article__share


// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .article__share {
    &:not(.article__share--meldung) {
      display: flex;
      flex-direction: column;
      left: $gutter-sm;
      position: absolute;
      top: 0;

      > * {
        margin: 4px 0;

        &:nth-child(4) {
          margin-top: 10px; } } }

    .article__share__whatsapp {
      display: none; }

    .article__share__print {
      display: block; } } }

// MD
@media screen and (min-width: $min-md ) and (max-width: $max-md ) {
  .article__share {
    &:not(.article__share--meldung) {
      left: $gutter-md; } } }


// LG
@media screen and (min-width: $min-lg) {
  .article__share {
    &:not(.article__share--meldung) {
      left: $gutter-lg; } } }


