
.form {
	input[type="text"],
	input[type="email"],
	input[type="tel"],
	textarea,
	select {
		appearance: none;
		border: 1px solid $lighter-grey;
		border-radius: 5px;
		color: $black;
		font-size: 1.5rem;
		height: 42px;
		padding: 0 15px;
		width: 100%;

		&:placeholder {
			color: $dark-grey;
			opacity: 1; }

		&:hover {
			border: 1px solid $dark-grey; }

		&:focus {
			border-color: $dark-grey;
			box-shadow: 0px 0px 4px $dark-grey; }

		&.form__error {
			border-color: $red;
			box-shadow: 0px 0px 4px $red; } }

	textarea {
		height: auto;
		padding: 10px 15px; }

	select {
		cursor: pointer;
		background-image: url("data:image/svg+xml,%3Csvg width='22' height='13' viewBox='0 0 22 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.9999 12.0999L0.899902 1.9999L1.99991 0.899902L10.9999 9.8999L19.9999 0.899902L21.0999 1.9999L10.9999 12.0999Z' fill='%2346555F'/%3E%3C/svg%3E%0A");
		background-repeat: no-repeat;
		background-size: 20px;
		background-position: center right 15px;

		option {
			color: $dark-grey; } }

	.form__checkbox {
		input[type="checkbox"] {
			display: none;

			&+label {
				display: flex;
				margin-bottom: 10px;

				&:after {
					border: 1px solid $lighter-grey;
					border-radius: 3px;
					box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
					content: '';
					display: block;
					height: 18px;
					margin-left: auto;
					width: 18px; } }

			&:checked {
				&+label {
					&:after {
						background: $red url("data:image/svg+xml,%3Csvg width='14' height='11' viewBox='0 0 14 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 5.23529L4.84 9L13 1' stroke='white' stroke-width='1.5'/%3E%3C/svg%3E ") no-repeat center;
						border: none; } } } } } }



.form--search {
	display: flex;
	align-items: center;
	width: 100%;

	input[type="submit"] {
		background: url(../images/icons/icon__search--grey.svg) no-repeat center;
		background-size: 20px 20px;
		border: none;
		border-radius: 5px;
		color: transparent;
		height: 42px;
		margin-left: -42px;
		padding: 0;
		width: 42px;

		&:hover {
			background-color: $red;
			background-image: url(../images/icons/icon__search.svg); } } }


// XS


// LG
@media screen and (min-width: $min-lg) {
	.form {
		input[type="text"],
		input[type="email"],
		input[type="tel"],
		textarea,
		select {
			font-size: 1.7rem; } } }



