//.title--dark


/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs ) {
  .title--dark {
    background: $dark-grey;
    color: white; } }


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.title--dark


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.title--dark


// LG
//@media screen and (min-width: $min-lg )
//.title--dark
