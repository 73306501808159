.bnews__more {
  align-items: center;
  @include border($color: $red);
  color: $darker-grey;
  display: flex;
  font-size: 1.2rem;
  font-weight: $semi-bold;
  text-transform: uppercase;

  .bnews__more__newsletter {
    align-items: center;
    display: none;
    padding: 8px 16px;

    &:after {
      background: url(../images/icons/icon__arrow-headline.svg) no-repeat;
      content: '';
      height: 10px;
      margin-left: 8px;
      width: 10px; } }

  .bnews__more__apps {
    align-items: center;
    border-left: 1px solid $red;
    display: none;
    padding: 8px 16px;
    width: 100%;

    &:after {
      background: url(../images/icons/icon__arrow-headline.svg) no-repeat;
      content: '';
      flex-shrink: 0;
      height: 10px;
      margin-left: auto;
      width: 10px; } }

  .bnews__more__mobile {
    display: none;
    width: 100%;

    span {
      margin-right: auto; } }

  .bnews__more__button {
    justify-content: flex-end;

    a {
      align-items: center;
      border: 1px solid $darker-grey;
      display: flex;
      font-size: 1.1rem;
      padding: 4px 8px;
      text-transform: uppercase;
      white-space: nowrap;

      &:before {
          content: '';
          display: inline-flex;
          height: 15px;
          margin-right: 2px;
          width: 15px; } }

    &.bnews__more__android {
      a {
        &:before {
          background: url(../images/icons/icon__bnews--playstore.svg); } } }

    &.bnews__more__ios {
      a {
        &:before {
          background: url(../images/icons/icon__bnews--apple.svg); } } }

    &.bnews__more__whatsapp {
      a {
        &:before {
          background: url(../images/icons/icon__bnews--whatsapp.svg); } } } }

  .bnews__more__buttons {
    display: flex; } }




/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs ) {
  .bnews__more {
    .bnews__more__button {
      margin-right: 8px; }

    .bnews__more__buttons {
      margin-top: 5px; }

    .bnews__more__mobile {
      flex-direction: column;
      padding: 10px; } } }



// Min SM
@media screen and (min-width: $min-sm ) {
  .bnews__more {
    .bnews__more__button {
      margin-left: 8px; }

    .bnews__more__mobile {
      align-items: center;
      padding: 8px 16px; } } }



// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.bnews__more


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.bnews__more


// LG
//@media screen and (min-width: $min-lg )
//.bnews__more
