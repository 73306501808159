.popup__wrapper, .popup__wrapper__unsubscribe {
  background: white;
  margin: auto;
  max-width: 350px;
  padding: 16px !important;
  position: relative;
  top: calc(50% - 81px); }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.popup__wrapper


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.popup__wrapper


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.popup__wrapper


// LG
//@media screen and (min-width: $min-lg )
//.popup__wrapper
