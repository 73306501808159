.teaser__ad {
  background: $dark-grey;
  color: white;
  font-size: 1.1rem;
  margin-right: 5px;
  padding: 3px 5px; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.teaser__ad


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.teaser__ad


// MD
@media screen and (min-width: $min-md ) and (max-width: $max-md ) {
  .teaser__ad {
      font-size: 1.2rem; } }



// LG
@media screen and (min-width: $min-lg ) {
  .teaser__ad {
      font-size: 1.3rem; } }
