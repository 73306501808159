.newsletter__back {
	display: block; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.newsletter__back


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.newsletter__back


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.newsletter__back


// LG
//@media screen and (min-width: $min-lg )
//.newsletter__back
