
.sport {
    .sport__update {
        align-items: center;
        color: $dark-grey;
        cursor: pointer;
        display: flex;
        font-weight: bold;
        text-transform: uppercase;
        &:after {
            background: url(../images/icons/icon__update_off.svg);
            background-repeat: no-repeat;
            content: "";
            height: 18px;
            margin-left: 5px;
            width: 38px; }

        &.active {
            &:after {
                background: url(../images/icons/icon__update_on.svg);
                background-repeat: no-repeat;
                content: "";
                height: 18px;
                margin-left: 5px;
                width: 38px; } } } }


/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs ) {
    .sport {
        .sport__update {
            font-size: 1rem;
            margin-bottom: 8px; } } }


// SM
@media screen and (min-width: $min-sm ) {
    .sport {
        .sport__update {
            font-size: 1.2rem; } } }


// SM, MD and LG
//@media screen and (min-width: $min-sm) and (max-width: $max-md )
//  .sport


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//  .sport


// LG
//@media screen and (min-width: $min-md)
//  .sport
