.browserpush__ask {
    display: flex;
    flex: 1;
    flex-direction: column;

    &__logo {
        margin-right: 10px; }

    &__buttons {
        margin-top: 10px;

        button {
            margin-right: 10px;
            width: 70px; } } }

/* BREAKPOINTS */

@media screen and (max-width: $max-xs ) {
	.browserpush__ask {
        margin-top: 10px; } }

// SM, MD and LG
//@media screen and (min-width: $min-sm)
//browserpush__ask

// LG
//@media screen and (min-width: $min-lg )
//browserpush__ask
