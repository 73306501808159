.feedback__popup {
  background-color: white;
  @include border();
  display: none;
  left: 50%;
  max-width: 100%;
  padding: 12px;
  position: fixed;
  top: 20%;
  transform: translateX(-50%);
  width: 340px;
  z-index: $popup;

  &.open {
    display: block; }

  textarea {
    @include border($width: 1px);
    font-size: 1.5rem;
    margin-bottom: 4px;
    margin-top: 15px;
    max-width: 100%;
    min-width: 100%;
    padding: 4px; }

  button[type="submit"] {
    background-color: $red;
    border: none;
    color: white;
    cursor: pointer;
    display: flex;
    font-size: 1.4rem;
    margin-left: auto;
    padding: 4px 8px; }

  p {
    line-height: 24px;
    text-align: right; } }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.feedback__popup


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.feedback__popup


// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .feedback__popup {
    width: 500px; } }

// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.feedback__popup


// LG
//@media screen and (min-width: $min-lg )
//.feedback__popup

