//.header__nav__lvl2--main


/* BREAKPOINTS */


// MD and LG
@media screen and (min-width: $min-md) {
  .header__nav__lvl2--main {
    background: $lighter-grey;
    height: calc(100% - 17px);
    margin-left: -$gutter-md-real;
    margin-top: 17px;
    padding-top: 0;
    width: 254px;

    > li {
      @include border(b, $color: white);
      padding-left: $gutter-md-real; }

    .header__nav__lvl3 {
      left: 254px;
      width: 254px; } } }

// LG
@media screen and (min-width: $min-lg) {
  .header__nav__lvl2--main {
    margin-left: -$gutter-lg-real;

    > li {
      padding-left: $gutter-lg-real; } } }

