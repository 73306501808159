.day__teaser__hour {
  color: $red;
  font-size: 1.4rem;
  line-height: 1.2;
  width: 100%; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.day__teaser__hour


// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .day__teaser__hour {
    display: block;
    font-size: 1.8rem;
    margin-top: 4px; } }

// LG
@media screen and (min-width: $min-lg) {
  .day__teaser__hour {
    display: block;
    font-size: 2.0rem;
    margin-top: 2px; } }
