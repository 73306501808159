.article__source {
  color: $dark-grey;
  font-size: 1.4rem;
  line-height: 1.1;
  margin-top: 13px;
  text-align: left; }

/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
  .article__source {
    font-size: 1.5rem; } }

// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.article__source


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.article__source


// LG
@media screen and (min-width: $min-lg) {
  .article__source {
    font-size: 1.5rem; } }
