.article__teasers {
  > ul {
    > li {
      display: none;
      opacity: 0;
      transition: opacity 2s;

      &.active {
        display: block;
        opacity: 1; } } } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.article__teasers


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.article__teasers


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.article__teasers


// LG
//@media screen and (min-width: $min-lg )
//.article__teasers
