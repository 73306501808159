.stock__group {
	display: flex;
	flex-wrap: wrap;
	padding-right: 30px;
	width: 50%;

	&:first-child {
		padding-left: 0; }

	&:nth-child( n+3 ) {
		display: none; } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs)
//stock__group

// XS +
@media screen and (min-width: 550px) and (max-width: $max-xs) {
	.stock__group {
		width: 33.333%;

		&:nth-child( 3 ) {
			display: block; } } }

// SM
//@media screen and (min-width: $min-sm) and (max-width: $max-sm)
//.stock__group


// SM + and MD
@media screen and (min-width: 1000px) {
	.stock__group {
		width: 33.333%;

		&:nth-child( 3 ) {
			display: block; } } }

// SM, MD and LG
@media screen and (min-width: $min-sm) {
	.stock__group {
		@include border(r);
		padding: 0 15px; } }

// MD
//@media screen and (min-width: $min-md) and (max-width: $max-md)
//stock__group

// LG
@media screen and (min-width: $min-lg) {
	.stock__group {
		display: block !important;
		padding: 0 20px;
		width: 25%; } }
