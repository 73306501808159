.teaser--live {
  picture {
    &:before {
      background: url(../images/icons/icon__live.svg) no-repeat; } } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//teaser--live


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//teaser--live


// SM, MD and LG
@media screen and (min-width: $min-sm) {
  //.teaser--live
  section {
    &.teaser--live {
      padding-bottom: 20px; } } }

// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//teaser--live


// LG
//@media screen and (min-width: $min-lg )
//teaser--live
