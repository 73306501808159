.article__infos {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 15px;

  img {
    display: block;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    margin-right: 10px; }

  .article__infos__txt {
    display: flex;
    flex-direction: column; }

  .article__author {
    margin: 0;
    font-style: normal;
    color: $darker-grey;
    line-height: 1.5; }

  .article__date {
    margin: 0;
    font-size: 1.3rem;
    line-height: 1.3; } }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//  .article__infos


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//  .article__infos


// SM and MD
//@media screen and (min-width: $min-sm ) and (max-width: $max-md )
//  .article__infos


// LG
@media screen and (min-width: $min-lg) {
  .article__infos {
    margin-top: 20px;

    img {
      width: 47px;
      height: 47px; }

    .article__date {
      font-size: 1.5rem; } } }


