.vplayer__video {
  //transition: height 0.3s linear 0s, width 0.3s linear 0s, transform 0.3s linear 0s
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  margin-bottom: 15px;

  .vplayer__video__img {
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: -1; }

  #playerwrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='200px' height='200px' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='xMidYMid' class='lds-rolling' style='background: none;'%3E%3Ccircle cx='50' cy='50' fill='none' ng-attr-stroke='%7B%7Bconfig.color%7D%7D' ng-attr-stroke-width='%7B%7Bconfig.width%7D%7D' ng-attr-r='%7B%7Bconfig.radius%7D%7D' ng-attr-stroke-dasharray='%7B%7Bconfig.dasharray%7D%7D' stroke='%23FFFFFF' stroke-width='7' r='43' stroke-dasharray='202.63272615654165 69.54424205218055' transform='rotate(300 50 50)'%3E%3CanimateTransform attributeName='transform' type='rotate' calcMode='linear' values='0 50 50;360 50 50' keyTimes='0;1' dur='1.5s' begin='0s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/circle%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 13%;
    background-position: center;

    .playerwrapper__vidnode {
      visibility: hidden; } } }

.vplayer__video--foundation {
  #playerwrapper {
    background: none; } }

.vplayer--sticky .vplayer__video  #playerwrapper {
  position: relative;
  width: auto;
  height: auto; }

.vplayer--sticky .vplayer__video {
  width: 330px;
  position: absolute;
  top: 10px;
  height: auto;
  padding: 0;
  z-index: 99; }

.vplayer--sticky.vplayer--article .vplayer__video {
  left: 50%;
  transform: translateX(-50%); }

// XS
@media screen and  (max-width: $max-xs) {
  .vplayer--sticky .vplayer__video {
    //width: 260px
    left: 50%;
    transform: translateX(-50%); } }

// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .vplayer__video {
    #playerwrapper {
      background-size: 8%; } } }

