.header__nav {
  background: $bg-grey;
  color: white;
  display: flex;
  height: 40px;
  position: relative;
  z-index: $header__nav;

  li {
    list-style: none; }

  .day {
    padding: 0 10px; } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.header__nav


// XS and SM
@media screen and (max-width: $max-sm) {
  .header__nav {
    li {
      &.active {
        > .header__nav__element {
          color: $red;
          font-weight: $bold;

          .icon {
            &:before {
              background: url(../images/icons/icon__arrow-circ--active.svg) no-repeat; } } } } } } }

// SM
@media screen and (min-width: $min-sm) and (max-width: $max-sm) {
  .header__nav {
    height: 48px; } }

// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.header__nav


// MD and LG
@media screen and (min-width: $min-md) {
  .header__nav {
    height: 60px;

    .icon__burger {
      display: none; } } }

// LG
@media screen and (min-width: $min-lg) {
  .header__nav {
    height: 85px; } }
