.feedback {
  > a,
  > span {
    align-items: baseline;
    color: #778892;
    display: flex;
    font-size: 1.5rem;
    line-height: 1.4;

    &:before {
      background: url(../images/icons/icon__arrow-headline.svg) no-repeat;
      content: '';
      height: 8px;
      transform: rotate(180deg);
      margin-right: 5px;
      width: 8px; } } }

.feedback--open {
  > div {
    display: block; } }

/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
  .feedback {
    margin-bottom: 12px;

    a,
    > span {
      margin-bottom: 3px; } } }

// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.feedback


// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .feedback {
    margin-bottom: 9px;
    padding: 2px 0 11px 0;

    a,
    > span {
      display: inline-flex;
      line-height: 1; } } }

// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.feedback

// MD and LG
@media screen and (min-width: $min-md) {
  .feedback {
    padding-top: 0; } }

// LG
@media screen and (min-width: $min-lg) {
  .feedback {
    a,
    > span {
      font-size: 1.7rem;

      &:before {
        height: 10px;
        margin-right: 8px;
        width: 10px; } } } }
