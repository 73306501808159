.gallery__slider {
  display: flex;
  height: 100%;
  overflow: hidden;
  visibility: hidden;
  //position: relative

  &.slick-initialized {
    visibility: visible; }

  .slick-track {
    opacity: 0;
    transition: opacity 0.2s; } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.gallery__slider


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.gallery__slider


// SM, MD and LG
//@media screen and (min-width: $min-sm )
//.gallery__slider


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.gallery__slider


// LG
//@media screen and (min-width: $min-lg )
//.gallery__slider
