.teaser--banner {
  border: none;
  text-transform: uppercase;

  a {
    background: $bg-grey;
    display: block;
    padding: 25px 0; }

  span {
    display: flex;
    justify-content: center;
    text-align: center;

    &:last-child {
      &:before {
        background: url(../images/icons/icon__arrow-red.svg) no-repeat; } } }

  .teaser__title {
    color: white;
    font-size: 2.2rem;
    margin-bottom: 5px; }

  .teaser__more {
    font-size: 1.8rem;
    font-weight: normal;
    text-decoration: none; } }

/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
  .teaser--banner {
    margin: 0 0 20px; } }

// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.teaser--banner


// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .teaser--banner {
    border-bottom: none !important; } }

// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.teaser--banner


// MD and LG
@media screen and (min-width: $min-md) {
  .teaser--banner {
    a {
      padding: 29px 0; } } }

// LG
@media screen and (min-width: $min-lg) {
  .teaser--banner {
    .teaser__title {
      font-size: 2.3rem; }

    .teaser__more {
      font-size: 2rem; } } }
