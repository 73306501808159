.button {
	align-items: center;
	background-color: $darker-grey;
	border: none;
	border-radius: 5px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
	color: white;
	cursor: pointer;
	display: inline-flex;
	font-size: 1.5rem;
	font-weight: bold;
	height: 42px;
	justify-content: center;
	text-decoration: none;
	padding: 0 10px;

	&:hover {
		background-color: $dark-grey; } }

.button--icon {
	&:before {
		background-position: center;
		background-repeat: no-repeat;
		background-size: 20px 20px;
		content: '';
		height: 100%;
		margin-right: 10px;
		width: 20px; } }

.button--highlight {
	background-color: $red;

	&:hover {
		background-color: #F2414A; } }

.button--secondary {
	background-color: transparent;
	border: 1px solid $dark-grey;
	color: $dark-grey;

	&:hover {
    	background-color: transparent;
    	border: 1px solid $light-grey;
    	color: $light-grey; } }



/* BREAKPOINTS */


// LG
@media screen and (min-width: $min-lg ) {
	.button {
		font-size: 1.7rem; } }

