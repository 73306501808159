.tv__prog__time {
  align-self: flex-start;
  color: $dark-grey;
  font-size: 1.5rem;
  font-weight: $bold;
  margin-right: 10px; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.tv__prog__time


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.tv__prog__time


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.tv__prog__time


// LG
@media screen and (min-width: $min-lg) {
  .tv__prog__time {
    font-size: 1.7rem;
    line-height: 1.9; } }

