.shopteaser {}

.shopteaser__wrapper {
  display: flex;
  flex-wrap: wrap;
  background-color: $lighter-grey;
  padding: 16px; }

.shopteaser__media {
  width: 100%;
  margin-bottom: 10px;

  > * {
    display: block;
    width: 100%;
    padding-bottom: 56.25%;
    position: relative;
    background: #FFFFFF; }

  img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: contain;
    width: 100%;
    height: 100%;
    display: block; } }

.shopteaser__media--4-3 {
  > * {
    padding-bottom: 75%; } }

.shopteaser__media--17-6 {
  > * {
    padding-bottom: 35.25%; } }

.shopteaser__content {
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column; }

.shopteaser__text {
  font-size: 1.6rem;
  line-height: 1.2;
  color: $black; }

.shopteaser__rating {
  display: flex;
  align-items: center;
  margin-top: 5px; }

.shopteaser__rating-stars {
  width: 79px;
  //background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20preserveAspectRatio%3D%22none%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22%237d8c96%22%20d%3D%22M11.731%2C18.829%2C5.792%2C22.411a.52.52%2C0%2C0%2C1-.775-.563l1.571-6.756a.52.52%2C0%2C0%2C0-.166-.511L1.18%2C10.04a.521.521%2C0%2C0%2C1%2C.3-.912l6.911-.593a.52.52%2C0%2C0%2C0%2C.434-.316l2.7-6.389a.52.52%2C0%2C0%2C1%2C.958%2C0l2.7%2C6.389a.52.52%2C0%2C0%2C0%2C.434.316l6.911.593a.521.521%2C0%2C0%2C1%2C.3.912l-5.242%2C4.541a.52.52%2C0%2C0%2C0-.166.511l1.571%2C6.756a.52.52%2C0%2C0%2C1-.775.563l-5.939-3.582A.52.52%2C0%2C0%2C0%2C11.731%2C18.829Z%22%2F%3E%3C%2Fsvg%3E")
  background-image: url(../images/amazon-rating-empty.svg);
  background-repeat: repeat-x;
  background-size: 16px;

  div {
    height: 16px;
    //background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20preserveAspectRatio%3D%22none%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22%23ffce01%22%20d%3D%22M11.731%2C18.829%2C5.792%2C22.411a.52.52%2C0%2C0%2C1-.775-.563l1.571-6.756a.52.52%2C0%2C0%2C0-.166-.511L1.18%2C10.04a.521.521%2C0%2C0%2C1%2C.3-.912l6.911-.593a.52.52%2C0%2C0%2C0%2C.434-.316l2.7-6.389a.52.52%2C0%2C0%2C1%2C.958%2C0l2.7%2C6.389a.52.52%2C0%2C0%2C0%2C.434.316l6.911.593a.521.521%2C0%2C0%2C1%2C.3.912l-5.242%2C4.541a.52.52%2C0%2C0%2C0-.166.511l1.571%2C6.756a.52.52%2C0%2C0%2C1-.775.563l-5.939-3.582A.52.52%2C0%2C0%2C0%2C11.731%2C18.829Z%22%2F%3E%3C%2Fsvg%3E")
    background-image: url(../images/amazon-rating-full.svg);
    background-repeat: repeat-x;
    background-size: 16px; } }

.shopteaser__rating-count {
  font-size: 1.3rem;
  color: #376eaa;
  padding-left: 10px; }

.shopteaser__price {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: #000000;
  font-size: 2.0rem;
  font-weight: bold;
  margin-top: 10px; }

.shopteaser__price-through {
  text-decoration: line-through;
  font-size: 1.6rem;
  font-weight: normal;
  padding-left: 10px;
  color: #46555f; }

.shopteaser__prime {
  width: 65px;
  height: 15px;
  background: url(../images/amazon-prime.svg) right center no-repeat; }

.shopteaser__button-wrapper {
  .button {
    font-weight: normal;
    margin-right: 5px;
    margin-top: 15px;
    width: 100%; } }


.shopteaser__button-wrapper--logo {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .button {
    width: auto; }

  img {
    width: 100px;
    margin-left: auto;
    margin-top: 15px; } }

.shopteaser__sellers {
  margin-top: 16px; }

.shopteaser__content > .shopteaser__sellers:first-child {
  margin-top: 0; }

.shopteaser__seller {
  display: flex;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid white; }

.shopteaser__seller:first-child {
  padding-top: 0; }

.shopteaser__seller .shopteaser__price-logo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(100% - 150px); }

.shopteaser__seller .shopteaser__price {
  display: flex;
  flex-direction: column;
  margin-top: 0;
  align-items: flex-start; }

.shopteaser__seller .shopteaser__price-through {
  padding: 0;
  font-size: 1.3rem;
  margin-top: 5px; }

.shopteaser__seller .shopteaser__logo {
  margin-top: 10px; }

.shopteaser__seller .shopteaser__logo img {
  width: 80px; }

.shopteaser__seller .shopteaser__button-wrapper {
  width: 150px;
  text-align: right; }

.shopteaser__seller .shopteaser__button-wrapper .button {
  margin: 0;
  width: auto;
  font-weight: bold; }



/* BREAKPOINTS */


// SM, MD, LG
@media screen and (min-width: $min-sm ) {
  .article__aside--full {
    .shopteaser__media {
      width: 30%;
      padding-right: 9px;
      margin-bottom: 0;

      > * {
        padding-bottom: 75%; } }

    .shopteaser__content {
      width: 70%;
      padding-left: 9px; }

    .shopteaser__wrapper {
      display: flex; }

    .shopteaser__button-wrapper .button {
      width: auto; } }

  .shopteaser__wrapper .shopteaser__content:first-child {
    width: 100%; }

  .shopteaser__seller .shopteaser__price-logo {
    flex-direction: row;
    align-items: center; }

  .shopteaser__seller .shopteaser__price {
    width: 50%; }

  .shopteaser__seller .shopteaser__logo {
    margin-top: 0;
    width: 50%; }

  .shopteaser__seller .shopteaser__logo img {
    width: 100px; } }


// LG
@media screen and (min-width: $min-lg ) {
  .shopteaser__wrapper {
    padding: 20px; }

  .shopteaser__text {
    font-size: 1.8rem; }

  .shopteaser__price {
    font-size: 2.2rem; }

  .shopteaser__price-through {
    font-size: 1.8rem; }

  .shopteaser__seller .shopteaser__price-through {
    font-size: 1.3rem; } }


