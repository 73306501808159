
.abo-ad {
	background: $lightest-grey;
	padding: 20px 16px;
	margin: 15px 0 20px 0;
	position: relative;
	display: flex;
	flex-direction: column;

	.abo-ad__title {
		display: block;
		font-size: 1.7rem;
		font-weight: bold;
		margin-bottom: 8px; }

	.abo-ad__txt {
		display: block;
		font-size: 1.5rem;
		margin-bottom: 16px;

		span {
			white-space: nowrap; } }

	.button {
		width: 100%; } }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )



// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )



// MD and LG
@media screen and (min-width: $min-md ) {
	.abo-ad {
		flex-direction: row;
		align-items: center;

		.abo-ad__txt {
			margin-bottom: 0; }

		.button {
			margin-left: auto;
			padding: 0 40px;
			width: auto; } } }


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )



// LG
@media screen and (min-width: $min-lg) {
	.abo-ad {
		.abo-ad__title {
			font-size: 2rem; }

		.abo-ad__txt {
			font-size: 1.7rem; } } }


