.article__aside--img {
  border: none;
  margin-bottom: 0;

  picture {
    position: relative; }

  figcaption {
    padding: 9px 10px; } }


.article__aside--img-ctb {

  picture {
    cursor: pointer;

    &:before {
      background: url(../images/icons/icon__zoom.svg);
      bottom: 15px;
      content: '';
      display: block;
      position: absolute;
      right: 15px; } } }



/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.article__aside--img


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.article__aside--img


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.article__aside--img


// LG
//@media screen and (min-width: $min-lg )
//.article__aside--img
