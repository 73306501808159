
// Carefull! Any change here will affect theme__detail too!

.search__results {
  .teaser--video,
  .teaser--gallery {
    .teaser__date {
      &:before {
        background: $darker-grey;
        color: white;
        content: 'VIDEO';
        display: block;
        float: left;
        margin-right: 5px;
        padding: 1px 2px; } }

    .teaser__content {
      margin-top: 0; } }


  .teaser--gallery {
    .teaser__date {
      &:before {
        content: 'BILDERSERIE'; } } } }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.search__results


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.search__results


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.search__results


// MD and LG
@media screen and (min-width: $min-md ) {
  .search__results {
    .teaser {
      margin-bottom: 30px; } } }


// LG
//@media screen and (min-width: $min-lg )
//.search__results
