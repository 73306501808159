/*! 001 - Default */

.VWDcontent {
  * {
    color: $black; }

  a {
    color: #778892;

    &:focus, &:hover {
      color: #778892; } }

  .pos {
    color: $green;

    * {
      color: $green; } }

  .neutral {
    color: #7e8688;

    * {
      color: #7e8688; } }

  .neg {
    color: $red;

    * {
      color: $red; } }

  .icon_pos {
    background-size: 14px;
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ccircle%20cx%3D%22896%22%20cy%3D%22896%22%20r%3D%22700%22%20fill%3D%22white%22%2F%3E%3Cpath%20fill%3D%22#{$green-ec}%22%20id%3D%22fa-arrow-circle-up%22%20d%3D%22M1412%20895q0-27-18-45l-362-362-91-91q-18-18-45-18t-45%2018l-91%2091-362%20362q-18%2018-18%2045t18%2045l91%2091q18%2018%2045%2018t45-18l189-189v502q0%2026%2019%2045t45%2019h128q26%200%2045-19t19-45v-502l189%20189q19%2019%2045%2019t45-19l91-91q18-18%2018-45zm252%201q0%20209-103%20385.5t-279.5%20279.5-385.5%20103-385.5-103-279.5-279.5-103-385.5%20103-385.5%20279.5-279.5%20385.5-103%20385.5%20103%20279.5%20279.5%20103%20385.5z%22%2F%3E%3C%2Fsvg%3E"); }

  .icon_neutral {
    background-size: 14px;
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ccircle%20cx%3D%22896%22%20cy%3D%22896%22%20r%3D%22700%22%20fill%3D%22white%22%2F%3E%3Cpath%20fill%3D%22%237e8688%22%20id%3D%22fa-arrow-circle-right%22%20d%3D%22M1413%20896q0-27-18-45l-91-91-362-362q-18-18-45-18t-45%2018l-91%2091q-18%2018-18%2045t18%2045l189%20189h-502q-26%200-45%2019t-19%2045v128q0%2026%2019%2045t45%2019h502l-189%20189q-19%2019-19%2045t19%2045l91%2091q18%2018%2045%2018t45-18l362-362%2091-91q18-18%2018-45zm251%200q0%20209-103%20385.5t-279.5%20279.5-385.5%20103-385.5-103-279.5-279.5-103-385.5%20103-385.5%20279.5-279.5%20385.5-103%20385.5%20103%20279.5%20279.5%20103%20385.5z%22%2F%3E%3C%2Fsvg%3E"); }

  .icon_neg {
    background-size: 14px;
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ccircle%20cx%3D%22896%22%20cy%3D%22896%22%20r%3D%22700%22%20fill%3D%22white%22%2F%3E%3Cpath%20fill%3D%22#{$red-ec}%22%20id%3D%22fa-arrow-circle-down%22%20d%3D%22M1412%20897q0-27-18-45l-91-91q-18-18-45-18t-45%2018l-189%20189v-502q0-26-19-45t-45-19h-128q-26%200-45%2019t-19%2045v502l-189-189q-19-19-45-19t-45%2019l-91%2091q-18%2018-18%2045t18%2045l362%20362%2091%2091q18%2018%2045%2018t45-18l91-91%20362-362q18-18%2018-45zm252-1q0%20209-103%20385.5t-279.5%20279.5-385.5%20103-385.5-103-279.5-279.5-103-385.5%20103-385.5%20279.5-279.5%20385.5-103%20385.5%20103%20279.5%20279.5%20103%20385.5z%22%2F%3E%3C%2Fsvg%3E"); } }

.VWDcomp {
  background-color: #fff;

  h2 {
    font-weight: 300; }

  .comp_head .headline {
    h2.topline {
      font-size: 30px;
      font-size: 1.875em;
      color: #004494; }

    p.subline .separator {
      color: #e1e5e6; } }

  fieldset.bordered {
    border-color: #e1e5e6;
    border-width: 2px 0; }

  select, input.vwdFormControl {
    border-color: #e1e5e6;
    border-width: 2px;
    border-radius: .25em;
    color: $black;
    background-color: #fff; } }

.VWDcontent {
  input[disabled], select[disabled] {
    background-color: #f5f9fa; } }

.VWDcomp {
  .list_securities {
    .icon_pos, .icon_neutral, .icon_neg {
      background-size: 16px; }

    li:nth-child(odd) {
      background: #f5f9fa; } }

  table.cnttable.zebra {
    tbody tr:nth-child(odd) > *, &.reverse tbody tr:nth-child(even) > * {
      background: #f5f9fa; }

    &.accordion {
      tbody tr {
        &:nth-child(4n-2) > *, &:nth-child(4n-3) > * {
          background: #f5f9fa; } }

      &.reverse tbody tr {
        &:nth-child(4n) > *, &:nth-child(4n-1) > * {
          background: #f5f9fa; } } } }

  .list_securities li:nth-child(even) {
    background: #fff; }

  table.cnttable.zebra {
    tbody tr:nth-child(even) > *, &.reverse tbody tr:nth-child(odd) > * {
      background: #fff; }

    &.accordion {
      tbody tr {
        &:nth-child(4n) > *, &:nth-child(4n-1) > * {
          background: #fff; } }

      &.reverse tbody tr {
        &:nth-child(4n-2) > *, &:nth-child(4n-3) > * {
          background: #fff; } } }

    tbody td.high {
      background-color: #7e8688; } }

  th span.sortable {
    background-size: 14px;
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%237e8688%22%20id%3D%22fa-sort%22%20d%3D%22M1408%201088q0%2026-19%2045l-448%20448q-19%2019-45%2019t-45-19l-448-448q-19-19-19-45t19-45%2045-19h896q26%200%2045%2019t19%2045zm0-384q0%2026-19%2045t-45%2019h-896q-26%200-45-19t-19-45%2019-45l448-448q19-19%2045-19t45%2019l448%20448q19%2019%2019%2045z%22%2F%3E%3C%2Fsvg%3E");

    &.asc {
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%20-416%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%238f367f%22%20id%3D%22fa-sort-asc%22%20d%3D%22M1408%20704q0%2026-19%2045t-45%2019h-896q-26%200-45-19t-19-45%2019-45l448-448q19-19%2045-19t45%2019l448%20448q19%2019%2019%2045z%22%2F%3E%3C%2Fsvg%3E"); }

    &.desc {
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%20416%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%238f367f%22%20id%3D%22fa-sort-desc%22%20d%3D%22M1408%201088q0%2026-19%2045l-448%20448q-19%2019-45%2019t-45-19l-448-448q-19-19-19-45t19-45%2045-19h896q26%200%2045%2019t19%2045z%22%2F%3E%3C%2Fsvg%3E"); } }

  .list_vertical_checkandradio label.disabled * {
    color: #9da2a9; }

  .size_XS .list_vertical_checkandradio li, .size_XXS .list_vertical_checkandradio li {
    border-color: #e1e5e6; }

  .collapsable {
    background-color: #f5f9fa; }

  .selection-criteria-item-selector-header {
    border-color: #e1e5e6;
    background-color: #fff; }

  .selection-criteria-item-selected li {
    border-color: #e1e5e6; }

  .selection-criteria-item-selector-overlay {
    background-color: #fff; }

  .cnttable td {
    &.relation > span {
      padding: 0.35714em; }

    &.compare52week > span {
      padding: 0.35714em 0; }

    &.relation > span {
      > span:first-child > span {
        border-color: #7e8688; }

      &.pos > span + span {
        border-color: $green; }

      &.neg > span + span {
        border-color: $red; } }

    &.compare52week > span > span {
      background-color: #dadcdc;

      > span {
        background-color: #7e8688; } } }

  .pagination li {
    color: $black;

    &.first {
      background-size: 16px;
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%237e8688%22%20id%3D%22fa-fast-backward%22%20d%3D%22M1747%20141q19-19%2032-13t13%2032v1472q0%2026-13%2032t-32-13l-710-710q-9-9-13-19v710q0%2026-13%2032t-32-13l-710-710q-9-9-13-19v678q0%2026-19%2045t-45%2019h-128q-26%200-45-19t-19-45v-1408q0-26%2019-45t45-19h128q26%200%2045%2019t19%2045v678q4-11%2013-19l710-710q19-19%2032-13t13%2032v710q4-11%2013-19z%22%2F%3E%3C%2Fsvg%3E"); }

    &.prev {
      background-size: 16px;
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%237e8688%22%20id%3D%22fa-backward%22%20d%3D%22M1683%20141q19-19%2032-13t13%2032v1472q0%2026-13%2032t-32-13l-710-710q-8-9-13-19v710q0%2026-13%2032t-32-13l-710-710q-19-19-19-45t19-45l710-710q19-19%2032-13t13%2032v710q5-11%2013-19z%22%2F%3E%3C%2Fsvg%3E"); }

    &.next {
      background-size: 16px;
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%237e8688%22%20id%3D%22fa-forward%22%20d%3D%22M109%201651q-19%2019-32%2013t-13-32v-1472q0-26%2013-32t32%2013l710%20710q8%208%2013%2019v-710q0-26%2013-32t32%2013l710%20710q19%2019%2019%2045t-19%2045l-710%20710q-19%2019-32%2013t-13-32v-710q-5%2010-13%2019z%22%2F%3E%3C%2Fsvg%3E"); }

    &.last {
      background-size: 16px;
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%237e8688%22%20id%3D%22fa-fast-forward%22%20d%3D%22M45%201651q-19%2019-32%2013t-13-32v-1472q0-26%2013-32t32%2013l710%20710q8%208%2013%2019v-710q0-26%2013-32t32%2013l710%20710q8%208%2013%2019v-678q0-26%2019-45t45-19h128q26%200%2045%2019t19%2045v1408q0%2026-19%2045t-45%2019h-128q-26%200-45-19t-19-45v-678q-5%2010-13%2019l-710%20710q-19%2019-32%2013t-13-32v-710q-5%2010-13%2019z%22%2F%3E%3C%2Fsvg%3E"); }

    &.disabled {
      &.first {
        background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%23dbddde%22%20id%3D%22fa-fast-backward%22%20d%3D%22M1747%20141q19-19%2032-13t13%2032v1472q0%2026-13%2032t-32-13l-710-710q-9-9-13-19v710q0%2026-13%2032t-32-13l-710-710q-9-9-13-19v678q0%2026-19%2045t-45%2019h-128q-26%200-45-19t-19-45v-1408q0-26%2019-45t45-19h128q26%200%2045%2019t19%2045v678q4-11%2013-19l710-710q19-19%2032-13t13%2032v710q4-11%2013-19z%22%2F%3E%3C%2Fsvg%3E"); }

      &.prev {
        background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%23dbddde%22%20id%3D%22fa-backward%22%20d%3D%22M1683%20141q19-19%2032-13t13%2032v1472q0%2026-13%2032t-32-13l-710-710q-8-9-13-19v710q0%2026-13%2032t-32-13l-710-710q-19-19-19-45t19-45l710-710q19-19%2032-13t13%2032v710q5-11%2013-19z%22%2F%3E%3C%2Fsvg%3E"); }

      &.next {
        background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%23dbddde%22%20id%3D%22fa-forward%22%20d%3D%22M109%201651q-19%2019-32%2013t-13-32v-1472q0-26%2013-32t32%2013l710%20710q8%208%2013%2019v-710q0-26%2013-32t32%2013l710%20710q19%2019%2019%2045t-19%2045l-710%20710q-19%2019-32%2013t-13-32v-710q-5%2010-13%2019z%22%2F%3E%3C%2Fsvg%3E"); }

      &.last {
        background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%23dbddde%22%20id%3D%22fa-fast-forward%22%20d%3D%22M45%201651q-19%2019-32%2013t-13-32v-1472q0-26%2013-32t32%2013l710%20710q8%208%2013%2019v-710q0-26%2013-32t32%2013l710%20710q8%208%2013%2019v-678q0-26%2019-45t45-19h128q26%200%2045%2019t19%2045v1408q0%2026-19%2045t-45%2019h-128q-26%200-45-19t-19-45v-678q-5%2010-13%2019l-710%20710q-19%2019-32%2013t-13-32v-710q-5%2010-13%2019z%22%2F%3E%3C%2Fsvg%3E"); } } }

  .comp_head {
    .tsf_form .radio label span {
      background-size: 14px; }

    .tsf span {
      background-color: #fff; }

    .edg {
      padding: 0.3125em 0 0 0.3125em; }

    .action_button .svgicon {
      background-color: #f2f7f8;
      box-shadow: 0 1px 0 0 #cfd3d4;
      border-radius: 0.28571em;

      &.svg-close:hover {
        background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%238f367f%22%20id%3D%22fa-close%22%20d%3D%22M1490%201322q0%2040-28%2068l-136%20136q-28%2028-68%2028t-68-28l-294-294-294%20294q-28%2028-68%2028t-68-28l-136-136q-28-28-28-68t28-68l294-294-294-294q-28-28-28-68t28-68l136-136q28-28%2068-28t68%2028l294%20294%20294-294q28-28%2068-28t68%2028l136%20136q28%2028%2028%2068t-28%2068l-294%20294%20294%20294q28%2028%2028%2068z%22%2F%3E%3C%2Fsvg%3E"); }

      &.svg-info {
        background-size: 14px;
        background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%237e8688%22%20id%3D%22fa-info%22%20d%3D%22M1216%201344v128q0%2026-19%2045t-45%2019h-512q-26%200-45-19t-19-45v-128q0-26%2019-45t45-19h64v-384h-64q-26%200-45-19t-19-45v-128q0-26%2019-45t45-19h384q26%200%2045%2019t19%2045v576h64q26%200%2045%2019t19%2045zm-128-1152v192q0%2026-19%2045t-45%2019h-256q-26%200-45-19t-19-45v-192q0-26%2019-45t45-19h256q26%200%2045%2019t19%2045z%22%2F%3E%3C%2Fsvg%3E");

        &:hover {
          background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%238f367f%22%20id%3D%22fa-info%22%20d%3D%22M1216%201344v128q0%2026-19%2045t-45%2019h-512q-26%200-45-19t-19-45v-128q0-26%2019-45t45-19h64v-384h-64q-26%200-45-19t-19-45v-128q0-26%2019-45t45-19h384q26%200%2045%2019t19%2045v576h64q26%200%2045%2019t19%2045zm-128-1152v192q0%2026-19%2045t-45%2019h-256q-26%200-45-19t-19-45v-192q0-26%2019-45t45-19h256q26%200%2045%2019t19%2045z%22%2F%3E%3C%2Fsvg%3E"); } } } }

  .holder_searchinput .svgicon.svg-search {
    background-size: 16px;
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%237e8688%22%20id%3D%22fa-search%22%20d%3D%22M1216%20832q0-185-131.5-316.5t-316.5-131.5-316.5%20131.5-131.5%20316.5%20131.5%20316.5%20316.5%20131.5%20316.5-131.5%20131.5-316.5zm512%20832q0%2052-38%2090t-90%2038q-54%200-90-38l-343-342q-179%20124-399%20124-143%200-273.5-55.5t-225-150-150-225-55.5-273.5%2055.5-273.5%20150-225%20225-150%20273.5-55.5%20273.5%2055.5%20225%20150%20150%20225%2055.5%20273.5q0%20220-124%20399l343%20343q37%2037%2037%2090z%22%2F%3E%3C%2Fsvg%3E");

    &:hover {
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%238f367f%22%20id%3D%22fa-search%22%20d%3D%22M1216%20832q0-185-131.5-316.5t-316.5-131.5-316.5%20131.5-131.5%20316.5%20131.5%20316.5%20316.5%20131.5%20316.5-131.5%20131.5-316.5zm512%20832q0%2052-38%2090t-90%2038q-54%200-90-38l-343-342q-179%20124-399%20124-143%200-273.5-55.5t-225-150-150-225-55.5-273.5%2055.5-273.5%20150-225%20225-150%20273.5-55.5%20273.5%2055.5%20225%20150%20150%20225%2055.5%20273.5q0%20220-124%20399l343%20343q37%2037%2037%2090z%22%2F%3E%3C%2Fsvg%3E"); } }

  .list_horizontal ul {
    background-color: transparent;

    li {
      background-color: transparent;
      padding-top: 1em;
      padding-bottom: 0.875em;
      color: $black;
      border-bottom-color: #f5f9fa;

      &:hover {
        color: #46555f;
        background-color: transparent;
        border-bottom-color: #46555f; }

      &.current {
        color: #46555f;
        background-color: transparent;
        border-bottom-color: #46555f;
        font-weight: bold; } }

    color: $black;
    border-bottom-color: #f5f9fa; }

  .overflowed_scroller {
    .svgicon {
      background-color: #f2f7f8;
      box-shadow: 0 1px 0 0 #cfd3d4;
      border-radius: .28571em;
      background-size: 18px; }

    &.left .softener {
      background-image: -moz-linear-gradient(to right, #fff 2.5em, rgba(255, 255, 255, 0) 100%);
      background-image: -webkit-linear-gradient(to right, #fff 2.5em, rgba(255, 255, 255, 0) 100%);
      background-image: linear-gradient(to right, #fff 2.5em, rgba(255, 255, 255, 0) 100%); }

    &.right .softener {
      background-image: -moz-linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #fff 0.625em);
      background-image: -webkit-linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #fff 0.625em);
      background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #fff 0.625em); } }

  .list_chartnavi {
    background-color: #f5f9fa;

    li {
      color: $black;
      background-color: #fff;
      box-shadow: 0 1px 0 0 #e1e5e6;

      &:hover {
        color: #fff;
        background-color: #aab9c3;
        box-shadow: 0 1px 0 0 #aab9c3; }

      &.current {
        color: #fff;
        background-color: #aab9c3;
        box-shadow: 0 1px 0 0 #aab9c3;
        font-weight: normal; } } }

  .list_news li .date {
    color: #7e8688; }

  .quotebox {
    .prices .date {
      color: #7e8688; }

    .change {
      .icon_pos, .icon_neutral, .icon_neg {
        background-size: 16px; } }

    .addinfo span {
      border-right-color: #e1e5e6; } }

  .chart_sizer {
    background: #f5f9fa; }

  .indexmap {
    ul {
      background: #f5f9fa; }

    li {
      border-color: #fff; } }

  .head_searchsuggest .action {
    color: #8f367f; }

  .list_searchsuggest li span {
    background: #f5f9fa;
    border-bottom-color: #fff; }

  .linked {
    color: #8f367f; }

  .text p.date {
    color: #7e8688; }

  .infobox {
    background: #f5f9fa;

    * {
      color: #7e8688; } }

  .holder_carousel {
    background: #f5f9fa; }

  .svg-long-arrow-left {
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%237e8688%22%20id%3D%22fa-long-arrow-left%22%20d%3D%22M1792%20800v192q0%2014-9%2023t-23%209h-1248v224q0%2021-19%2029t-35-5l-384-350q-10-10-10-23%200-14%2010-24l384-354q16-14%2035-6%2019%209%2019%2029v224h1248q14%200%2023%209t9%2023z%22%2F%3E%3C%2Fsvg%3E"); }

  .overflowed_scroller.enabled .svg-long-arrow-left:hover {
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%238f367f%22%20id%3D%22fa-long-arrow-left%22%20d%3D%22M1792%20800v192q0%2014-9%2023t-23%209h-1248v224q0%2021-19%2029t-35-5l-384-350q-10-10-10-23%200-14%2010-24l384-354q16-14%2035-6%2019%209%2019%2029v224h1248q14%200%2023%209t9%2023z%22%2F%3E%3C%2Fsvg%3E"); }

  .svg-long-arrow-right {
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%237e8688%22%20id%3D%22fa-long-arrow-right%22%20d%3D%22M1728%20893q0%2014-10%2024l-384%20354q-16%2014-35%206-19-9-19-29v-224h-1248q-14%200-23-9t-9-23v-192q0-14%209-23t23-9h1248v-224q0-21%2019-29t35%205l384%20350q10%2010%2010%2023z%22%2F%3E%3C%2Fsvg%3E"); }

  .overflowed_scroller.enabled .svg-long-arrow-right:hover {
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%238f367f%22%20id%3D%22fa-long-arrow-right%22%20d%3D%22M1728%20893q0%2014-10%2024l-384%20354q-16%2014-35%206-19-9-19-29v-224h-1248q-14%200-23-9t-9-23v-192q0-14%209-23t23-9h1248v-224q0-21%2019-29t35%205l384%20350q10%2010%2010%2023z%22%2F%3E%3C%2Fsvg%3E"); }

  .svg-file-pdf-o {
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Crect%20x%3D%22128%22%20y%3D%220%22%20width%3D%221536%22%20height%3D%221792%22%20rx%3D%2264%22%20ry%3D%2264%22%20fill%3D%22white%22%2F%3E%3Cpath%20fill%3D%22%23333333%22%20d%3D%22M1596%20380q28%2028%2048%2076t20%2088v1152q0%2040-28%2068t-68%2028h-1344q-40%200-68-28t-28-68v-1600q0-40%2028-68t68-28h896q40%200%2088%2020t76%2048zm-444-244v376h376q-10-29-22-41l-313-313q-12-12-41-22zm384%201528v-1024h-416q-40%200-68-28t-28-68v-416h-768v1536h1280z%22%2F%3E%3Cpath%20fill%3D%22red%22%20d%3D%22M1000%201100q33%2026%2084%2056%2059-7%20117-7%20147%200%20177%2049%2016%2022%202%2052%200%201-1%202l-2%202v1q-6%2038-71%2038-48%200-115-20t-130-53q-221%2024-392%2083-153%20262-242%20262-15%200-28-7l-24-12q-1-1-6-5-10-10-6-36%209-40%2056-91.5t132-96.5q14-9%2023%206%202%202%202%204%2052-85%20107-197%2068-136%20104-262-24-82-30.5-159.5t6.5-127.5q11-40%2042-40h22q23%200%2035%2015%2018%2021%209%2068-2%206-4%208%201%203%201%208v30q-2%20123-14%20192%2055%20164%20146%20238zm-576%20411q52-24%20137-158-51%2040-87.5%2084t-49.5%2074zm398-920q-15%2042-2%20132%201-7%207-44%200-3%207-43%201-4%204-8-1-1-1-2t-.5-1.5-.5-1.5q-1-22-13-36%200%201-1%202v2zm-124%20661q135-54%20284-81-2-1-13-9.5t-16-13.5q-76-67-127-176-27%2086-83%20197-30%2056-45%2083zm646-16q-24-24-140-24%2076%2028%20124%2028%2014%200%2018-1%200-1-2-3z%22%2F%3E%3C%2Fsvg%3E"); }

  .svg-close {
    background-size: 14px;
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%237e8688%22%20id%3D%22fa-close%22%20d%3D%22M1490%201322q0%2040-28%2068l-136%20136q-28%2028-68%2028t-68-28l-294-294-294%20294q-28%2028-68%2028t-68-28l-136-136q-28-28-28-68t28-68l294-294-294-294q-28-28-28-68t28-68l136-136q28-28%2068-28t68%2028l294%20294%20294-294q28-28%2068-28t68%2028l136%20136q28%2028%2028%2068t-28%2068l-294%20294%20294%20294q28%2028%2028%2068z%22%2F%3E%3C%2Fsvg%3E"); }

  .svg-chevron-right {
    background-size: 14px;
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%237e8688%22%20id%3D%22fa-chevron-right%22%20d%3D%22M1363%20877l-742%20742q-19%2019-45%2019t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19%2045-19t45%2019l742%20742q19%2019%2019%2045t-19%2045z%22%2F%3E%3C%2Fsvg%3E"); }

  .svg-chevron-down {
    background-size: 14px;
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%237e8688%22%20id%3D%22fa-chevron-down%22%20d%3D%22M1683%20808l-742%20741q-19%2019-45%2019t-45-19l-742-741q-19-19-19-45.5t19-45.5l166-165q19-19%2045-19t45%2019l531%20531%20531-531q19-19%2045-19t45%2019l166%20165q19%2019%2019%2045.5t-19%2045.5z%22%2F%3E%3C%2Fsvg%3E"); }

  .chartchanger_table table.cnttable tbody tr.current td, .chartchanger_chart {
    background-color: #e4eff2; } }

.VWDcontent {
  .link {
    color: #8f367f;

    &:focus {
      color: #8f367f; } }

  .link_extern {
    background: url(../images/icons/icon__arrow-headline.svg) no-repeat;
    background-position: left center;
    background-size: 8px;
    color: #778892;
    padding-left: 15px; }

  .link_weiter {
    padding-right: 1.3125em;
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 16px;
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%238f367f%22%20id%3D%22fa-long-arrow-right%22%20d%3D%22M1728%20893q0%2014-10%2024l-384%20354q-16%2014-35%206-19-9-19-29v-224h-1248q-14%200-23-9t-9-23v-192q0-14%209-23t23-9h1248v-224q0-21%2019-29t35%205l384%20350q10%2010%2010%2023z%22%2F%3E%3C%2Fsvg%3E"); }

  .link:hover {
    text-decoration: underline; }

  label.toplabel {
    color: #7e8688; }

  .radio label {
    span {
      background-repeat: no-repeat;
      background-position: left center;
      background-size: 16px;
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ccircle%20cx%3D%22896%22%20cy%3D%22896%22%20r%3D%22700%22%20fill%3D%22white%22%2F%3E%3Cpath%20fill%3D%22%23e1e5e6%22%20id%3D%22fa-circle-o%22%20d%3D%22M896%20352q-148%200-273%2073t-198%20198-73%20273%2073%20273%20198%20198%20273%2073%20273-73%20198-198%2073-273-73-273-198-198-273-73zm768%20544q0%20209-103%20385.5t-279.5%20279.5-385.5%20103-385.5-103-279.5-279.5-103-385.5%20103-385.5%20279.5-279.5%20385.5-103%20385.5%20103%20279.5%20279.5%20103%20385.5z%22%2F%3E%3C%2Fsvg%3E"); }

    input {
      + span.pickable {
        color: rgba(33, 35, 38, 0.4); }

      &[type=radio]:checked + span {
        background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ccircle%20cx%3D%22896%22%20cy%3D%22896%22%20r%3D%22700%22%20fill%3D%22white%22%2F%3E%3Cpath%20fill%3D%22%238f367f%22%20id%3D%22fa-dot-circle-o%22%20d%3D%22M1152%20896q0%20106-75%20181t-181%2075-181-75-75-181%2075-181%20181-75%20181%2075%2075%20181zm-256-544q-148%200-273%2073t-198%20198-73%20273%2073%20273%20198%20198%20273%2073%20273-73%20198-198%2073-273-73-273-198-198-273-73zm768%20544q0%20209-103%20385.5t-279.5%20279.5-385.5%20103-385.5-103-279.5-279.5-103-385.5%20103-385.5%20279.5-279.5%20385.5-103%20385.5%20103%20279.5%20279.5%20103%20385.5z%22%2F%3E%3C%2Fsvg%3E"); } } }

  .checkbox label {
    span {
      background-repeat: no-repeat;
      background-position: left -1px center;
      background-size: 16px;
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%23e1e5e6%22%20id%3D%22fa-square-o%22%20d%3D%22M1312%20256h-832q-66%200-113%2047t-47%20113v832q0%2066%2047%20113t113%2047h832q66%200%20113-47t47-113v-832q0-66-47-113t-113-47zm288%20160v832q0%20119-84.5%20203.5t-203.5%2084.5h-832q-119%200-203.5-84.5t-84.5-203.5v-832q0-119%2084.5-203.5t203.5-84.5h832q119%200%20203.5%2084.5t84.5%20203.5z%22%2F%3E%3C%2Fsvg%3E"); }

    input[type=checkbox]:checked + span {
      background-position: left center;
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%238f367f%22%20id%3D%22fa-check-square-o%22%20d%3D%22M1472%20930v318q0%20119-84.5%20203.5t-203.5%2084.5h-832q-119%200-203.5-84.5t-84.5-203.5v-832q0-119%2084.5-203.5t203.5-84.5h832q63%200%20117%2025%2015%207%2018%2023%203%2017-9%2029l-49%2049q-10%2010-23%2010-3%200-9-2-23-6-45-6h-832q-66%200-113%2047t-47%20113v832q0%2066%2047%20113t113%2047h832q66%200%20113-47t47-113v-254q0-13%209-22l64-64q10-10%2023-10%206%200%2012%203%2020%208%2020%2029zm231-489l-814%20814q-24%2024-57%2024t-57-24l-430-430q-24-24-24-57t24-57l110-110q24-24%2057-24t57%2024l263%20263%20647-647q24-24%2057-24t57%2024l110%20110q24%2024%2024%2057t-24%2057z%22%2F%3E%3C%2Fsvg%3E"); } } }

.VWDcomp button.btn {
  color: $black;
  font-weight: bold;

  * {
    color: $black;
    font-weight: bold; }

  font-size: 16px;
  font-size: 1em;
  padding: 1em 1.5em;
  border: none;
  border-color: transparent;
  box-shadow: 0 1px 0 0 #c8cfd1;
  background-color: #e1e5e6;

  &.small {
    font-weight: normal;

    * {
      font-weight: normal; }

    font-size: 14px;
    font-size: .875em;
    padding: 0.64286em 1.14286em; } }

.VWDcontent button {
  &.btn-primary {
    color: #fff;
    font-weight: bold;

    * {
      color: #fff;
      font-weight: bold; }

    border-color: transparent;
    box-shadow: 0 1px 0 0 #002859;
    background-color: #004ca1;
    background-image: -moz-linear-gradient(to bottom, #004ca1, #003c87);
    background-image: -webkit-linear-gradient(to bottom, #004ca1, #003c87);
    background-image: linear-gradient(to bottom, #004ca1, #003c87); }

  &.small.btn-primary {
    font-weight: normal;

    * {
      font-weight: normal; } }

  &.btn-primary .svgicon.svg-user-plus {
    background-position: left center;
    background-size: 16px;
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%23fff%22%20id%3D%22fa-user-plus%22%20d%3D%22M576%20896q-159%200-271.5-112.5t-112.5-271.5%20112.5-271.5%20271.5-112.5%20271.5%20112.5%20112.5%20271.5-112.5%20271.5-271.5%20112.5zm960%20128h352q13%200%2022.5%209.5t9.5%2022.5v192q0%2013-9.5%2022.5t-22.5%209.5h-352v352q0%2013-9.5%2022.5t-22.5%209.5h-192q-13%200-22.5-9.5t-9.5-22.5v-352h-352q-13%200-22.5-9.5t-9.5-22.5v-192q0-13%209.5-22.5t22.5-9.5h352v-352q0-13%209.5-22.5t22.5-9.5h192q13%200%2022.5%209.5t9.5%2022.5v352zm-736%20224q0%2052%2038%2090t90%2038h256v238q-68%2050-171%2050h-874q-121%200-194-69t-73-190q0-53%203.5-103.5t14-109%2026.5-108.5%2043-97.5%2062-81%2085.5-53.5%20111.5-20q19%200%2039%2017%2079%2061%20154.5%2091.5t164.5%2030.5%20164.5-30.5%20154.5-91.5q20-17%2039-17%20132%200%20217%2096h-223q-52%200-90%2038t-38%2090v192z%22%2F%3E%3C%2Fsvg%3E"); }

  &.small.btn-primary .svgicon.svg-user-plus {
    background-size: 14px; }

  &.btn-reset {
    color: $black;

    * {
      color: $black; }

    border-color: transparent;
    box-shadow: 0 1px 0 0 #c8cfd1;
    background-color: #e1e5e6; }

  &.svgicon {
    &.svg-lock {
      background-size: 22px;
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%237e8688%22%20id%3D%22fa-lock%22%20d%3D%22M640%20768h512v-192q0-106-75-181t-181-75-181%2075-75%20181v192zm832%2096v576q0%2040-28%2068t-68%2028h-960q-40%200-68-28t-28-68v-576q0-40%2028-68t68-28h32v-192q0-184%20132-316t316-132%20316%20132%20132%20316v192h32q40%200%2068%2028t28%2068z%22%2F%3E%3C%2Fsvg%3E");

      &:hover, &.active {
        background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%238f367f%22%20id%3D%22fa-lock%22%20d%3D%22M640%20768h512v-192q0-106-75-181t-181-75-181%2075-75%20181v192zm832%2096v576q0%2040-28%2068t-68%2028h-960q-40%200-68-28t-28-68v-576q0-40%2028-68t68-28h32v-192q0-184%20132-316t316-132%20316%20132%20132%20316v192h32q40%200%2068%2028t28%2068z%22%2F%3E%3C%2Fsvg%3E"); } }

    &.svg-navicon {
      background-size: 22px;
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%237e8688%22%20id%3D%22fa-navicon%22%20d%3D%22M1664%201344v128q0%2026-19%2045t-45%2019h-1408q-26%200-45-19t-19-45v-128q0-26%2019-45t45-19h1408q26%200%2045%2019t19%2045zm0-512v128q0%2026-19%2045t-45%2019h-1408q-26%200-45-19t-19-45v-128q0-26%2019-45t45-19h1408q26%200%2045%2019t19%2045zm0-512v128q0%2026-19%2045t-45%2019h-1408q-26%200-45-19t-19-45v-128q0-26%2019-45t45-19h1408q26%200%2045%2019t19%2045z%22%2F%3E%3C%2Fsvg%3E");

      &:hover, &.active {
        background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%238f367f%22%20id%3D%22fa-navicon%22%20d%3D%22M1664%201344v128q0%2026-19%2045t-45%2019h-1408q-26%200-45-19t-19-45v-128q0-26%2019-45t45-19h1408q26%200%2045%2019t19%2045zm0-512v128q0%2026-19%2045t-45%2019h-1408q-26%200-45-19t-19-45v-128q0-26%2019-45t45-19h1408q26%200%2045%2019t19%2045zm0-512v128q0%2026-19%2045t-45%2019h-1408q-26%200-45-19t-19-45v-128q0-26%2019-45t45-19h1408q26%200%2045%2019t19%2045z%22%2F%3E%3C%2Fsvg%3E"); } } } }

.VWDcomp {
  .cellicon.svgicon {
    width: 14px;
    background-size: 14px; }

  .highlight {
    background-color: #e3e3e3; }

  table.cnttable.zebra tbody {
    tr.highlight td, td.highlight {
      background-color: #e3e3e3; } }

  .highlight * {
    color: $black; }

  table.cnttable.zebra tbody {
    tr.highlight td, td.highlight {
      color: $black;

      * {
        color: $black; } } }

  .feri_rating li {
    color: #7e8688; }

  .list_rating li {
    color: #7e8688;

    .fvwd-dia {
      color: #c0c4c5; } }

  .feri_rating li.current {
    color: $black; }

  .list_rating li.high {
    color: $black;

    * {
      color: $black; } }

  .analystscheck {
    .bar {
      background-color: #dadcdc; }

    .bar-pos {
      background-color: $green; }

    .bar-neg {
      background-color: $red; }

    .bar-neutral, .bar-percent {
      background-color: #7e8688; }

    .bar-legend {
      .holder span::after {
        color: #999fa0; }

      .valuesign {
        background-repeat: no-repeat;
        background-position: center;
        background-size: 16px;
        background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%22120%20120%201552%201552%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22#{$green-ec}%22%20id%3D%22fa-play%22%20d%3D%22M1576%20927l-1328%20738q-23%2013-39.5%203t-16.5-36v-1472q0-26%2016.5-36t39.5%203l1328%20738q23%2013%2023%2031t-23%2031z%22%2F%3E%3C%2Fsvg%3E"); } } } }

.VWDcontent.extern {
  font-size: 16px; }

html.VWD {
  font-size: 16px;

  body {
    background-color: #f4f6f6;
    background-image: -moz-linear-gradient(to bottom, #f4f6f6, #ced4d6);
    background-image: -webkit-linear-gradient(to bottom, #f4f6f6, #ced4d6);
    background-image: linear-gradient(to bottom, #f4f6f6, #ced4d6);
    background-image: url("../img/bg_body.jpg");
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover; }

  #backgroundShape {
    background-image: -moz-linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
    background-image: -webkit-linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0)); }

  #contentOverlay {
    background: rgba(0, 0, 0, 0.48); } }

.VWDcontent {
  .btn {
    border-radius: 0.25rem; }

  #topBar .navbar {
    background: #fff;

    .action button {
      background-color: #fff; } }

  #topHeadline h1 {
    color: $black;

    span {
      color: $black; } }

  #menuFlyout {
    background: #fff;

    .list_menu {
      > ul > li {
        border-bottom-color: #e1e5e6; }

      a {
        color: $black; } }

    .list_menu_extend a {
      color: $black; } }

  #topFlyout {
    background: #fff; }

  footer {
    background: rgba(33, 35, 38, 0.8);

    .header {
      background: rgba(0, 0, 0, 0.48);

      * {
        color: #fff; }

      .item {
        &.history {
          background-repeat: no-repeat;
          background-position: -1px center;
          background-size: 16px;
          background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%23fff%22%20id%3D%22fa-plus-square%22%20d%3D%22M1408%20960v-128q0-26-19-45t-45-19h-320v-320q0-26-19-45t-45-19h-128q-26%200-45%2019t-19%2045v320h-320q-26%200-45%2019t-19%2045v128q0%2026%2019%2045t45%2019h320v320q0%2026%2019%2045t45%2019h128q26%200%2045-19t19-45v-320h320q26%200%2045-19t19-45zm256-544v960q0%20119-84.5%20203.5t-203.5%2084.5h-960q-119%200-203.5-84.5t-84.5-203.5v-960q0-119%2084.5-203.5t203.5-84.5h960q119%200%20203.5%2084.5t84.5%20203.5z%22%2F%3E%3C%2Fsvg%3E");

          &.opened {
            background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%23fff%22%20id%3D%22fa-minus-square%22%20d%3D%22M1408%20960v-128q0-26-19-45t-45-19h-896q-26%200-45%2019t-19%2045v128q0%2026%2019%2045t45%2019h896q26%200%2045-19t19-45zm256-544v960q0%20119-84.5%20203.5t-203.5%2084.5h-960q-119%200-203.5-84.5t-84.5-203.5v-960q0-119%2084.5-203.5t203.5-84.5h960q119%200%20203.5%2084.5t84.5%20203.5z%22%2F%3E%3C%2Fsvg%3E"); } }

        &.history_more {
          background-repeat: no-repeat;
          background-position: right center;
          background-size: 16px;
          background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%23fff%22%20id%3D%22fa-caret-up%22%20d%3D%22M1408%201216q0%2026-19%2045t-45%2019h-896q-26%200-45-19t-19-45%2019-45l448-448q19-19%2045-19t45%2019l448%20448q19%2019%2019%2045z%22%2F%3E%3C%2Fsvg%3E");

          &.opened {
            background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%23fff%22%20id%3D%22fa-caret-down%22%20d%3D%22M1408%20704q0%2026-19%2045l-448%20448q-19%2019-45%2019t-45-19l-448-448q-19-19-19-45t19-45%2045-19h896q26%200%2045%2019t19%2045z%22%2F%3E%3C%2Fsvg%3E"); } } } }

    .content .list_history_items_hor li {
      > span {
        background: #f5f9fa;
        border-radius: 0.25rem; }

      span .svgicon {
        background-size: 16px;
        background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%237e8688%22%20id%3D%22fa-close%22%20d%3D%22M1490%201322q0%2040-28%2068l-136%20136q-28%2028-68%2028t-68-28l-294-294-294%20294q-28%2028-68%2028t-68-28l-136-136q-28-28-28-68t28-68l294-294-294-294q-28-28-28-68t28-68l136-136q28-28%2068-28t68%2028l294%20294%20294-294q28-28%2068-28t68%2028l136%20136q28%2028%2028%2068t-28%2068l-294%20294%20294%20294q28%2028%2028%2068z%22%2F%3E%3C%2Fsvg%3E"); } }

    #historyFlyout {
      background: rgba(33, 35, 38, 0.8);

      .inner {
        background: rgba(0, 0, 0, 0.48); }

      .clear_history {
        color: #fff;

        &.svgicon.svg-trash {
          background-position: left center;
          background-size: 16px;
          background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%23fff%22%20id%3D%22fa-trash%22%20d%3D%22M704%201376v-704q0-14-9-23t-23-9h-64q-14%200-23%209t-9%2023v704q0%2014%209%2023t23%209h64q14%200%2023-9t9-23zm256%200v-704q0-14-9-23t-23-9h-64q-14%200-23%209t-9%2023v704q0%2014%209%2023t23%209h64q14%200%2023-9t9-23zm256%200v-704q0-14-9-23t-23-9h-64q-14%200-23%209t-9%2023v704q0%2014%209%2023t23%209h64q14%200%2023-9t9-23zm-544-992h448l-48-117q-7-9-17-11h-317q-10%202-17%2011zm928%2032v64q0%2014-9%2023t-23%209h-96v948q0%2083-47%20143.5t-113%2060.5h-832q-66%200-113-58.5t-47-141.5v-952h-96q-14%200-23-9t-9-23v-64q0-14%209-23t23-9h309l70-167q15-37%2054-63t79-26h320q40%200%2079%2026t54%2063l70%20167h309q14%200%2023%209t9%2023z%22%2F%3E%3C%2Fsvg%3E"); } }

      .list_history_items_ver li {
        border-top-color: #4b4c4c;

        span {
          span {
            color: #fff; }

          .svgicon {
            background-size: 16px;
            background-image: url("data:image/svg+xml,%3Csvg%20width%3D%221%22%20height%3D%221%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%23fff%22%20id%3D%22fa-close%22%20d%3D%22M1490%201322q0%2040-28%2068l-136%20136q-28%2028-68%2028t-68-28l-294-294-294%20294q-28%2028-68%2028t-68-28l-136-136q-28-28-28-68t28-68l294-294-294-294q-28-28-28-68t28-68l136-136q28-28%2068-28t68%2028l294%20294%20294-294q28-28%2068-28t68%2028l136%20136q28%2028%2028%2068t-28%2068l-294%20294%20294%20294q28%2028%2028%2068z%22%2F%3E%3C%2Fsvg%3E"); } } } } } }

@media (max-width: 767px) {
  .VWDcontent #topHeadline h1 {
    color: #fff;

    span {
      color: #fff; } } }

@media (min-width: 768px) {
  .VWDcontent #topHeadline {
    background: #fff; } }

@media (min-width: 768px) and (max-width: 1024px) {
  html.VWD {
    font-size: 15px; } }





//custom tm


.VWDcontent {
  font-family: IBM Plex Sans, Arial, Helvetica, sans-serif !important;
  visibility: hidden; }

.VWDcomp>.comp_sizer {
  position:relative {}
  margin: 0 0 40px 0 !important; }

.VWDcomp .comp_head:last-child {
  margin-bottom: 1.25em; }

.VWDcomp .comp_head .headline h2.topline {
  color: #46555f;
  display: block;
  font-size: 1.9rem;
  font-weight: bold;
  line-height: 3.6rem;
  text-transform: uppercase; }

.VWDcomp #USFlastSecurity .comp_head .headline h2.topline {
  font-size: 1.3rem;
  color: #697882; }


.VWDcomp select,
.VWDcomp input.vwdFormControl {
  appearance: none;
  border: 1px solid $lighter-grey;
  border-radius: 5px;
  color: $black;
  font-size: 1.5rem;
  height: 42px;
  padding: 0 15px; }

.VWDcomp select::placeholder,
.VWDcomp input.vwdFormControl::placeholder {
  color: $dark-grey;
  opacity: 1; }

.VWDcomp select:hover,
.VWDcomp input.vwdFormControl:hover {
  border: 1px solid $dark-grey; }

.VWDcomp select:focus,
.VWDcomp input.vwdFormControl:focus {
  border-color: $dark-grey;
  box-shadow: 0px 0px 4px $dark-grey; }

.VWDcomp select {
  background-image: url("data:image/svg+xml,%3Csvg width='22' height='13' viewBox='0 0 22 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.9999 12.0999L0.899902 1.9999L1.99991 0.899902L10.9999 9.8999L19.9999 0.899902L21.0999 1.9999L10.9999 12.0999Z' fill='%2346555F'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: center right 15px;
  cursor: pointer; }

.VWDcomp select option {
  color: $dark-grey; }

.VWDcomp .holder_searchinput .svgicon.svg-search:hover {
  background-color: $red;
  background-image: url(../images/icons/icon__search.svg); }

.VWDcomp .holder_searchinput .svgicon.svg-search {
  background-size: 20px 20px;
  background-image: url(../images/icons/icon__search--grey.svg); }

.VWDcomp .holder_searchinput .svgicon {
  position: absolute;
  top: 0;
  right: 0;
  width: 42px;
  height: 100%;
  cursor: pointer;
  border-radius: 5px; }


/* BREAKPOINTS */

// XS
@media screen and (max-width: $max-xs ) {
  .VWDcomp .comp_head .headline h2.topline {
    background: #aab9c3;
    color: #46555f;
    font-size: 1.6rem;
    margin: 0 -10px;
    padding: 0 10px; }

  .VWDcomp #USFlastSecurity .comp_head .headline h2.topline {
    background: none;
    color: #697882;
    font-size: 1.3rem;
    margin: 0;
    padding: 0; } }


// SM, MD and LG
@media screen and (min-width: $min-sm ) {
  .VWDcomp .comp_head .headline h2.topline {
    line-height: 1; } }


// MD
@media screen and (min-width: $min-md ) and (max-width: $max-md ) {
  .VWDcomp .comp_head .headline h2.topline {
    font-size: 2rem; } }


// LG
@media screen and (min-width: $min-lg ) {
  .VWDcomp .comp_head .headline h2.topline {
    font-size: 2.2rem; }

  .VWDcomp select,
  .VWDcomp input.vwdFormControl {
    font-size: 1.7rem; } }


