.cat {
  position: relative;
  text-transform: uppercase;

  ul {
    display: flex;

    li {
      color: $darker-grey;
      display: inline-flex;
      font-size: 1.3rem;
      font-weight: $bold;
      margin-bottom: 5px;
      transition: color 0.15s;

      &.active {
        color: $red; }

      &:hover {
        a {
          color: $red; } }

      &:last-of-type {
        &:after {
          border-right: none; } }

      &:after {
        border-right: 1px solid $red;
        content: '';
        margin: 0 10px;
        transition: opacity 0.15s; } } } }




/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
  .cat {
    margin-bottom: 20px; } }

// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.cat



//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.cat


// LG
@media screen and (min-width: $min-lg) {
  .cat {
    .title--dark {
      font-size: 2.4rem;
      line-height: 1.2; }

    ul {
      li {
        font-size: 1.5rem;
        line-height: 1.1; } } } }
