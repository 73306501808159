.feedback__popup__header {
  display: flex;
  justify-content: space-between; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.feedback__popup__header


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.feedback__popup__header


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.feedback__popup__header


// LG
//@media screen and (min-width: $min-lg )
//.feedback__popup__header
