.author {
  background: #EDF0F3;
  padding-top: 20px;
  margin-top: 10px;
  display: flex;
  border: 0; }

.author__title {
  display: block;
  margin-bottom: 15px;
  color: #46555f;
  font-size: 2.4rem;
  font-weight: bold;
  text-transform: uppercase; }

.author__txt-wrapper {
  padding: 0 10px 20px 0;
  width: calc(100% - 200px); }


/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs ) {
  .author {
    background: white;
    margin-left: -10px;
    margin-right: -10px;
    margin-top: 0;
    flex-direction: column;
    padding: 0;

    .author__txt-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      width: 100%;
      order: 2;
      padding: 30px 10px 5px 10px; } }

  .author__title {
    color: #46555f;
    justify-content: center;
    font-size: 1.8rem; } }


// SM
@media screen and (min-width: $min-sm ) and (max-width: $max-sm ) {
  .author {
    margin-left: -8px;
    margin-right: -8px;
    padding-left: 8px;
    padding-right: 8px;

    .row {
      margin: 0; } }

  .author__title {
    font-size: 2.2rem; }

  .author__txt-wrapper {
    width: calc(100% - 180px); } }

// MD and LG
@media screen and (min-width: $min-md ) {
  .author {
    padding-left: 16.667%;
    padding-right: 16.667%; }

  .author__txt-wrapper {
    width: calc(100% - 180px); } }


// MD
@media screen and (min-width: $min-md ) and (max-width: $max-md ) {
  .author__title {
    font-size: 2.2rem; } }

// LG
// @media screen and (min-width: $min-lg )

