.stock__icon {
  padding: 0 6px;

  &:before {
    flex-shrink: 0;
    height: 12px;
    width: 12px; } }

.stock__element--up {
  .stock__icon {
    &:before {
      @include background-image(arrow-stock,$green-ec);
      background-repeat: no-repeat;
 } } }      //background: url(../images/icons/icon__stock-up.svg) no-repeat

.stock__element--down {
  .stock__icon {
    &:before {
      @include background-image(arrow-stock,$red-ec);
      background-repeat: no-repeat;
      transform: rotate(180deg);
 } } }      //background: url(../images/icons/icon__stock-down.svg) no-repeat


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.stock__icon


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.stock__icon


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.stock__icon


// LG
//@media screen and (min-width: $min-lg )
//.stock__icon
