.theme__list {
	font-size: 1.5rem;
	margin-top: -8px;

	a {
		@include border(b);
		break-inside: avoid-column;
		display: block;
		@include ellipsis();
		line-height: 2.7rem; } }

/* BREAKPOINTS */


// XS > 550px
@media screen and (min-width: 550px) and (max-width: $max-sm) {
	.theme__list {
		columns: 2; } }

// SM, MD and LG
@media screen and (min-width: $min-sm) {
	.theme__list {
		columns: 4;
		font-size: 1.7rem;
		padding-bottom: 38px;
		margin-top: -4px; } }
