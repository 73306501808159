.newsletter__valid {
	@include border(b);
	display: flex;
	flex-wrap: wrap;
	padding: 5px 0 30px;

	> * {
		margin-right: 10px;

		&:last-child {
			margin-right: 0; } }

	input {
		@include border($color: $lighter-grey);
		flex: 1 0 auto;
		max-width: calc(100% - 120px);
		height: 42px;
		vertical-align: top;

		&::-webkit-input-placeholder {
			color: $dark-grey; }
		&:-moz-placeholder {
			color: $dark-grey;
			opacity: 1; }
		&::-moz-placeholder {
			color: $dark-grey;
			opacity: 1; }
		&:-ms-input-placeholder {
			color: $dark-grey; } } }

/* BREAKPOINTS */


// SM, MD and LG
@media screen and (min-width: $min-sm) {
	.newsletter__valid {
		padding: 0 0 30px 0;

		input {
			flex: 0 0 50%; } } }

// MD and LG
@media screen and (min-width: $min-sm) {
	.newsletter__valid {
		input {
			font-size: 1.7rem;
			width: 50%; } } }

