.list__block {
  font-size: 1.5rem;
  padding: 14px 0 4px;

  li {
    @include border(b);
    //line-height: 18px
    padding: 5px 0 5px 8px; } }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.list__block


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.list__block


// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .list__block {
    break-inside: avoid-column;
    padding: 6px 0 23px; } }


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.list__block


// LG
@media screen and (min-width: $min-lg ) {
  .list__block {
    font-size: 1.7rem;

    //li
 } }    //line-height: 23px
