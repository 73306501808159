.sport--live {
  .sport__score {
    color: $red; } }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.sport--live


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.sport--live


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.sport--live


// LG
//@media screen and (min-width: $min-lg )
//.sport--live
