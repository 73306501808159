.list--numbered {
  counter-reset: teaser;

  .teaser {
    counter-increment: section;
    margin-bottom: 20px;

    a {
      display: flex;
      width: 100%; } }

  .teaser__headline {
    font-size: 1.8rem; }

  .teaser__media {
    font-size: 1.5rem;
    margin-bottom: auto;
    width: auto;

    &:before {
      align-self: flex-start;
      color: $dark-grey;
      content: counter(section);
      display: block;
      font-weight: $bold;
      width: 18px; }

    picture {
      min-width: auto; } }

  .teaser__kicker {
    display: block;
    @include ellipsis(); } }


/* BREAKPOINTS */


// XS and SM
@media screen and (max-width: $max-sm ) {
  .list--numbered {
    .teaser__media {
      &:before {
        margin-right: 16px;

        @media (inverted-colors: inverted) {
          background: $inv-red; } }

      img {
        display: none; } } } }


// XS
@media screen and (max-width: $max-xs ) {
  .list--numbered {
    .teaser {
      &:nth-child(n+5) {
        margin: 0; }

      &:nth-child(n+6) {
        display: none; }

      .teaser__content {
        width: calc(100% - 27px); } }

    .teaser__media {
      &:before {
        width: 10px; } } } }


// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .list--numbered {
    padding-bottom: 20px;

    .teaser {
      break-inside: avoid-column; }

    ul {
      // flex doesn't work here, as we need to display vertically the items,
      // and flex can do this only if we have a fix height, what we do not have here
      columns: 2; } } }


// SM
@media screen and (min-width: $min-sm) and (max-width: $max-sm) {
  .list--numbered {
    .teaser {
      margin-bottom: 15px; }

    .teaser__content {
      height: 60px;
      overflow: hidden;
      width: calc(100% - 34px); }

    .teaser__media {
      &:before {
        font-size: 1.5rem; } } } }


// SM and MD
@media screen and (min-width: $min-sm) and (max-width: $max-md) {
  .list--numbered {
    .teaser__media {
      &:before {
        margin-right: 16px; } } } }


// MD and LG
@media screen and (min-width: $min-md) {
  .list--numbered {

    .teaser__kicker {
      margin-top: -3px; }

    .teaser__media {
      margin-right: 15px; }

    .teaser__content {
      display: flex;
      flex-direction: column;
      justify-content: center; } } }


// MD
@media screen and (min-width: $min-md) and (max-width: $max-md) {
  .list--numbered {

    .teaser__content {
      width: calc(100% - 203px); }

    .teaser__media {
      img {
        height: 87px;
        width: 154px; } } } }

// LG
@media screen and (min-width: $min-lg) {
  .list--numbered {
    padding-bottom: 10px;

    .teaser__content {
      width: calc(100% - 273px); }

    .teaser__headline {
      font-size: 2.2rem; }

    .teaser__media {
      img {
        height: 124px;
        width: 220px; }

      &:before {
        font-size: 1.7rem;
        margin-right: 20px; } } } }
