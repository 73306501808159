.space {
  position: relative;

  &.space--1_1 {
    padding-bottom: 100%; }

  &.space--4_3 {
    padding-bottom: 75%; }

  &.space--16_9 {
    padding-bottom: 56.25%; }

  &.space--3_4 {
    padding-bottom: 133.33%; }

  &.space--9_16 {
    padding-bottom: 177.77%; }

  &.space--showheroes {
    padding-bottom: calc(56.25% + 45px); }

  .space__strech {
    height: 100%!important; //muss element-style datawrapper überschreiben
    overflow: hidden;
    position: absolute;
    width: 100%; } }



/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
  .space {
    &.space--1_1-xs {
      padding-bottom: 100%; }

    &.space--4_3-xs {
      padding-bottom: 75%; }

    &.space--16_9-xs {
      padding-bottom: 56.25%; }

    &.space--3_4-xs {
      padding-bottom: 133.33%; }

    &.space--9_16-xs {
      padding-bottom: 177.77%; } } }


// SM
//@media screen and (min-width: $min-sm) and (max-width: $max-sm)



// MD
//@media screen and (min-width: $min-md) and (max-width: $max-md)



// LG
//@media screen and (min-width: $min-lg)
