.header__nav__lvl4 {
  display: none; }


/* BREAKPOINTS */


// MD and LG
@media screen and (min-width: $min-md) {
  .header__nav__lvl4 {
    background: white;
    @include border(r);
    height: 100%;
    left: 100%;
    padding-left: $gutter-md-real;
    position: absolute;
    top: 0;
    width: 254px; } }

// LG
@media screen and (min-width: $min-lg) {
  .header__nav__lvl4 {
    min-height: 344px;
    padding-left: $gutter-lg-real;
    width: 254px; } }

