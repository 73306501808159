.gallery--popup {
  left: 0;
  height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $popup;

  figcaption {
    position: absolute;
    width: 100vw; } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.gallery--popup


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.gallery--popup


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.gallery--popup


// LG
//@media screen and (min-width: $min-lg )
//.gallery--popup
