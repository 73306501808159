.adviser {
    background-color: $darker-grey;
    border-top: 2px solid #ffffff;
    color: #ffffff;
    display: flex; }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//  .adviser

// SM
@media screen and (max-width: $max-sm ) {
    .adviser {
        flex-direction: column; } }

// SM and MD
//@media screen and (max-width: $max-md )
//  .adviser

// SM
//@media screen and (min-width: $min-lg )
//  .adviser


