.feedback__headline {
  display: block;
  font-weight: $bold;
  margin-bottom: 15px; }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.feedback__headline


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.feedback__headline


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.feedback__headline


// LG
//@media screen and (min-width: $min-lg )
//.feedback__headline
