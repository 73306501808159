.teaser--highlight {
  padding-bottom: 20px;

  .teaser__text {
    display: block; } }

/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
  .teaser--highlight {
    .teaser__media {
      margin: 0 (-$gutter-xs-real) 7px; }

    .title {
      margin-bottom: 0; } } }

// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.teaser--highlight


// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .teaser--highlight {
    .title--dark {
      font-size: 2.2rem; }

    + .col--x2 {
      margin-top: -10px; } } }

// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.teaser--highlight


// MD and LG
@media screen and (min-width: $min-md) {
  .teaser--highlight {
    margin-bottom: 30px; } }

// LG
//@media screen and (min-width: $min-lg)
//.teaser--highlight

