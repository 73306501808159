.teaser__content {
  width: 100%; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.teaser__content


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.teaser__content


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.teaser__content


// LG
//@media screen and (min-width: $min-lg )
//.teaser__content
