.overlay {
  align-items: center;
  background: $black; //rgba(0, 0, 0, 0.5)
  bottom: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $popup; // 1000 is sticky nav

  img {
    animation: scale-overlay 0.2s ease-out;
    max-height: 100vh;
    max-width: 100vw;
    width: auto; } }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.overlay


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.overlay


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.overlay


// LG
//@media screen and (min-width: $min-lg )
//.overlay

