.article__links {
  display: block;
  margin-top: 13px;
  @extend .teaser__related--link;

  li {
    align-items: center;
    // To display all the links, not only one
    display: flex !important; } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.article__links


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.article__links


// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .article__links {
    margin-top: 20px; } }

//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.article__links


// LG
//@media screen and (min-width: $min-lg )
//.article__links
