.teaser__adviser {
    background-color: #41648c;
    border-top: 2px solid $blue;
    color: #ffffff;

    .teaser__adviser__links {
        margin-bottom: 16px;

        a {
            border-bottom: 1px solid white;
            color: white;
            display: block;
            margin-bottom: 8px;
            padding-bottom: 8px;

            > div {
                color: $light-grey;
                display: flex;
                font-size: 1.6rem;
                font-weight: bold;

                &:before {
                    background: url(../images/icons/icon__play.svg) no-repeat;
                    content: "";
                    flex-shrink: 0;
                    height: 20px;
                    margin-right: 10px;
                    position: relative;
                    top: 2px;
                    width: 20px; }

                span {
                    color: #ffffff;
                    display: block;
                    font-size: 1.7rem;
                    font-weight: bold; } } } }

    .teaser__adviser__more {
        color: #ffffff;
        font-size: 1.2rem;
        text-transform: uppercase;

        span {
            margin-right: 10px; }

        a {
            align-items: center;
            border: none;
            display: inline-flex;
            margin-right: 10px;

            &:before {
                background: url(../images/icons/icon__arrow-white.svg) no-repeat;
                background-position-y: -1px;
                content: "";
                height: 10px;
                margin-right: 2px;
                width: 10px; } } }

    .teaser__adviser__supporter {
        align-items: center;
        display: flex;
        font-size: 1.2rem;
        justify-content: flex-end;

        img {
            height: 26px;
            margin-left: 10px; } }

    .title--smal {
        color: #ffffff; } }

/* BREAKPOINTS */

// XS
@media screen and (max-width: $max-xs) {
    .teaser__adviser {
        .teaser__adviser__links {
            a {
                > div {
                    font-size: 1.4rem; } } }

        .teaser__adviser__more {
            span {
                display: flex;
                margin-bottom: 5px; } } } }

// Min SM
@media screen and (min-width: $min-sm ) {
 .teaser__adviser {
     .teaser__adviser__more {
         display: flex; } } }

// MD
@media screen and (max-width: $max-md) {
    .teaser__adviser {
        padding: 10px;

        .teaser__adviser__links {
            font-size: 1.5rem; }

        .teaser__adviser__more {
            padding-bottom: 10px; }

        .teaser__adviser__supporter {
            padding-bottom: 10px; } } }

// LG
@media screen and (min-width: $min-lg) {
    .teaser__adviser {
        padding: 16px;

        .teaser__adviser__links {
            font-size: 1.7rem;

            a {
                > div {
                    font-size: 1.9rem;

                    &:before {
                        height: 25px;
                        width: 25px; }

                    span {
                        font-size: 2rem; } } } }

        .teaser__adviser__more {
            font-size: 1.4rem;
            padding-bottom: 16px; }

        .teaser__adviser__supporter {
            padding-bottom: 16px; } } }
