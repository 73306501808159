.article__text {
  font-size: 1.7rem;
  line-height: 1.4;

  > * {
    margin-bottom: 13px; }

  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6 {
    font-size: 1.9rem; }

  p {
    // Links are in p tags, we search for a tags in p
    // to avoid a tags from inline widgets to be red too
    a {
      text-decoration: underline;
      text-decoration-color: $black;

      &:hover {
        color: $dark-grey;
        text-decoration: none; } } }

  ol,
  ul {
    counter-reset: ol-counter;
    list-style-type: none; }

  li {
    line-height: 1.4;
    margin-bottom: 8px;
    padding-left: 16px;

    &:before {
      color: $red;
      float: left;
      margin: 2px 10px 0 -16px; } }

  ol {
    li {
      &:before {
        counter-increment: ol-counter;
        content: counter(ol-counter) '.';
        font-weight: $bold; } } }

  ul {
    li {
      &:before {
        content: '\25A0';
        font-size: 1.1rem;
        margin-top: 2px; }

      a {
        color: $red; } } }

  blockquote {
    @include border();
    color: $darker-grey;
    font-size: 1.4rem;
    line-height: 1.2;
    text-align: center;

    figure {
      position: relative;

      figcaption {
        background-color: $red;
        bottom: -8px;
        color: #FFFFFF;
        display: inline-block;
        left: 50%;
        padding: 2px 4px;
        position: absolute;
        text-transform: uppercase;
        transform: translate(-50%, 0);
        white-space: nowrap; } }

    .blockquote__title {
        background-color: $red;
        color: #FFFFFF;
        display: inline-block;
        padding: 2px 4px;
        text-transform: uppercase;
 }        //white-space: nowrap

    p {
      font-size: 1.7rem;
      padding: 20px;
      text-align: center;

      &:before {
        content: '\201C';
        margin-right: 1px; }

      &:after {
        content: '\201D'; }

      &:before,
      &:after {
        color: $red;
        font-size: 2.2rem;
        line-height: 1rem;
        position: relative;
        top: 5px; } }

    .article__credit {
      margin-bottom: 10px;
      text-align: center; } }

  .article__aside__iframe {
    margin-bottom: 0;
    iframe {
      padding: 0 0 10px 0; } }

  span.article__update {
    color: #dc0028; }

  span.article__update:before {
    content: "UPDATE";
    padding: 2px 4px;
    background: #dc0028;
    color: white;
    margin-right: 4px; } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs)
//.article__text


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.article__text


// SM and MD
@media screen and (min-width: $min-sm) and (max-width: $max-md) {
  .article__text {
    > h1,
    > h2,
    > h3,
    > h4,
    > h5,
    > h6 {
      font-size: 2rem; } } }

// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .article__text {
    > * {
      margin-bottom: 22px; }

    blockquote {} } }

// MD
@media screen and (max-width: $max-md) {
  .article__text {
    blockquote {
      figure {
        figcaption {
          font-size: 1.2rem; } } } } }

// LG
@media screen and (min-width: $min-lg) {
  .article__text {
    font-size: 1.9rem;

    blockquote {
      p {
        font-size: 1.9rem;

        &:before,
        &:after {
          top: 3px; } } }

    > h1,
    > h2,
    > h3,
    > h4,
    > h5,
    > h6 {
      font-size: 2.1rem; } } }
