.bnews__slides {
  .slick-slide {
    display: flex; }

  .bnews-icon {
    display: flex;
    width: 35px;
    margin-top: 6px;

    img {
      height: 25px;
      width: 25px; } }

  .bnews__slides__teaser {
    display: flex; } }

/* BREAKPOINTS */

// SM
@media screen and (min-width: $min-sm ) and (max-width: $max-sm ) {
  .bnews__slides {
    .bnews-icon {
      margin-top: 12px; } } }


// SM, MD and LG
@media screen and (min-width: $min-sm ) {
  .bnews__slides {
    .slick-slide {
      > div {
        > div {
          display: flex !important; } } } } }
