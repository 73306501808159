.author__follow {
  display: block;
  padding-top: 10px;

  a {
    display: inline-block;
    margin-right: 4px;
    padding: 9px;
    vertical-align: bottom;

    &:hover {
      background-color: $light-grey; } }

  img {
    height: 24px;
    vertical-align: bottom; } }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )

