.footer__links {
    margin-bottom: 10px;
    text-align: center;

    a {
        white-space: nowrap;

        &:not(:last-child) {
            &:after {
                content: "|"; } } } }

/* BREAKPOINTS */

// SM, MD and LG
@media screen and (min-width: $min-sm) {
    .footer__links {} }

// MD and LG
//@media screen and (min-width: $min-md )
// .footer__links
