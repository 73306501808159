.article__credit {
  font-size: 1.2rem;
  line-height: 1.5;
  padding: 0 8px; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.article__credit


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.article__credit


// MD
@media screen and (min-width: $min-md) and (max-width: $max-md) {
  .article__credit {
    font-size: 1.1rem; } }

// LG
@media screen and (min-width: $min-lg) {
  .article__credit {
    font-size: 1.4rem; } }
