.theme__teasers {
	.teaser {
		margin-bottom: 11px;

		> .teaser__content {
			.teaser__headline {
				font-size: 1.8rem; } } }

	.row {
		display: flex;
		flex-wrap: wrap; }

	.teaser__media {
		margin-bottom: 6px; }

	.teaser__kicker,
	.teaser__headline {
	  overflow: hidden;
	  text-overflow: ellipsis; } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.theme__teasers


// SM, MD and LG
@media screen and (min-width: $min-sm) {
	.theme__teasers {
		@include border(b);
		margin-bottom: 26px;

		&:last-child {
			border: none;
			margin-bottom: 0; }

		.teaser {
			display: block;
			margin-bottom: 25px; } } }

// MD
@media screen and (min-width: $min-md) and (max-width: $max-md) {
	.theme__teasers {
		.teaser {
			> .teaser__content {
				.teaser__headline {
					font-size: 2.0rem; } } } } }

// LG
@media screen and (min-width: $min-md) {
	.theme__teasers {
		.teaser {
			> .teaser__content {
				.teaser__headline {
					font-size: 2.2rem; } } } } }
