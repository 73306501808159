.media--1 {
  padding-bottom: 100%; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//media--1


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//media--1


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//media--1


// LG
//@media screen and (min-width: $min-lg )
//media--1
