// related options under teasers (links here)
// /!\ .article__links extends this class /!\

.teaser__related--link {
  .teaser__related__element {
    display: flex;

    .teaser__kicker, .teaser__headline {
      display: inline; }

    &:before {
      background: url(../images/icons/icon__arrow-headline.svg) no-repeat;
      content: '';
      height: 8px;
      margin-right: 6px;
      margin-top: 5px;
      width: 7px; } } }

/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
  .teaser__related--link {
    .teaser__related__element {
      &:nth-child(n+2) {
        display: none; } } } }

// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .teaser__related--link {
    flex-wrap: wrap; } }

// LG
//@media screen and (min-width: $min-lg )
//  .teaser__related--link
