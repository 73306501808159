.gotop {
	bottom: 20px;
	cursor: pointer;
	visibility: hidden;
	opacity: 0;
	position: fixed;
	right: 20px;
	transition: opacity 0.5s ease-in-out;
	z-index: $gotop;

	&.gotop--active {
		visibility: visible;
		opacity: 1; }

	.button--icon {
		&:before {
			background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.615 6.77 10.308 0 4 6.77l1.077 1.038 4.308-4.731V20h1.538V3.077l4.462 4.73z' fill='%23FFF' fill-rule='evenodd'/%3E%3C/svg%3E%0A"); } } }



/* BREAKPOINTS */

// SM and MD and LG
@media screen and (min-width: $min-sm) {
	.gotop {
		right: auto; } }


// SM
@media screen and (min-width: $min-sm) and (max-width: $max-sm) {
	.gotop {
		left: calc(50% - 72px); } }


// MD
@media screen and (min-width: $min-md) and (max-width: $max-md) {
	.gotop {
		margin-left: 438px; } }


// LG
@media screen and (min-width: $min-lg) {
	.gotop {
		margin-left: 637px; } }

