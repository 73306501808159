.feedback__stars {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 6px;

  span {
    align-self: center;
    margin-right: auto;
    order: 1; } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.feedback__stars


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.feedback__stars


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.feedback__stars


// LG
//@media screen and (min-width: $min-lg )
//.feedback__stars
