// /!\ article__wrapper has also the .row class /!\
.article__wrapper {
  margin-bottom: 20px;
  min-height: 175px;
  position: relative; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//article__wrapper


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//article__wrapper


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//article__wrapper


// LG
//@media screen and (min-width: $min-lg )
//article__wrapper
