.newsletter__group {
	border-bottom: 1px solid #e1e6eb;
	padding-bottom: 20px;
	margin-bottom: 20px;

	img {
		align-self: flex-start;
		padding-right: $gutter-xs;
		width: 33.333%; }

	label {
		cursor: pointer;
		display: flex;
		position: relative;

		> span {
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding-left: $gutter-xs;
			padding-right: 25px;
			width: 66.666%; }

		&:after {
			position: absolute;
			right: 10px;
			top: calc(50% - 10px); } }

	a.newsletter__preview {
		color: #DC0028; }

	input.xxx {
		&:checked {
			+ label {
				background: $lighter-grey; } } }

	a.xxx {
		align-items: center;
		display: flex;
		font-size: 1.3rem;

		&:before {
			background: url(../images/icons/icon__arrow-red.svg) no-repeat;
			content: "";
			height: 10px;
			margin-right: 5px;
			width: 10px; } } }

.newsletter__group .form__checkbox input[type="checkbox"]+label {
	margin-bottom: 0; }

.newsletter__group .newsletter__text--info {
	color: #DC0028; }

.newsletter__group--na label {
	cursor: default; }

.form .newsletter__group--na label:after {
	border: 1px solid #AAB9C3;
	background-color: #F5F6F8;
	border-radius: 3px;
	content: '';
	display: block;
	height: 18px;
	margin-left: auto;
	width: 18px; }


/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
	.newsletter__group {
		margin-bottom: 16px;

		label {
			padding: 10px 5px 10px 0;

			> span {
				padding-bottom: 5px; } } } }

// SM, MD and LG
@media screen and (min-width: $min-sm) {
	.newsletter__group {
		margin-bottom: 30px;

		img {
			padding-right: $gutter-sm;
			width: 25%; }

		label {
			> span {
				padding-left: $gutter-sm;
				padding-right: 60px;
				width: 75%; }

			&:after {
				right: 29px;
				top: calc(50% - 10px); } } } }

// MD
@media screen and (min-width: $min-md) and (max-width: $max-md) {
	.newsletter__group {
		img {
			padding-right: $gutter-md; }

		label {
			> span {
				padding-left: $gutter-md; } } } }

// LG
@media screen and (min-width: $min-lg) {
	.newsletter__group {
		img {
			padding-right: $gutter-lg; }

		label {
			> span {
				padding-left: $gutter-lg; } }

		a {
			font-size: 1.5rem; } } }
