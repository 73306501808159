// header__nav__element is the block containing the text and the icon of the menus and submenus

.header__nav__element {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 1.5rem;
  justify-content: space-between;

  a {
    @include ellipsis(); }

  .header__nav__element__adlabel {
    color: $dark-grey;
    font-size: 1.0rem;
    text-transform: uppercase; }

  &.header__nav__element--live {
    display: none;

    a {
      display: flex;

      &:before {
        background: url(../images/icons/icon__livetv.svg) no-repeat;
        background-size: contain;
        background-position: center;
        content: "";
        height: 40px;
        margin-right: 6px;
        width: 6px; } } } }


/* BREAKPOINTS */


// XS and SM
@media screen and (max-width: $max-sm) {
  .header__nav__element {
    align-items: center;
    cursor: pointer;
    height: 39px;

    a,
    > span:not(.icon):not(.header__nav__element__adlabel) {
      height: 100%;
      line-height: 39px;
      padding: 0 15px;

      span {
        line-height: 39px; }

      &:hover {
        color: $red;
        font-weight: $bold; } }

    .icon {
      cursor: pointer;
      margin-right: 15px;

      &:before {
        background: url(../images/icons/icon__arrow-circ.svg) no-repeat;
        height: 25px;
        width: 25px; } }

    .header__nav__element__adlabel {
      margin-right: 15px; } }

  .header__nav__element--last {
    a {
      flex-grow: 1; } } }

// MD and LG
@media screen and (min-width: $min-md) {
  .header__nav__element {
    padding: 0 10px;

    .icon {
      display: none; }

    &.header__nav__element--live {
      display: flex; } }

  .header__nav__lvl2 {
    .header__nav__element {
      width: calc(100% - 10px);

      a {
        width: 100%; } } } }

// LG
@media screen and (min-width: $min-lg) {
  .header__nav__element {
    font-size: 1.7rem;

    &.header__nav__element--live {
      a {
        &:before {
          height: 50px; } } } } }
