.sport__bwin {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
    margin-top: 5px;

    &__joker {
      display: none; }

    &.closed {
      display: none;

      + .sport__bwin__joker {
        align-items: center;
        display: flex;

        span {
          background-color: #fff;
          border: 1px solid $lighter-grey;
          color: $darker-grey;
          font-size: 1.1rem;
          font-weight: 700;
          margin-bottom: 5px;
          margin-top: 5px;
          padding: 2px 5px;
          text-align: center;
          text-transform: uppercase; } } }

    &__w {
      color: $light-grey;
      font-size: 1.1rem;
      font-weight: bold;
      margin-right: 5px; }

    &__wrapper {
      display: flex;
      width: stretch; }

    &__quota {
        align-items: center;
        border: 1px solid $lighter-grey;
        display: flex;
        font-size: 1.1rem;
        margin: 0 3px;

        span {
          align-items: center;
          background-color: $lighter-grey;
          color: $darker-grey;
          display: flex;
          font-weight: 700;
          height: 100%;
          padding: 0 5px; }

        div {
          color: $darker-grey;
          padding: 1px 10px;
          position: relative; }

        &.down div {
          &:before {
            border-style: solid;
            border-width: 0 0 8px 8px;
            border-color: transparent transparent $red transparent;
            bottom: 0;
            content: '';
            display: block;
            height: 0px;
            right: 0;
            position: absolute;
            width: 0px;
            -webkit-transform:rotate(360deg) {} } }

        &.up div {
          &:before {
            border-style: solid;
            border-width: 0 8px 8px 0;
            border-color: transparent $green transparent transparent;
            content: '';
            display: block;
            height: 0px;
            position: absolute;
            right: 0;
            top: 0;
            width: 0px;
            -webkit-transform:rotate(360deg) {} } } }

    img {
      display: block; } }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.sport__bwin


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.sport__bwin


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.sport__bwin


// LG
@media screen and (min-width: $min-lg ) {
  .sport__bwin {
    &__quota {
      font-size: 1.3rem; } } }
