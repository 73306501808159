.article__share--clicked {
  background: #a3aeb2;
  color: white;
  line-height: 25px;
  font-size: 11px;
  padding: 0 3px;
  position: absolute;
  text-align: center;
  top: 0;
  transform: translateX(-100%);
  white-space: nowrap;
  z-index: $article__share--clicked; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//  .article__share--clicked


// SM, MD and LG
//@media screen and (min-width: $min-sm)
//.article__share--clicked
