.article__aside__text {
  display: block;
  font-style: italic;
  margin-bottom: 13px;
  margin-top: -3px; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//  .article__aside__text


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.article__aside__text


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.article__aside__text


// LG
//@media screen and (min-width: $min-lg )
//.article__aside__text
