.newsletter__bottom {
	padding: 25px 0;

	a {
		font-weight: $bold;
		text-decoration: underline; } }

/* BREAKPOINTS */


@media screen and (min-width: $min-sm) {
	.newsletter__bottom {
		> span {
			display: inline-block; } } }
