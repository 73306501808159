.article__audioicon {
  font-size: 1.5rem;
  line-height: 22px;
  text-decoration: underline;
  color: $darker-grey;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  margin-top: 32px;

  &:hover {
    color: $black; }

  &:before {
    content: "";
    background-image: url(../images/icons/icon__audio.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 22px;
    height: 22px;
    margin: 0 10px 0 5px; } }


.article__header {
  .article__audioplayer {
    display: none;
    width: 100%;
    margin-bottom: 16px; } }


.article__header--audioActive {
  .article__audioicon {
    &:before {
      background-image: url(../images/icons/icon__close_darker-grey.svg); } }

  .article__audioplayer {
      display: block; } }



/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )



// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )


// SM and MD and LG
@media screen and (min-width: $min-md ) {
  .article__header {
    .article__audioplayer {
      margin-top: -10px; } } }

// MD and LG
@media screen and (min-width: $min-md ) {
  .article__audioicon {
    width: auto;
    margin-top: 0;
    margin-left: auto; }

  .article__header__titles--portrait {
    .article__audioicon {
      width: 100%;
      margin-top: 32px;
      margin-left: 0; } } }



// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )



// LG
// @media screen and (min-width: $min-lg)



