.teaser--col {
  background: $lighter-grey;
  padding: 16px;

  .teaser__media {
    align-items: center;
    height: 100%; }

  .teaser__infos {
    justify-content: flex-start;

    &:before {
      background: $darker-grey;
      color: white;
      content: "KOLUMNE";
      font-size: 1rem;
      font-weight: $semi-bold;
      margin-right: 5px;
      padding: 0 2px; } }

  .teaser__content {
    align-content: center;
    display: flex;
    flex-wrap: wrap;
    height: 100%;

    > * {
      width: 100%; } } }

/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
  .teaser--col {
    margin-bottom: 15px;
    padding: 10px; } }

// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.teaser--col


// SM, MD and LG
//@media screen and (min-width: $min-sm )
//.teaser--col


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.teaser--col


//MD and LG
@media screen and (min-width: $min-md) {
  .teaser--col {
    margin-bottom: 30px; } }


// LG
@media screen and (min-width: $min-lg) {
  .teaser--col {
    .teaser__infos {
      &:before {
        font-size: 1.2rem; } } } }
