.gallery__infos {
  color: $dark-grey;
  display: flex;
  font-size: 1.1rem;
  justify-content: space-between;
  line-height: 1.4;
  margin-bottom: 10px; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.gallery__infos


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.gallery__infos


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.gallery__infos


// LG
@media screen and (min-width: $min-lg) {
  .gallery__infos {
    font-size: 1.3rem; } }
