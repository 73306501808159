.article__header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 10px;

  h1,
  h2 {
    span {
      display: flex; } }

  .article__media {
    flex-grow: 1;
    width: 100%; } }

/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
  .article__header {
    flex-wrap: wrap; } }

// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.article__header


// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .article__header {
    display: flex;
    justify-content: space-between; } }

//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.article__header


// LG
//@media screen and (min-width: $min-lg )
//.article__header
