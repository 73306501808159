.teaser--moderator {
  padding: 0 5px;

  .teaser__more {
    color: $red;
    font-size: 1.3rem;
    text-decoration: none;

    &:before {
      background: url(../images/icons/icon__arrow-red.svg) no-repeat;
      margin-right: 4px; } }

  .teaser__content {
    padding: 0 !important; }

  .teaser__media {
    margin-bottom: 5px; }

  .teaser__headline {
    color: $darker-grey;
    font-size: 1.5rem !important; } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.teaser--moderator


// SM
@media screen and (min-width: $min-sm) and (max-width: $max-sm) {
  .teaser--moderator {
    .teaser__media {
      margin-bottom: 7px; } } }

//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.teaser--moderator


// LG
@media screen and (min-width: $min-lg) {
  .teaser--moderator {
    .teaser__more {
      font-size: 1.5rem;

      &:before {
        height: 7px; } }

    .teaser__headline {
      font-size: 1.7rem !important; } } }
