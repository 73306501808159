.table--col1-2 {
  td {
    &:first-child {
      @include ellipsis();
      width: 50%; }

    // 2 lasts
    &:nth-last-child(-n+2) {
      width: 25%;
      text-align: right; } } }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.table--col1-2


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.table--col1-2


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.table--col1-2


// LG
//@media screen and (min-width: $min-lg )
//.table--col1-2
