.nav-sub {
	.dropdown {
		width: 200px; } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.nav-sub

// SM, MD, LG
@media screen and (min-width: $min-sm ) {
	.nav-sub {
		padding-bottom: 20px; } }

// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.nav-sub



// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.nav-sub


// LG
//@media screen and (min-width: $min-lg )
//.nav-sub
