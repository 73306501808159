// related options under teasers (links here)

.teaser__related {
  list-style: none;
  margin-top: 2px;
  width: 100%; }

/* BREAKPOINT */


// XS and SM
//@media screen and (max-width: $max-sm)
//.teaser__related

// XS
//@media screen and (max-width: $max-xs)
//.teaser__related


// SM
//@media screen and (min-width: $min-sm) and (max-width: $max-sm)
//.teaser__related


// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .teaser__related {
    margin-top: 20px; } }

// MD and LG
@media screen and (min-width: $min-md) {
  .teaser__related {
    figure {
      a {
        font-size: 1.3rem; }

      figcaption {
        margin-top: -27px; } } } }

// LG
//@media screen and (min-width: $min-lg )
//.teaser__related
