
//.sport

/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs ) {
    .sport {
        .sport__title {
            div {
                display: flex;
                justify-content: flex-end; } } } }


// SM
@media screen and (min-width: $min-sm ) {
    .sport {
        .sport__title {
            display: flex;
            margin-bottom: 10px;

            a {
                margin-bottom: 0; } } } }



// SM, MD and LG
//@media screen and (min-width: $min-sm) and (max-width: $max-md )
//  .sport


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//  .sport

// LG
//@media screen and (min-width: $min-md)
//  .sport
