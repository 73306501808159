.quiz {

  @keyframes spin {to { transform: rotate(.5turn) }};

  @keyframes bg {50% { background: $green }};

  .pie--chart {
    background: $red;
    background-image: linear-gradient(to right, transparent 50%, $green 0);
    border-radius: 50%;
    color: transparent;
    height: 200px;
    line-height: 200px;
    position: relative;
    text-align: center;
    width: 200px;

    &:before {
      animation: spin 50s linear infinite,bg 100s step-end infinite;
      animation-delay: inherit;
      animation-play-state: paused;
      background-color: inherit;
      border-radius: 0 100% 100% 0 / 50%;
      content: '';
      height: 100%;
      left: 50%;
      position: absolute;
      top: 0;
      transform-origin: left;
      width: 50%; } }

  .quiz__chart--right {
    margin-bottom: 10px;

    span {
      color: $green; } }

  .quiz__chart--wrong {
    span {
      color: $red; } } }

/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs ) {
  .quiz {
    .pie--chart {
      height: 120px;
      line-height: 120px;
      width: 120px; } } }


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.quiz__chart


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.quiz__chart


// LG
//@media screen and (min-width: $min-lg )
//.quiz__chart
