header {
  height: 40px;
  margin-bottom: 15px;
  position: sticky;
  top: 0;
  z-index: $header--sticky; }


/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
  .header {
    margin: 0 (-$gutter-xs-real) 9px; } }


// SM
@media screen and (min-width: $min-sm) and (max-width: $max-sm) {
  .header {
    height: 48px;
    margin: 0 (-$gutter-sm) 9px; } }


// MD
//@media screen and (min-width: $min-md) and (max-width: $max-md)

// MD and LG
@media screen and (min-width: $min-md) {
  .header {
    height: 60px;
    margin: 0 (-$gutter-md-real) 15px; } }


// LG
@media screen and (min-width: $min-lg) {
  .header {
    height: 85px;
    margin: 0 (-$gutter-lg-real) 15px; } }

