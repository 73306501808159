.gallery {
  background: $black;
  @include border($color: $dark-grey);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  .slick-track {
    height: 100%; }

  .slick-slide {
    align-items: center;
    display: flex !important;
    height: 100%; }

  .slick--prev,
  .slick--next {
    position: absolute;
    top: calc(50% - 25px);
    transition: all $gallery-transitions;
    z-index: $gallery__nav;

    &:before {
      margin: 0 10px; } }

  .slick--prev {
    left: 0; }

  .slick--next {
    right: 0; }

  figcaption {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    padding: 10px;
    transition: bottom $gallery-transitions; }

  figure {
    width: 100%;

    img {
      cursor: pointer;
      margin: auto;
      max-width: 100%;
      transition: max-height $gallery-transitions;
      width: auto; } }

  + .row {
    margin-bottom: 20px;

    h1 {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 12px;

      > * {
        width: 100%; } } } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.gallery


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.gallery


// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .gallery {
    .slick--prev,
    .slick--next {
      &:before {
        height: 51px;
        width: 51px; } }

    figcaption {
      padding: 15px 30px; }

    + .row {
      margin-bottom: 30px; } } }

//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.gallery


// LG
@media screen and (min-width: $min-lg) {
  .gallery {
    .slick--prev,
    .slick--next {
      &:before {
        height: 65px;
        margin: 0 20px;
        width: 65px; } }

    figcaption {
      padding: 20px 40px; } } }
