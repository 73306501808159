.weather__next__temp {
	font-size: 1.5rem;
	font-weight: $semi-bold;

	.max {
		color: $red;

		@media (inverted-colors: inverted) {
			color: $inv-red; } }

	.min {
		color: $blue;

		@media (inverted-colors: inverted) {
			color: $inv-blue; } } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.weather__next__temp


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.weather__next__temp


// LG
@media screen and (min-width: $min-lg) {
	.weather__next__temp {
		font-size: 1.7rem; } }
