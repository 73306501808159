.article__aside__links {
  // FOLGEN
  span {
    display: block;
    float: left;
    font-weight: $bold;
    line-height: 24px;
    margin-right: 5px; }

  // ICONS
  img {
    height: 24px; } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//  .article__aside__links


