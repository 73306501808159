.browserpush__channel {
	display: flex;
	flex-direction: column;

	li {
		list-style-type: none;
		margin-bottom: 15px; }

	label {
		position: relative; }

	input {
		position: absolute;
		left: -9999px;

		& + label {
			align-items: center;
			cursor: pointer;
			display: inline-flex;
			line-height: 20px;
			padding-left: 30px;

			&:before {
				@include border($color: $dark-grey);
				display: block;
				content: '';
				height: 18px;
				left: 0;
				position: absolute;
				width: 18px; } }

		&:checked + label {
			&:before {
				background: url(../images/icons/icon__checkmark.svg);
				border-color: $red; } } } }

/* BREAKPOINTS */

@media screen and (max-width: $max-xs ) {
	.browserpush__channel {
        margin-bottom: 16px; } }

// SM, MD and LG
@media screen and (min-width: $min-sm) {
	.browserpush__channel {
		margin-bottom: 30px; } }

// LG
//@media screen and (min-width: $min-lg )
//browserpush__channel
