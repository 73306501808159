// slider of sliders are disabled in xs, where all teasers are displayed one under eachothers
// except if it is associated with the class .slider--show-xs
.slider {
  .teaser {
    .teaser__headline {
      font-size: 1.8rem; } }

  .teaser__media,
  .teaser__content {
    width: 100%; } // IE11

  .slick-list {
    padding: 0 !important; }

  article {
    margin: 0; } }

/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
  .slider {
    &:not(.slider--show-xs) {
      padding-bottom: 0;

      .teaser {
        margin-bottom: 25px;

        &:nth-child(n+6) {
          display: none; }

        &:nth-child(5),
        &:last-child {
          margin-bottom: 0; } } } } }

// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .slider {
    padding-bottom: 25px; } }

// SM
@media screen and (min-width: $min-sm) and (max-width: $max-sm) {
  .slider {
    .teaser {
      padding: 0 $gutter-sm; } } }


// MD
@media screen and (min-width: $min-md) and (max-width: $max-md) {
  .slider {
    .teaser {
      padding: 0 $gutter-md; } } }

// MD and LG
//@media screen and (min-width: $min-md)
//.slider


// LG
@media screen and (min-width: $min-lg) {
  .slider {
    padding-bottom: 20px;

    .teaser {
      padding: 0 $gutter-lg;

      .teaser__headline {
        font-size: 2.2rem; } } } }
