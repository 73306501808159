.list__block__title {
  color: $red;
  font-weight: $bold;
  margin-bottom: 5px; }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.list__block__title


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.list__block__title


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.list__block__title


// LG
//@media screen and (min-width: $min-lg )
//.list__block__title
