.article__header__titles {
  display: inline-flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 15px;
  width: 100%;

  &.article__header__titles--portrait {
      flex-basis: calc(100% - 100px); }

  > * {
    &:last-child {
      margin-bottom: 0; } } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.article__header__titles


// SM
@media screen and (min-width: $min-sm) and (max-width: $max-sm) {
  .article__header__titles {
    &.article__header__titles--portrait {
      flex: 1 0 calc(100% - 240px);
      // For IE11 which doesn't like calc in flex:
      flex-basis: calc(100% - 240px); } } }

// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .article__header__titles {
    margin-bottom: 26px; } }

// MD and LG
@media screen and (min-width: $min-md) {
  .article__header__titles {
    &.article__header__titles--portrait {
      flex: 1 0 calc(100% - 250px);
      // For IE11 which doesn't like calc in flex:
      flex-basis: calc(100% - 250px); } } }

// LG
//@media screen and (min-width: $min-lg )
//.article__header__titles
