//.sport
//title--dark
//display: block

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.sport


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.sport


// SM, MD and LG
@media screen and (min-width: $min-sm) and (max-width: $max-md ) {
	.sport {
		padding-bottom: 25px; } }

//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.sport

// LG
@media screen and (min-width: $min-md) {
	.sport {
		padding-bottom: 20px; } }
