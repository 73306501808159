.article__author {
  color: $dark-grey;
  display: block;
  font-size: 1.5rem;
  font-style: italic;
  line-height: 1.33;
  margin-top: 10px;

  a {
    display: flex;
    align-items: center; }

  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px; } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//  .article__author


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//  .article__author


// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .article__author {
    margin-top: 17px;

    img {
      width: 35px;
      height: 35px; } } }

// MD and LG
@media screen and (min-width: $min-md) {
  .article__author {
    margin-top: 22px; } }

// LG
@media screen and (min-width: $min-lg) {
  .article__author {
    font-size: 1.7rem; } }
