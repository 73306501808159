
.abo-manage {
	font-size: 1.5rem;
	border: 0;
	margin-bottom: 100px;

	.title {
		margin-bottom: 16px; }

	.abo-manage__render--loading {
		background: url(../images/icons/icon__loading__grey.svg) no-repeat;
		background-position: center;
		background-size: 50px;
		height: 200px; }

	.abo-manage__name {
		display: block;
		font-size: 2.0rem;
		font-weight: bold;
		margin-bottom: 5px;

		&.abo-manage__name--small {
			font-size: 1.5rem; } }

	.abo-manage__info {
		display: block;
		color: #46555F;
		margin-bottom: 16px;

		&.abo-manage__info--canceled {
			color: $red; } }

	ul {
		border-top: 1px dashed $light-grey;
		margin-top: 20px;

		li {
			list-style-type: none;
			margin-top: 20px; } }

	.abo-manage__account-create {
		margin-top: 20px;
		font-size: 1.3rem;
		font-weight: bold;

		a {
			font-weight: normal; } }

	.button {
		width: 100%; } }




/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )

// SM and MD and LG
@media screen and (min-width: $min-sm ) {
	.abo-manage {
		.button {
			width: 350px; } } }


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )



// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )



// LG
@media screen and (min-width: $min-lg) {
	.abo-manage {
		font-size: 1.7rem;

		.abo-manage__name {
			font-size: 2.6rem; }

		.button {
			width: 450px; } } }


