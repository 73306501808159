// not displayed on XS and SM

.sitemap__group {
	font-size: 1.5rem;
	margin-top: 0;
	padding: 0 20px 10px;
	width: 100%;

	.title {
		margin-bottom: 10px;
		text-transform: initial;
		white-space: nowrap; }

	&:last-child {
		margin-bottom: 0; }

	.title {
		font-size: 1.5rem; } }

/* BREAKPOINTS */

// XS and SM
//@media screen and (max-width: $max-sm)
//	.sitemap__group
// MD and LG
//@media screen and (min-width: $min-md )
//	.sitemap__group

// LG
@media screen and (min-width: $min-lg) {
	.sitemap__group {
		font-size: 1.7rem;

		.title {
			font-size: 1.7rem; } } }
