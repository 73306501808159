// This is the parent class of every teaser in the website.
// Every type of teaser must start with this class first (ex: class="teaser teaser--wide")
.teaser {
  display: flex;
  flex-wrap: wrap; }

.teaser__kicker,
.teaser__headline {
  font-weight: $bold; }

/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
  .teaser {
    margin-bottom: 15px;

    .teaser__related {
      display: none; } }

  .teaser--xs--3 {
    div {
      &:nth-child(n+4) {
        display: none; } } } }


// SM
@media screen and (min-width: $min-sm) {
  .teaser {
    margin-bottom: 20px; } }


// MD and LG
//@media screen and (min-width: $min-md)
//.teaser


// LG
//@media screen and (min-width: $min-lg)
//.teaser
