.bnewsB {
  padding-bottom: 24px;
  margin-top: 24px;
  border: 0;

  .teaser {
    margin: 0;
    padding: 0 12px; }

  .teaser--video picture:before {
    background-image: url(../images/icons/icon__arrow-white.svg);
    background-color: #dc0028;
    width: 30px;
    height: 30px;
    left: 0;
    bottom: 1px;
    background-size: 10px;
    background-position: center; }

  .teaser__infos {
    font-size: 1.3rem;
    line-height: 1.8; }

  .teaser__date {
    color: #dc0028;
    padding-right: 5px; }

  .teaser__section {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

  .teaser__section:before {
    content: '|';
    padding-right: 5px; }

  .teaser__headline {
    font-size: 1.6rem; }

  .teaser__media,
  .teaser__content {
    width: 100%; }

  .bnewsB__more {
    text-align: right;
    margin-right: 32px;
    font-size: 1.3rem;
    margin-top: 24px;

    a {
      text-decoration: underline;

      &:hover {
        color: #697882;
        text-decoration: none; } } } }

.bnews__slidesB {
  padding: 0 20px;
  position: relative;

  button {
    position: absolute;
    top: calc(50% - 19px);

    &:before {
      background-position: center; } }

  .slick--prev {
    left: -10px; }

  .slick--next {
    right: -10px; } }


/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
  .wrapperNewsSliderB {
    margin: 0 -10px; }

  .bnewsB {
    box-shadow: 0px 10px 10px 0px #0000000D;
    padding: 0 0 24px 10px;
    margin-bottom: 24px;

    .teaser {
      padding: 0 20px 20px 0;
      margin: 0;
      border-right: 1px solid #e1e6eb;

      &:last-child {
        padding-bottom: 0; } }

    .teaser__media {
      width: 25%;

      .media {
        padding-bottom: 100%; } }

    .teaser__content {
      width: 75%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 10px; }

    .bnewsB__more {
      text-align: center;
      width: 100%; } }

  .bnews__slidesB {
    padding: 0;

    .bnews__slide-wrapper {
      padding-right: 20px; }

    .slick-list {
      padding: 0 10% 0 0 !important; }

    .slick-slide:not(.slick-current) {
      opacity: 0.2; } } }

// SM, MD and LG
//@media screen and (min-width: $min-sm)


// SM
@media screen and (min-width: $min-sm) and (max-width: $max-sm) {
  .bnewsB {
    box-shadow: 0px 10px 10px 0px #0000000D;
    margin: 24px -8px;
    padding: 0 8px 24px 8px; }

  .bnews__slidesB {
    .slick--prev {
      left: -8px; }

    .slick--next {
      right: -8px; } } }


// MD
@media screen and (min-width: $min-md) and (max-width: $max-md) {
  .bnewsB {
    border-bottom: 36px solid #e1e6eb;
    margin: 24px -16px;
    padding: 0 16px 24px 16px; } }


// LG
@media screen and (min-width: $min-lg) {
  .bnewsB {
    border-bottom: 36px solid #e1e6eb;
    margin: 24px -20px;
    padding: 0 20px 24px 20px;

    .teaser {
      padding: 0 10px;

      .teaser__headline {
        font-size: 1.8rem; } }

    .teaser__infos {
      font-size: 1.5rem; }

    .bnewsB__more {
      font-size: 1.5rem; } } }

