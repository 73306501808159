.day__teaser__caption {
  color: $dark-grey;
  font-size: 1.4rem;
  line-height: 1.2;
  margin-top: 7px;
  margin-bottom: 2px; }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.day__teaser__caption


// SM, MD and LG
//@media screen and (min-width: $min-sm )
//.day__teaser__caption

// LG
@media screen and (min-width: $min-lg) {
  .day__teaser__caption {
    font-size: 1.6rem; } }
