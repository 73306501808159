.weather__next__item {
	width: 50%;

	&:last-child {
		display: none; }

	img {
		display: none;
		float: left;
		margin-right: 10px;
		width: 40px; } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.weather__next__item


// XS +
@media screen and (min-width: 430px) and (max-width: $max-xs) {
	.weather__next__item {
		&:last-child {
			display: block; } } }

// XS ++
@media screen and (min-width: 551px) and (max-width: $max-xs) {
	.weather__next__item {
		img {
			display: block; } } }

// SM
@media screen and (min-width: 1000px) and (max-width: $max-sm) {
	.weather__next__item {
		&:last-child {
			display: block; } } }

// LG
@media screen and (min-width: $min-lg) {
	.weather__next__item {
		width: 33.333%;

		&:last-child {
			display: block; } } }
