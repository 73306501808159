.day__teaser {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 30px;
  position: relative;

  .row {
    margin: 0; }

  > * {
    width: 100%; }

  &:not(:last-child) {
    @include border(b);
    margin-bottom: 30px; }

  .teaser {
    a {
      width: 100%; } }

  > figure {
    margin-bottom: 24px;

    + .day__teasers {
      margin-top: -10px; } }

  .article__text {
    > * {
      &:last-child {
        margin-bottom: 0; } } }

  .day__teaser__breaking {
    background: $red;
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 2rem;
    padding: 2px;
    text-transform: uppercase; } }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs)
//day__teaser

// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .day__teaser {
    flex-direction: column;
    min-height: 215px;

    > figure {
      margin-bottom: 25px; }

    .article__share {
      margin-top: 30px;
      width: auto; }

    .article__aside {
      margin-bottom: 0; }

    .day__teaser__breaking {
      font-size: 1.4rem; } } }

// LG
@media screen and (min-width: $min-lg) {
  .day__teaser {
    min-height: 260px;

    .day__teaser__breaking {
      font-size: 1.6rem; } } }
