.social {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;

  a {
    margin: 0 4px;
    padding: 9px;

    &:hover {
      background-color: $darker-grey; }

    img {
      display: block;
      height: 24px; } } }

/* BREAKPOINTS */

// XS
@media screen and (max-width: $max-xs) {
    .social {
        flex-wrap: wrap;

        .title {
            text-align: center;
            width: 100%; } } }

// SM
@media screen and (min-width: $min-sm) and (max-width: $max-sm) {
    .social {
        justify-content: center;

        .gotop {
            height: 50px; }

        .title {
            margin-right: 10px; }

        .title,
        > div {
            height: 30px; } } }

// SM, MD and LG
@media screen and (min-width: $min-sm) {
    .social {
        border: none !important; } }

// MD and LG
@media screen and (min-width: $min-md) {
    .social {
        .title {
            line-height: 1;
            margin-bottom: 12px; } } }

// LG
@media screen and (min-width: $min-lg) {
    .social {
        .title {
            font-size: 1.7rem;
            width: 100%; } } }
