.teaser--image {
	display: block; }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.teaser--image


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.teaser--image


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.teaser--image


// LG
//@media screen and (min-width: $min-lg )
//.teaser--image
