.breadcrumb {
  margin: 12px 0; // 13sm, 14md, 12lg
  display: flex;
  white-space: nowrap;

  span,
  h1 {
    color: $dark-grey;
    display: block;
    font-size: 1.2rem;
    font-weight: normal;

    &:not(:last-child) {
      &:after {
        align-self: center;
        content: '';
        background: url(../images/icons/icon__arrowlight-grey.svg) no-repeat center;
        transform: rotate(180deg);
        display: inline-block;
        width: 18px;
        height: 0.9rem; } } }


  h1 {
    text-overflow: ellipsis;
    overflow: hidden; }

  a {
    &:hover {
      text-decoration: underline; } } }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.breadcrumb


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.breadcrumb


// MD and LG
@media screen and (min-width: $min-md ) {
  .breadcrumb {
    span,
    h1 {
      font-size: 1.4rem;

      &:not(:last-child) {
        &:after {
          height: 1rem; } } } } }


// LG
//@media screen and (min-width: $min-lg )
//.breadcrumb
