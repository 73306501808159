.sidebar {}
/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.sidebar




// SM, MD and LG
@media screen and (min-width: $min-sm) {
	.sidebar {
		margin-bottom: 20px;
		@include border();
		padding: 20px 20px 0 20px;

		&.sidebar--noborder {
		  border: 0;
		  padding: 0; } } }


// SM and MD
@media screen and (min-width: $min-sm) and (max-width: $max-md) {
	.sidebar {
		&.sticky-sidebar {
			position: sticky;
			top: 70px; }

		.teaser {
			.teaser__content {
				.teaser__headline {
					font-size: 1.8rem; } } } } }


// SM
//@media screen and (min-width: $min-sm) and (max-width: $max-sm)
//sidebar


// MD
//@media screen and (min-width: $min-md) and (max-width: $max-md)
//sidebar


// LG
@media screen and (min-width: $min-lg) {
	.sidebar {

		.teaser {
			.teaser__content {
				.teaser__headline {
					font-size: 2.2rem; } } } }

	.sidebar.sticky-sidebar {
		position: sticky;
		top: 100px; } }


