.article__kicker {
  color: $red;
  font-size: 1.6rem;
  line-height: 1.2;
  margin-bottom: 3px;

  @media (inverted-colors: inverted) {
    color: $inv-red; }

  .update {
    background-color: $red;
    color: #FFFFFF;
    text-transform: uppercase;
    margin-right: 5px;
    padding: 4px 5px;

    @media (inverted-colors: inverted) {
      background-color: $inv-red; } } }

/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs ) {
  .article__kicker {

    .update {
      font-size: 1.1rem; } } }

// SM MD
@media screen and (min-width: $min-sm ) and (max-width: $max-md ) {
  .article__kicker {

    .update {
      font-size: 1.2rem; } } }

// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .article__kicker {
    font-size: 1.8rem; } }

// MD
//@media screen and (min-width: $min-md) and (max-width: $max-md)
//.article__kicker


// LG
@media screen and (min-width: $min-lg) {
  .article__kicker {
    font-size: 2rem;

    .update {
      font-size: 1.3rem; } } }
