.article__aside--right {
    .twitter-tweet, .instagram-media {
       width: 100% !important; } }


/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.article__aside--full


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.article__aside--full


//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.article__aside--full


// LG
//@media screen and (min-width: $min-lg)
//.article__aside--full
