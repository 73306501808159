.quiz__selection {
  margin-bottom: 10px;

  .button {
    display: block;
    height: calc(100% - 8px);
    line-height: 2rem;
    margin-bottom: 8px;
    padding: 5px 10px;
    text-align: left;
    width: 100%; }

  span.button {
    cursor: default; } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )
//.quiz__selection


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.quiz__selection


// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.quiz__selection


// LG
//@media screen and (min-width: $min-lg )
//.quiz__selection
