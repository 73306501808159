.stock-table {
  font-size: 1.5rem;

  .stock__icon {
    padding-right: 0; }

  table {
    border: none;
    border-collapse: collapse;
    min-width: 100%;
    table-layout: fixed;
    white-space: nowrap; }

  thead {
    color: $red;
    font-weight: $bold;

    td {
      background: white; } }

  td {
    line-height: 2.1rem;
    padding: 5px;

    > * {
      display: inline-block; } }

  tr {
    @include border(b);

    &:nth-child(odd) {
      background: $tables-grey; } } }

/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
  .stock-table {
    .title {
      background: $light-grey;
      margin-bottom: 15px; } } }

// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.stock-table


// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .stock-table {
    margin-bottom: 20px;

    .title {
      margin-bottom: 8px; }

    .title--arrow {
      display: inline-flex; } } }

// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.stock-table


// MD and LG
@media screen and (min-width: $min-md) {
  .stock-table {
    table {
      width: 100%; }

    td {
      @include ellipsis(); } } }

// LG
@media screen and (min-width: $min-lg) {
  .stock-table {
    font-size: 1.7rem; } }
