.title {
	color: $darker-grey;
	display: flex;
	align-items: center;
	font-size: 2.2rem;
	font-weight: $bold;
	line-height: 3.6rem;
	text-transform: uppercase; }

.title--arrow {
	align-items: center;

	&:after {
		@include background-image(arrow,$darker-grey-ec);
		background-repeat: no-repeat;
		background-size: 10px;
		content: '';
		height: 10px;
		width: 10px;
		margin: 0 0 1px 8px;
 } }		//margin: 0 0 2px 8px

@keyframes rotation {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }


/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
	.title {
		background: $dark-grey;
		color: #FFFFFF;
		font-size: 1.6rem;
		margin: 0 (-$gutter-xs-real) 10px;
		padding: 0 $gutter-xs-real;

		+ .teaser--hero,
		+ .row > *[class*=" col-"] > .content > .teaser--hero {
			margin-top: -10px; } } }



// SM, MD and LG
@media screen and (min-width: $min-sm) {
	.title {
		line-height: 1.3;
		margin-bottom: 20px; }

	.title--arrow {
		align-items: center;

		&:after {
			@include background-image(arrow-light,$darker-grey-ec);
			background-size: 15px;
			height: 15px;
			width: 15px; }

		&:hover {
			color: $black;

			&:after {
				@include background-image(arrow-light,$black-ec); } } } }


// MD and LG
//@media screen and (min-width: $min-md)
//title

// SM and MD
@media screen and (min-width: $min-sm) and (max-width: $max-md) {
	.title {
		font-size: 2.2rem; } }

// LG
@media screen and (min-width: $min-lg) {
	.title {
		font-size: 2.4rem;
		margin-bottom: 15px; }

	.title--arrow {
		&:after {
			background-size: 17px;
			height: 17px;
			width: 17px; } } }
