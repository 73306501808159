.article__aside {
  @include border();
  color: $darker-grey;
  font-size: 1.4rem;
  line-height: 1.2;
  margin-bottom: 25px;

  /* TEASER */
  .teaser {
    .teaser__media {
      width: 100%; }

    .teaser__kicker {
      font-size: 1.4rem; }

    .teaser__headline {
      white-space: initial;
      font-size: 1.6rem; }

    .teaser__kicker,
    .teaser__headline {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis; } }

  .teaser__content {
    padding: 8px 10px 0; }

  /* POLL */
  .poll {
    padding: 0 10px;

    form {
      font-size: 1.4rem;
      padding: 8px 0; } }

  .poll__element {
    border: none;
    margin: 0;
    padding-bottom: 10px; }

  .poll__question {
    font-size: 1.5rem; }

  .poll__form {
    label {
      margin-bottom: 7px; } }

  .teaser__ad {
    font-size: 1.0rem; } }

/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs)
//.article__aside


// SM
//@media screen and (min-width: $min-sm) and (max-width: $max-sm)



// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .article__aside--right,
  .article__aside--left {
    margin: 3px 0 5px;
    width: calc(40% - 8px);

    .poll {
      figure {
        margin: 0 -10px 12px; } } }

  .article__aside--right {
    float: right;
    margin-left: 16px; }

  .article__aside--left {
    float: left;
    margin-right: 16px; }

  .article__aside--full {
    .poll {
      padding: 17px 17px 11px; }

    .poll__element {
      display: flex;
      padding-bottom: 0;

      figure {
        padding-right: $gutter-sm;
        width: 33.333%; }

      > div {
        padding-left: $gutter-sm;
        width: 66.666%; } } }

  .article__aside {
    .teaser__ad {
      font-size: 1.1rem; } } }

// MD
@media screen and (min-width: $min-md) and (max-width: $max-md) {
  .article__aside--full {
    .poll__element {
      figure {
        padding-right: $gutter-md; }

      > div {
        padding-left: $gutter-md; } } }

  .article__aside--right,
  .article__aside--left {
    .poll {
      padding: 0 16px;

      figure {
        margin: 0 -16px 16px; } } } }

// LG
@media screen and (min-width: $min-lg) {
  .article__aside {
    font-size: 1.6rem;

    .teaser__content {
      padding: 8px 20px 0; }

    .teaser {
      .teaser__kicker {
        font-size: 1.6rem; }

      .teaser__headline {
        font-size: 1.8rem; } }

    .poll__question,
    .poll form {
      font-size: 1.6rem; }

    .poll {
      input[type=submit] {
        font-size: 1.5rem;
        line-height: 2.4;
        //36px
        padding: 0 20px; } }

    .poll__element {
      width: 100%; } }

  .article__aside--full {
    .poll__element {
      figure {
        padding-right: $gutter-lg; }

      > div {
        padding-left: $gutter-lg; } } }

  .article__aside--right,
  .article__aside--left {
    .poll {
      padding: 0 20px;

      figure {
        margin: 0 -20px 16px; } } } }
