//.teaser__related__element


/* BREAKPOINT */


// XS
@media screen and (max-width: $max-xs) {
  .teaser__related__element {
    margin-top: 7px;

    .teaser__headline {
      font-size: 1.4rem; }

    figure {
      padding-right: $gutter-xs; } } }

//SM
//@media screen and (min-width: $min-sm) and (min-width: $min-sm)
//.teaser__related__element


//SM, MD and LG
@media screen and (min-width: $min-sm) {
  .teaser__related__element {
    margin-bottom: 2px;

    .teaser__headline {
      font-size: 1.7rem; } } }


// MD and LG
@media screen and (min-width: $min-md) {
  .teaser__related__element {
    .teaser__kicker {
      &:after {
        content: ": "; } } } }

// LG
@media screen and (min-width: $min-lg) {
  .teaser__related__element {
    .teaser__kicker,
    .teaser__headline {
      font-size: 1.7rem; } } }
