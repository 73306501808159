.newsletter__teaser {
	padding-bottom: 15px;

	strong {
		display: block; } }

/* BREAKPOINTS */


//@media screen and (max-width: $max-xs )


// SM, MD and LG
@media screen and (min-width: $min-sm) {
	.newsletter__teaser {
		padding-bottom: 11px; } }

// LG
//@media screen and (min-width: $min-lg )
//newsletter__teaser
//font-size: 1.7rem
