.weather {
	border: none !important;
	color: $darker-grey;
	margin-bottom: 15px; }

/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs) {
	.weather {
		.title {
			margin-bottom: 14px; } }

	.sidebar {
		.weather {
			display: none; } } }

// SM
@media screen and (min-width: $min-sm) and (max-width: $max-sm) {
	.weather {
		padding: 21px 0 9px; } }

// SM, MD and LG
@media screen and (min-width: $min-sm) {
	.weather {
		.title {
			flex-shrink: 0;
			line-height: 1;
			margin-bottom: 0;
			margin-right: 5px;

			&:after {
				content: ' |';
				margin-left: 5px; } } }

	.content {
		.weather {
			display: none; } }

	.sidebar {
		.weather {
			margin: 0; } } }

// MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.weather


// MD and LG
@media screen and (min-width: $min-md) {
	.weather {
		padding: 13px 0 0; } }

// LG
//@media screen and (min-width: $min-lg )
//.weather
