.article__aside {
  .extcontent--datawrapper {
    @include border();
    padding: 10px; } }



/* BREAKPOINTS */


// XS
//@media screen and (max-width: $max-xs )


// SM
//@media screen and (min-width: $min-sm) and (max-width: $max-sm)


//MD
//@media screen and (min-width: $min-md) and (max-width: $max-md)


// LG
//@media screen and (min-width: $min-lg)


