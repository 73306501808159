.gallery--page {
  @include border(b);
  margin-bottom: 8px;
  position: relative;

  figure {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    width: 100%;

    img {
      height: auto;
      width: auto;
      max-height: 100%;
      max-width: 100%; } }


  figcaption {
    position: sticky; }

  picture {
    display: flex;
    height: 100%;
    margin-bottom: auto;
    margin-top: auto;
    width: 100%; }

  .slick-slide {
    > div {
      height: 100%;

      > div {
        height: 100%; } } }

  .gallery__space {
    padding-bottom: 60%;
    position: relative;

    .gallery__space__strech {
      height: 100%;
      overflow: hidden;
      position: absolute;
      width: 100%; } } }


/* BREAKPOINTS */


// XS
@media screen and (max-width: $max-xs ) {
  .gallery--page {
    .gallery__space {
  	  padding-bottom: 100%; } } }


// SM
//@media screen and (min-width: $min-sm ) and (max-width: $max-sm )
//.gallery--page


// SM, MD and LG
@media screen and (min-width: $min-sm) {
  .gallery--page {
    + .row {
      // kicker + headline + etc...
      @include border(b);
      margin-bottom: 20px;
      min-height: 180px;
      padding-bottom: 20px;
      position: relative; } } }

//MD
//@media screen and (min-width: $min-md ) and (max-width: $max-md )
//.gallery--page


// LG
@media screen and (min-width: $min-lg) {
  .gallery--page {
    width: 1100px;
    margin-left: auto;
    margin-right: auto;

    + .row {
      min-height: 224px; } } }
